<template>
  <Modal ref="modalRef" info title-lg @shown="onShown" @secondary-action="learnMore">
    <template #title>Upload my tree</template>
    <div class="body">
      <template v-if="isWindows">
        <div >
          First, install and open our Sync app.
        </div>
        <SyncAppBadge />
      </template>
      <div>
        Then, use this code to sign into the app.
      </div>
      <AuthCode ref="authCodeRef" @auth-code-used="authCodeUsed"/>
    </div>
    <template #secondaryText>Why do I need the Sync app?</template>
  </Modal>
</template>

<style lang="scss" scoped>
.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0 0;
  gap: 0.5rem;
  color: #333;

  .sync-app-badge {
    margin: 0.5rem 0 1.5rem;
  }

  .code {
    font-size: 2rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: black;
  }

  .remaining {
    font-size: 0.875rem;
    color: transparent;

    &.show {
      color: #888;
    }
  }
}
</style>

<script lang="ts" setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { hideModalAsync } from '@/util/AppUtil'
import { currentOS } from '@/rd/ResearchDataModel'
import Modal from '@/util/Modal.vue'
import SyncAppBadge from '@/public/SyncAppBadge.vue'
import AuthCode from '@/auth/AuthCode.vue'

const router = useRouter()

const isWindows = currentOS == 'windows'
const modalRef = ref<InstanceType<typeof Modal>>()
const authCodeRef = ref<InstanceType<typeof AuthCode>>()

defineExpose({
  open: () => modalRef.value?.open(),
})

async function learnMore() {
  await hideModalAsync(modalRef.value!.$el)
  router.push('/learn/sync')
}

function onShown() {
  authCodeRef.value?.ensureRequested()
}

function authCodeUsed() {
  modalRef.value?.close()
}
</script>
