<template>
  <div class="today-list">
    <ul class="list-unstyled">
      <li v-for="item in todayItemsRef" :key="item.id">
        <img :src="item.image" />
        <div class="content">
          <router-link :to="item.pageUrl" class="link-dark description">{{ item.displayText }}</router-link>
          <div v-if="item.relationPath" class="relation-text">your {{ getRelationText(item.relationPath) }}</div>
          <!-- <div class="date">{{ item.date.toString(HistoricalDateFormat.ShortDisplay) }}</div> -->
        </div>
      </li>
    </ul>
  </div>
</template>
  
<style lang="scss" scoped>
ul {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

li {
  display: flex;
  line-height: 1;
  color: #333;

  img {
    margin-top: 0.25rem;
    margin-right: 0.75rem;
    height: 1.25rem;
    opacity: 40%;
  }

  .content {
    display: flex;
    flex-direction: column;
    font-size: 0.85rem;
    gap: 0.25rem;
  }

  .relation-text {
    color: #bbb;
  }

  .date {
    font-size: 0.85rem;
    color: #bbb;
  }
}
</style>

<script lang="ts" setup>
import { Ref, ref, watch } from 'vue'
import { useExploreStore } from '@/explore/ExploreStore'
import { useAncestorsToday } from './AncestorsToday'
import { TodayItem } from './TodayItem'
import _ from 'lodash'
import { DateTime } from 'luxon'
import { useViewPersonStore } from '@/rd/ViewPersonStore'
import { useRelationTextBuilder } from '@/rd/RelationPath'

const exploreStore = useExploreStore()
const ancestorsToday = useAncestorsToday()
const viewPersonStore = useViewPersonStore()
const { getRelationText } = useRelationTextBuilder()

const todayItemsRef = ref([]) as Ref<TodayItem[]>
let itemsLoaded = DateTime.utc()

watch([
  () => exploreStore.startPersonId,
  () => exploreStore.initialTreeLoaded,
], () => {
  if (exploreStore.startPersonId && exploreStore.initialTreeLoaded && exploreStore.initialTreeLoaded != itemsLoaded) {
    const today = DateTime.local()

    const allItems = _.shuffle([
      ...ancestorsToday.getLoadedAncestorsBornOn(today),
      // TODO: get more today items
    ])

    allItems.splice(4)

    for (const item of allItems) {
      if (item.personId) {
        item.relationPath = viewPersonStore.getLoadedRelationPath(exploreStore.startPersonId, item.personId)
      }
    }

    todayItemsRef.value = allItems
    itemsLoaded = exploreStore.initialTreeLoaded
  }
}, { immediate: true })

</script>