<template>
  <div class="relationship-card" :class="{ spouse: props.spouse }">
    <div class="related-person drawer-top">
      <SpouseConnector v-if="props.spouse"></SpouseConnector>
      <PersonCard 
        :person-id="props.personId"
        layout="small"
        show-profile-type
        show-profile-count="multiple"
        link-singleton
        select-mode
        @click="onSelected">
      </PersonCard>
    </div>
    <ul class="list-unstyled profile-list drawer-stack" :class="{ show: showProfilesRef }">
      <li v-for="id in profileIdsRef" :key="id">
        <PersonCard 
          :person-id="id"
          profile
          layout="small">
        </PersonCard>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.relationship-card {
  .related-person {
    //margin-bottom: 0.25rem;
    display: flex;
    align-items: center;

    .person-card {
      width: 400px;
    }
  }

  .profile-list {
    //padding-top: 0rem;
    margin-left: 1rem;
  }

  &.spouse .profile-list {
    margin-left: 3rem;
  }
}
</style>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useViewPersonStore } from '@/rd/ViewPersonStore'
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { ProfileUtils } from './ProfileUtils'
import { Person } from '@/rd/ResearchDataModel'
import SpouseConnector from '@/manage/SpouseConnector.vue'
import PersonCard from '@/manage/PersonCard.vue'

const props = defineProps<{
  personId?: string,
  spouse?: boolean,
}>()

const viewPersonStore = useViewPersonStore()
const dataGroupStore = useDataGroupStore()
const router = useRouter()

const showProfilesRef = ref(false)

const personRef = computed(() => viewPersonStore.getLoadedPerson(props.personId))
const profileIdsRef = computed(() => {
  if (!personRef.value)
    return []
  const spouseDataGroups = dataGroupStore.getLoadedGroupList(personRef.value?.groupIds)
  return ProfileUtils.getNonPlaceholderIds(personRef.value.matchIds, spouseDataGroups)
})

watch(props, () => {
  showProfilesRef.value = false
})

function onSelected() {
  if (personRef.value) {
    if (profileIdsRef.value.length == 1) {
      router.push(Person.getPageUrl(profileIdsRef.value[0]))
    }
    else {
      showProfilesRef.value = !showProfilesRef.value
    }
  }
}

</script>
