<template>
  <div class="profile-parent-rel">
    <FamilyCard
      :family-id="props.parentFamily.family.id"
      layout="small"
      profile
      show-profile-type
      link-singleton>
    </FamilyCard>
    <div class="action-row">
      <button type="button" v-if="canRemoveRef" class="btn btn-inline btn-link action" @click="onRemove">
        Remove
      </button>
    </div>
    <ExpandableItem v-model:expanded="areMatchesExpandedRef" class="expander">
      <template #item>
        <div class="expander-label">
          Relationship defined in <span class="count"> ({{ matchProfileIdsRef.length }})</span>
        </div>
      </template>
      <template #content>
        <div class="matches-area">
          <ul class="list-unstyled matches-list">
            <li v-for="id in matchProfileIdsRef" :key="id">
              <PersonCard
                :person-id="id"
                profile 
                layout="small"
                :placeholder="id == props.personId">
              </PersonCard>
            </li>
          </ul>
        </div>
      </template>
    </ExpandableItem>
    <ProfileChildren
      :profile-person-id="props.personId"
      :family="props.parentFamily"
      :current-child-id="viewPersonRef?.id"
      @add-child="emit('addSibling')">
    </ProfileChildren>
  </div>
</template>

<style lang="scss" scoped>
.profile-parent-rel {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.expander {
  margin-left: 1rem;
  font-size: 0.875rem;
}

.expander-label {
  color: #888;
}

.count {
  font-size: 0.75rem;
  color: #aaa;
}

.matches-area {
  margin-left: 1.25rem;
  padding: 0.5rem 0; // margin-top causes jumping when expanding (??)

  .list-header {
    margin-bottom: 0.5rem;
    font-size: 0.75rem;
    color: #888;
  }

  .matches-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .action-row {
    margin-left: 0;
  }
}

.action-row {
  margin-left: 1rem;
  display: flex;
  gap: 1rem;

  .action {
    font-size: 0.875rem;
  }
}
</style>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { FamilyAndChildren } from '@/rd/ViewFamilyStore'
import { AssertionType } from '@/rd/ResearchDataModel'
import { useViewPersonStore } from '@/rd/ViewPersonStore'
import { useAssertionStore } from '@/rd/AssertionStore'
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { CompositeId } from '@/rd/CompositeId'
import { ItemPermissions } from '@/gp/GroupAdminModel'
import FamilyCard from '@/manage/FamilyCard.vue'
import ExpandableItem from '@/util/ExpandableItem.vue'
import PersonCard from '@/manage/PersonCard.vue'
import ProfileChildren from './ProfileChildren.vue'

const props = defineProps<{
  personId: string,
  parentFamily: FamilyAndChildren,
}>()

const emit = defineEmits([
  'addSibling',
  'remove',
])

const viewPersonStore = useViewPersonStore()
const assertionStore = useAssertionStore()
const dataGroupStore = useDataGroupStore()

const areMatchesExpandedRef = ref(false)

const viewPersonRef = computed(() => viewPersonStore.getLoadedPerson(props.personId))
const matchProfileIdsRef = computed(() => {
  return viewPersonRef.value?.matchIds.flatMap(id => assertionStore.getLoadedForPerson(id))
    .filter(a => a.assertionType == AssertionType.Child && props.parentFamily.family.matchIds.includes(a.relatedItemId!))
    .map(a => a.subjectId) ?? []
})
const profileGroupIdRef = computed(() => CompositeId.getGroupId(props.personId)!)
const permissionsRef = computed(() => dataGroupStore.getLoadedPermissions(profileGroupIdRef.value))
const canEditRef = computed(() => (permissionsRef.value & ItemPermissions.Modify) > 0)
const canRemoveRef = computed(() => canEditRef.value && matchProfileIdsRef.value.includes(props.personId))

function onRemove() {
  emit('remove', props.parentFamily.family)
}
</script>
