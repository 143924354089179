<template>
  <div class="research-tools-editor">
    <ul class="list-unstyled">
      <li v-for="cat in categories" :key="cat" class="category">
        <div class="category-header">
          {{ researchToolCategoryNames[cat] }}
        </div>
        <ul class="list-unstyled">
          <li v-for="t in catTools(cat)" :key="t.id" class="research-tool">
            <label :class="{ checked: isSelected(t.id) }">
              <input type="checkbox"
                class="form-check-input"
                :checked="isSelected(t.id)" 
                @change="e => onSelectedChanged(e, t.id)"/>
              <img :src="t.icon"/>
              {{ t.name }}
            </label>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.category {
  .category-header {
    margin: 1rem 0 0.5rem;
    color: #888;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-left: 0.5rem;

    label {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      font-size: 0.875rem;
      color: #333;
    }

    img {
      height: 24px;
      max-width: 24px;
      object-fit: cover;
    }
  }
}

.research-tool {
  label {
    cursor: pointer;
  }
}
</style>

<script setup lang="ts">
import { ResearchToolCategory, researchToolCategoryNames, researchToolInfos } from '@/rd/ResearchTools'

const props = defineProps<{
  modelValue?: string[]
}>()

const emit = defineEmits([
  'update:modelValue'
])

const categories = Object.keys(ResearchToolCategory)
  .map(key => ResearchToolCategory[key as keyof typeof ResearchToolCategory])
  .filter(value => typeof value == 'number')

function catTools(cat: ResearchToolCategory) {
  return researchToolInfos
    .filter(t => t.category === cat)
    .sort((a, b) => a.name.localeCompare(b.name))
}

function isSelected(id: string) {
  return !!props.modelValue?.includes(id)
}

function onSelectedChanged(e: Event, id: string) {
  const checkbox = e.target as HTMLInputElement
  const otherSelected = (props.modelValue ?? []).filter(t => t != id)
  if (checkbox.checked) {
    emit('update:modelValue', [...otherSelected, id]) // add
  } else {
    emit('update:modelValue', otherSelected) // remove
  }
}
</script>
