<template>
  <div class="account-page">
    <div class="page-title">
      <div class="page-title-text-area">
        <h1>
          {{ titleRef }}
          <div v-if="isDemoUserRef" class="badge">DEMO</div>
        </h1>
      </div>
    </div>
    <ResultIndicator ref="resetSuccessAlert" alert-type="success">
      This account has been reset.
    </ResultIndicator>
    <div v-if="isUserSupervisorRef" class="alert alert-primary alert-sm page-alert supervise-alert">
      <span>
        <LockIcon/> You manage this child account.
      </span>
      <button type="button" class="btn btn-primary btn-sm" @click="onSwitchToUser(userIdRef)">
        Switch to this user
      </button>
    </div>
    <div v-if="isSupervisedRef" class="alert alert-primary alert-sm page-alert supervise-alert">
      <span>
        <LockIcon/> Your account is managed by a parent.
      </span>
    </div>
    <div v-if="!isUserAdminRef"></div>
    <div>
      <ul class="nav nav-pills">
        <li class="nav-item">
          <button type="button" id="profilesTab" class="nav-link active" data-bs-toggle="tab" data-bs-target="#profilesPane" role="tab" aria-controls="profilesPane" aria-selected="false">
            Profiles
          </button>
        </li>
        <li v-if="showResearchGroupsRef" class="nav-item">
          <button type="button" id="researchGroupsTab" class="nav-link" data-bs-toggle="tab" data-bs-target="#researchGroupsPane" role="tab" aria-controls="researchGroupsPane" aria-selected="true">
            Trees
          </button>
        </li>
        <li class="nav-item">
          <button type="button" id="familyGroupsTab" class="nav-link" data-bs-toggle="tab" data-bs-target="#familyGroupsPane" role="tab" aria-controls="familyGroupsPane" aria-selected="true">
            Groups
          </button>
        </li>
        <li v-if="showSubscriptionRef" class="nav-item">
          <button type="button" id="subscriptionTab" class="nav-link" data-bs-toggle="tab" data-bs-target="#subscriptionPane" role="tab" aria-controls="subscriptionPane" aria-selected="true">
            Subscription
          </button>
        </li>
        <li v-if="showSecurityRef" class="nav-item">
          <button type="button" id="securityTab" class="nav-link" data-bs-toggle="tab" data-bs-target="#securityPane" role="tab" aria-controls="securityPane" aria-selected="true">
            Security
          </button>
        </li>
        <li v-if="showSupervisorRef" class="nav-item">
          <button type="button" id="supervisorTab" class="nav-link" data-bs-toggle="tab" data-bs-target="#supervisorPane" role="tab" aria-controls="supervisorPane" aria-selected="true">
            Parent account
          </button>
        </li>
        <li v-if="showAdminRef" class="nav-item">
          <button type="button" id="adminTab" class="nav-link" data-bs-toggle="tab" data-bs-target="#adminPane" role="tab" aria-controls="adminPane" aria-selected="true">
            Admin
          </button>
        </li>
      </ul>
    </div>
    <div class="tab-content content-area">
      <section id="profilesPane" class="tab-pane show active" role="tabpanel" aria-labelledby="profilesTab" tabindex="0">
        <ContactInfo :user-id="userIdRef"/>
        <div class="profile-section">
          <h4>User profile</h4>
          <p class="help-text">
            A user profile contains useful information about you as a Tree Crossing user.
            Anyone who can see you as a user can view your user profile.
          </p>
          <router-link :to="`/users/${userIdRef}`" class="btn btn-primary btn-sm">View my user profile</router-link>
        </div>
        <div class="profile-section">
          <h4>Tree profile</h4>
          <p class="help-text">
            A tree profile is what family members you've shared your tree profile with see about you in the family tree.
            You can add personal information like life events, photos, and family history.
          </p>
          <router-link to="/profile" class="btn btn-primary btn-sm">Edit my tree profile</router-link>
        </div>
        <div>
          <button type="button" v-if="canResetUserRef" class="btn btn-danger mt-3" data-bs-toggle="modal" data-bs-target="#resetUserModal">
            Reset account
          </button>
        </div>
      </section>
      <section v-if="showResearchGroupsRef" id="researchGroupsPane" class="tab-pane" role="tabpanel" aria-labelledby="researchGroupsTab" tabindex="0">
        <ResearchGroupList :user-id="userIdRef"/>
      </section>
      <section id="familyGroupsPane" class="tab-pane" role="tabpanel" aria-labelledby="familyGroupsTab" tabindex="0">
        <FamilyGroupList :user-id="userIdRef"/>
      </section>
      <!-- <section id="activityPane" class="tab-pane" role="tabpanel" aria-labelledby="activityTab" tabindex="0">
        <UserActivity :user-id="userIdRef"/>
      </section> -->
      <section v-if="showSubscriptionRef" id="subscriptionPane" class="tab-pane" role="tabpanel" aria-labelledby="subscriptionTab" tabindex="0">
        <SubscriptionInfo :user-id="userIdRef"/>
      </section>
      <section v-if="showSecurityRef" id="securityPane" class="tab-pane" role="tabpanel" aria-labelledby="securityTab" tabindex="0">
        <SecurityInfo :user-id="userIdRef"/>
      </section>
      <section id="supervisorPane" class="tab-pane" role="tabpanel" aria-labelledby="supervisorTab" tabindex="0">
        <SupervisionEditor :user-id="props.userId"/>
      </section>
      <section id="adminPane" class="tab-pane" role="tabpanel" aria-labelledby="adminTab" tabindex="0">
        <AccountAdmin :user-id="userIdRef"/>
      </section>
    </div>
    <UserSwitchModal ref="userSwitchModalRef"/>
    <Modal id="resetUserModal" ref="resetUserModalRef" ok-style="danger" @ok="resetUser">
      <template #title>Reset your account?</template>
      <div>
        <p class="mb-1">This will:</p>
        <ul>
            <li>Delete any trees you own</li>
            <li>Delete any groups you own</li>
            <li>Leave any groups you've joined</li>
            <li>Delete all data in your profile</li>
        </ul>
      </div>
      <template #okText>Reset now</template>
      <template #busyText>Resetting...</template>
    </Modal>
  </div>
</template>

<style lang="scss" scoped>
.page-title .badge {
  max-height: 1.5rem;
  transform: translateY(-40%);
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  background-color: var(--bs-info);
  color: white;
}

.supervise-alert {
  display: flex;
  align-items: center;

  button {
    margin-left: auto;
  }
}

section#profilesPane.active {
  // only when active! (need display: none when not active)
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.profile-section {
  position: relative;
  margin-bottom: 1rem;

  &:not(:first-child)::before {
    content: '';
    position: absolute;
    top: -1rem;
    left: 0;
    width: 100%;
    max-width: 650px;
    height: 1px;
    border-top: 1px solid #ddd;
  }
}

// h4 button.btn-primary {
//   margin-top: -0.25rem;
//   margin-left: 0.25rem;
//   min-width: 60px;
//   padding: 2px 4px;
// }

</style>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { usePageTitle } from '@/util/AppUtil'
import { useInitialTabRouting } from '@/util/InitialTabRouting'
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { useUserStore } from '@/gp/UserStore'
import { usePersonStore } from '@/rd/PersonStore'
import { useViewPersonStore } from '@/rd/ViewPersonStore'
import { DataGroup, ItemPermissions } from '@/gp/GroupAdminModel'
import { AsyncUtil } from '@/util/AsyncUtil'
import { LoadMode } from '@/util/AsyncData'
import { TokenManager, UserPrivilege } from '@/auth/TokenManager'
import { LockIcon } from '@zhuowenli/vue-feather-icons'
import SecurityInfo from '@/account/SecurityInfo.vue'
import ContactInfo from '@/account/ContactInfo.vue'
import SubscriptionInfo from '@/account/SubscriptionInfo.vue'
import AccountAdmin from '@/account/AccountAdmin.vue'
import Modal from '@/util/Modal.vue'
import ResultIndicator from '@/util/ResultIndicator.vue'
import UserSwitchModal from '@/manage/users/UserSwitchModal.vue'
import FamilyGroupList from '@/manage/familygroups/FamilyGroupList.vue'
import ResearchGroupList from '@/manage/researchgroups/ResearchGroupList.vue'
import SupervisionEditor from '@/manage/users/UserSupervisionEditor.vue'

const props = defineProps<{
  userId?: string
}>();

const userProfileInViewRef = ref(false)
const userProfileGroupRef = ref<DataGroup>()
const userPersonIdRef = ref<string>()
const userPrivilegesRef = ref<UserPrivilege[]>()

const resetSuccessAlert = ref<InstanceType<typeof ResultIndicator>>()
const userSwitchModalRef = ref<InstanceType<typeof UserSwitchModal>>()
const resetUserModalRef = ref<InstanceType<typeof Modal>>()

const route = useRoute()
const userStore = useUserStore()
const viewPersonStore = useViewPersonStore()
const dataGroupStore = useDataGroupStore()
const personStore = usePersonStore()

const userIdRef = computed(() => props.userId ?? TokenManager.userId!)
const isSelfRef = computed(() => userIdRef.value == TokenManager.userId)
const canReadAllUsersRef = computed(() => TokenManager.hasPrivilege(UserPrivilege.ReadAllUsers))
const showSecurityRef = computed(() => isSelfRef.value || canReadAllUsersRef.value)
const showResearchGroupsRef = computed(() => !isSelfRef.value)
const showSubscriptionRef = computed(() => isSelfRef.value || canReadAllUsersRef.value)
const showAdminRef = computed(() => TokenManager.hasPrivilege(UserPrivilege.ReadAllUsers))
const userRef = computed(() => userStore.getAsyncUser(userIdRef.value, LoadMode.EnsureLoaded)?.data)
const permissionsRef = computed(() => userStore.getAsyncPermissions(userIdRef.value, LoadMode.EnsureLoaded)?.data)
const isUserAdminRef = computed(() => permissionsRef.value && (permissionsRef.value & ItemPermissions.Modify) > 0)
const isUserSupervisorRef = computed(() => !isSelfRef.value && isUserAdminRef.value)
const isSupervisedRef = computed(() => isSelfRef.value && permissionsRef.value && !isUserAdminRef.value)
const showSupervisorRef = computed(() => isSelfRef.value || isUserSupervisorRef.value)
const isDemoUserRef = computed(() => userPrivilegesRef.value?.includes(UserPrivilege.DemoUser))
const canResetUserRef = computed(() =>
  (userRef.value?.isTestUser || isDemoUserRef.value) &&
  (isSelfRef.value || TokenManager.hasPrivilege(UserPrivilege.ManageUsers)))

const titleRef = usePageTitle("Account", () => isSelfRef.value ? undefined : userRef.value?.displayName, true)
useInitialTabRouting('profilesTab')

watch(userRef, async () => {
  userProfileInViewRef.value = false
  userProfileGroupRef.value = undefined
  userPersonIdRef.value = undefined
  userPrivilegesRef.value = undefined

  if (userRef.value) {
    const selfViewGroups = await dataGroupStore.getViewGroupsForUserAsync()
    userProfileInViewRef.value = selfViewGroups.some(g => g.id == userRef.value?.profileDataGroupId)

    userPrivilegesRef.value = isSelfRef.value
      ? TokenManager.userPrivileges
      : await userStore.getPrivilegesAsync(userIdRef.value)

    if (userProfileInViewRef.value) {
      userProfileGroupRef.value = await dataGroupStore.getAsyncGroup(userRef.value.profileDataGroupId, LoadMode.EnsureLoaded)!.whenLoadCompleted
      userPersonIdRef.value = userProfileGroupRef.value?.startItemId

      if (userProfileGroupRef.value) {        
        const vp = await viewPersonStore.getAsyncPerson(userPersonIdRef.value, LoadMode.EnsureLoaded)!.whenLoadCompleted
        await Promise.all([
          personStore.getPersonListAsync(vp?.matchIds ?? []),
          dataGroupStore.getGroupListAsync(vp?.groupIds ?? []),
        ])
      }
    }
  }
}, { immediate: true })

function onSwitchToUser(userId: string) {
  userSwitchModalRef.value?.open(userId)
}

async function resetUser() {
  await AsyncUtil.atLeast(500, userStore.resetAsync(userIdRef.value!))

  resetUserModalRef.value?.close()
  resetSuccessAlert.value?.show()
}
</script>
