<template>
  <TaskPage class="signup-later-page" no-title no-back no-action>
    <div class="body">
      <template v-if="notifyRequestedRef">
        <img src="@/assets/icons/green_check_circle_64.png" alt="Checkmark"/>
        <div>
          Got it. 
          We'll send you an email when support for {{ signInStore.getAppDesc() }} is ready.
        </div>
      </template>
      <template v-else>
        <img src="@/assets/tree-crossing-icon-128.png"/>
        <div>
          Sorry we couldn't help you get started today.
          We'll have more options available soon.
        </div>
      </template>
      <div class="link-section">
        <div>
          In the meantime, you can join our community
        </div>
        <div class="links">
          <a href="https://www.facebook.com/groups/1484810245417258"><img src="@/assets/logos/Facebook.png"/></a>
          <a href="https://discord.gg/mFkGM5fH7b"><img src="@/assets/logos/Discord.svg"/></a>
          <a href="https://x.com/treecrossing"><img src="@/assets/logos/X.png"/></a>
        </div>
      </div>
    </div>
  </TaskPage>
</template>

<style lang="scss" scoped>
.body {
  margin: 0 auto;
  max-width: 450px;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  text-align: center;
}

.link-section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.links {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;

  img {
    max-width: 35px;
    height: 30px;
    vertical-align: baseline;
  }
}
</style>

<script setup lang="ts">
import { computed } from 'vue'
import { useSignInStore } from '@webapp/auth/SignInStore'
import TaskPage from '@webapp/util/TaskPage.vue'

const signInStore = useSignInStore()

const notifyRequestedRef = computed(() => {
  const appKey = `${signInStore.app}|${signInStore.os}`
  return signInStore.notifyRequested.has(appKey)
})
</script>
