<template>
  <div>
    <div class="page-title">
      <img src="@/assets/icons/everyone_500.png" class="page-title-icon"/>
      <div class="page-title-text-area" :class="{ 'has-name': hasNameRef }">
        <h1 class="with-subhead">{{ title }}</h1>
        <p class="subhead">Group</p>
      </div>
    </div>
    <div>
      <ul ref="tabsElemRef" class="nav nav-pills group-tabs" role="tablist">
        <li class="nav-item">
          <button type="button" id="peopleTab" class="nav-link active" data-bs-toggle="tab" data-bs-target="#peoplePane" role="tab" aria-controls="peoplePane" aria-selected="true">
            Members
            <span v-if="membersRef.length > 0" class="count">{{ membersRef.length }}</span>
          </button>
        </li>
        <li class="nav-item">
          <button type="button" id="researchGroupsTab" class="nav-link" data-bs-toggle="tab" data-bs-target="#researchGroupsPane" role="tab" aria-controls="researchGroupsPane" aria-selected="true">
            Shared Trees
            <span v-if="sharedResearchGroupsRef.length > 0" class="count">
              {{ sharedResearchGroupsRef.length }}
            </span>
          </button>
        </li>
        <li class="nav-item">
          <button type="button" id="generalTab" class="nav-link" data-bs-toggle="tab" data-bs-target="#generalPane" role="tab" aria-controls="generalPane" aria-selected="false">
            General
          </button>
        </li>
      </ul>
    </div>
    <div class="tab-content content-area">
      <section id="peoplePane" class="tab-pane active" role="tabpanel" aria-labelledby="peopleTab" tab-index="0">
        <div class="scope-family-area">
          <h5 class="area-header">
            <img src="@/assets/icons/mini_tree_150.png"/>
            Common ancestors
          </h5>
          <div class="area-subhead">
            Group members should be living or recently living descendants (or ancestors) of this family.
          </div>
          <FamilyCard :familyId="familyGroupRef?.scopeFamilyId" ensure-loaded></FamilyCard>
          <div v-if="canEditRef" class="minor-actions">
            <button type="button" class="btn btn-inline btn-link" data-bs-toggle="modal" data-bs-target="#changeScopeModal">
              <span v-if="familyGroupRef?.scopeFamilyId">Change</span>
              <span v-else>Select a family</span>
            </button>
          </div>
        </div>
        <div class="members-area">
          <h5 class="area-header">
            Group members
          </h5>
          <div class="area-subhead">
            Group members can see each other in their family trees,
            as well as any profiles or trees shared with the group.
            Group members should already know each other.
          </div>
          <FamilyGroupMemberList v-if="canListItemsRef" ref="memberListRef" :family-group-id="props.familyGroupId"/>
          <div v-else class="alert alert-primary alert-sm">
            <LockIcon/> You do not have permission to see the members of this group.
          </div>
        </div>
      </section>
      <section id="researchGroupsPane" class="tab-pane" role="tabpanel" aria-labelled-by="researchGroupsTab" tab-index="0">
        <div class="area-subhead">
          Members of the group can explore these trees together.
          Sharing a tree does not allow anyone to edit it.
        </div>
        <div v-if="canListItemsRef">
          <h5 class="area-header">
            Shared
          </h5>
          <ul class="list-unstyled research-groups-list">
            <li v-if="sharedResearchGroupsRef.length == 0" class="empty-list">
              None
            </li>
            <li v-for="rg in sharedResearchGroupsRef" :key="rg.id">
              <ResearchGroupCard :data-group-id="rg.id"></ResearchGroupCard>
              <div class="minor-actions">
                <button type="button" v-if="canEditRef" class="btn btn-inline btn-link" @click="onRemoveResearchGroup(rg.id!)">
                  Remove
                </button>
              </div>
            </li>
          </ul>
          <h5 class="area-header">
            Sharing requests
          </h5>
          <div class="area-options">
            <label class="form-label">
              <input type="checkbox" v-model="showClosedRequestsRef" class="form-check-input">&nbsp;
              Show approved and expired
            </label>
          </div>
          <ul class="list-unstyled research-group-invites-list">
            <li v-if="researchGroupInvitesRef.length == 0" class="empty-list">
              None
            </li>
            <li v-for="inv in researchGroupInvitesRef" :key="inv.id">
              <ResearchGroupCard :invite-id="inv.id" small select-mode @selected="onSelectDataGroupInvite(inv.id!)"></ResearchGroupCard>
            </li>
          </ul>
          <div v-if="canEditRef" class="add-data-group">
            <button type="button" class="btn btn-primary btn-sm" @click="onShareResearchGroup">
              <PlusIcon size="1.2x" class="mb-1"></PlusIcon>&nbsp;
              <span v-if="sharedResearchGroupsRef.length == 0">Share a tree</span>
              <span v-else>Share another tree</span>
            </button>
          </div>
        </div>
        <div v-else class="alert alert-primary alert-sm">
          <LockIcon/> You do not have permission to see the trees shared with this group.
        </div>
      </section>
      <section id="generalPane" class="tab-pane" role="tabpanel" aria-labelled-by="generalTab" tab-index="0">
        <div class="general-content">
          <div>
            <div>
              <label for="nameInput" class="form-label">
                Group name
                <button type="button" v-if="canEditRef" class="btn btn-inline btn-link inline-action" @click="notImplemented">
                  edit
                </button>
              </label>
              <input type="text" v-if="false" class="form-control" id="nameInput"/>
              <div class="input-value">{{ familyGroupRef?.name }}</div>
            </div>
          </div>
          <div class="owner-area">
            <label class="form-label">
              Group owner
              <button type="button" v-if="canEditRef" class="btn btn-inline btn-link inline-action" @click="notImplemented">
                change
              </button>
            </label>
            <UserItem :user-id="familyGroupRef?.ownerId"></UserItem>
          </div>
          <div>
            <label class="form-label">Created</label>
            <div class="input-value">
              {{ DateTimeUtil.toUserActionDate(familyGroupRef?.createdDate) }}
              <span class="date-relative">({{ DateTimeUtil.toRelativePastDate(familyGroupRef?.createdDate) }})</span>
            </div>
          </div>
          <div class="actions">
            <button type="button" id="confirmDelete" v-if="canEditRef" class="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#deleteModal">
              <Trash2Icon></Trash2Icon> Delete group
            </button>
          </div>
        </div>
      </section>
    </div>
    <ChooseFamilyModal id="changeScopeModal" ref="changeScopeModalRef" @ok="changeScope"></ChooseFamilyModal>
    <DataGroupChooseShareModal 
      ref="dataGroupChooseModalRef" 
      @requestShare="onRequestShare">
    </DataGroupChooseShareModal>
    <DataGroupInviteModal
      ref="dataGroupInviteModalRef">
    </DataGroupInviteModal>
    <Modal ref="removeResearchGroupModalRef" 
      ok-style="danger" 
      @ok="removeResearchGroup(selectedResearchGroupIdRef!)">
      <template #title>Remove tree</template>
      <div class="remove-research-group">
        <p>Are you sure you want to stop sharing this tree with the group?</p>
        <ResearchGroupCard :data-group-id="selectedResearchGroupIdRef" small></ResearchGroupCard>
      </div>
      <template #okIcon><Trash2Icon></Trash2Icon></template>
      <template #okText>Remove</template>
      <template #busyText>Removing...</template>
    </Modal>
    <Modal id="deleteModal" ref="deleteModalRef" ok-style="danger" @ok="deleteGroup">
      <template #title>Delete group</template>
      <div>
        <p>Are you sure you want to delete this group?</p>
      </div>
      <template #okIcon><Trash2Icon></Trash2Icon></template>
      <template #okText>Delete</template>
      <template #busyText>Deleting...</template>
    </Modal>
  </div>
</template>

<style lang="scss" scoped>
.page-title {
  //margin-bottom: 1rem;

  .page-title-icon {
    margin-top: 0.5rem;
    margin-right: 0.75rem;
    width: 55px;
  }

  .page-title-text-area {
    &.has-name h1 {
      font-size: 1.7rem; // smaller to accommodate long group names
    }

    &:not(.has-name) .subhead {
      display: none;
    }
  }
}

.nav.group-tabs {
  max-width: 800px;
}

.section-help {
  max-width: 700px;
  font-size: 0.875rem;
  color: #888;
}

.inline-action {
  margin-left: 0.5rem;
  font-size: 0.75rem;
}

.area-header {
  font-size: 1rem;
  font-weight: 400;

  img {
    margin-top: -4px;
    margin-right: 0.25rem;
    width: 20px;
    filter: contrast(0.5) sepia(1) hue-rotate(130deg) brightness(2.0) saturate(3);
  }
}

.area-subhead {
  max-width: 600px;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  color: #888;
}

.minor-actions {
  margin-top: 0.5rem;

  button.btn-link {
    font-size: 0.875rem;
  }
}

.date-relative {
  font-size: 0.875rem;
  color: #888;
}

.general-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .owner-area {

    .user-item {
      margin-left: 0.25rem;
    }
  }

  .check-secondary {
    font-size: 0.875rem;
    color: #888;
  }
}

#researchGroupsPane {
  .research-groups-list {
    margin: 0.5rem 1rem 1.5rem;
  }

  .research-group-invites-list {
    margin: 0.5rem 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .add-research-group {
    margin-top: 2rem;
    font-size: 0.875rem;
  }
}

#peoplePane {
 
  .scope-family-area {
    margin-bottom: 1.5rem;

    .family-card {
      margin-top: 0.5rem;
      margin-left: 1rem;
    }

    .minor-actions {
      margin-left: 2rem;
    }
  }

  .members-area {
    .area-head {
      margin: 0.5rem 0 0.5rem;
    }
  }
}

.empty-list {
  font-size: 0.875rem;
  color: #888;
}

.actions {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  font-size: 0.875rem;
}

::v-deep .remove-research-group {
  .research-group-card {
    margin-left: 1rem;
  }
}
</style>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { notImplemented, usePageTitle } from '@/util/AppUtil'
import { DataGroupType, InvitationResult, ItemPermissions, PrincipalType } from '@/gp/GroupAdminModel'
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { useDataGroupMemberStore } from '@/gp/DataGroupMemberStore'
import { useDataGroupInviteStore } from '@/gp/DataGroupInviteStore'
import { useFamilyGroupStore } from '@/gp/FamilyGroupStore'
import { useFamilyGroupMemberStore } from '@/gp/FamilyGroupMemberStore'
import { ViewFamily } from '@/rd/ResearchDataModel'
import { useViewFamilyStore } from '@/rd/ViewFamilyStore'
import { useViewPersonStore } from '@/rd/ViewPersonStore'
import { useWorkspaceStore } from '@/rd/WorkspaceStore'
import { PatchChange } from '@/util/Api'
import { isDefined } from "@/util/TypeScriptUtil";
import { LockIcon, Trash2Icon, PlusIcon } from '@zhuowenli/vue-feather-icons'
import { LoadMode } from '@/util/AsyncData'
import { DateTimeUtil } from '@/util/LuxonUtil'
import { useTabsTelemetry } from '@/util/TabTelemetry'
import ChooseFamilyModal from '@/manage/ChooseFamilyModal.vue'
import UserItem from '@/manage/users/UserItem.vue'
import FamilyCard from '@/manage/FamilyCard.vue'
import ResearchGroupCard from '@/manage/researchgroups/ResearchGroupCard.vue'
import DataGroupChooseShareModal from '@/manage/datagroups/DataGroupChooseShareModal.vue'
import FamilyGroupMemberList from './FamilyGroupMemberList.vue'
import Modal from '@/util/Modal.vue'
import _ from 'lodash'
import DataGroupInviteModal from '@/manage/datagroups/DataGroupInviteModal.vue'

const props = defineProps<{
  familyGroupId: string
}>()

const selectedResearchGroupIdRef = ref<string>()
const showClosedRequestsRef = ref(false)

const tabsElemRef = ref<HTMLElement>()
const memberListRef = ref<InstanceType<typeof FamilyGroupMemberList>>()
const changeScopeModalRef = ref<InstanceType<typeof ChooseFamilyModal>>()
const dataGroupChooseModalRef = ref<InstanceType<typeof DataGroupChooseShareModal>>()
const dataGroupInviteModalRef = ref<InstanceType<typeof DataGroupInviteModal>>()
const removeResearchGroupModalRef = ref<InstanceType<typeof Modal>>()
const deleteModalRef = ref<InstanceType<typeof Modal>>()

const familyGroupStore = useFamilyGroupStore()
const familyGroupMemberStore = useFamilyGroupMemberStore()
const viewFamilyStore = useViewFamilyStore()
const viewPersonStore = useViewPersonStore()
const dataGroupStore = useDataGroupStore()
const dataGroupMemberStore = useDataGroupMemberStore()
const dataGroupInviteStore = useDataGroupInviteStore()
const workspaceStore = useWorkspaceStore()
const router = useRouter()

const familyGroupRef = computed(() => familyGroupStore.getAsyncGroup(props.familyGroupId, LoadMode.EnsureLoaded)?.data)
const permissionsRef = computed(() => familyGroupStore.getAsyncPermissions(props.familyGroupId, LoadMode.EnsureLoaded)?.data)
const canListItemsRef = computed(() => (permissionsRef.value ?? 0) & ItemPermissions.ListItems)
const canEditRef = computed(() => (permissionsRef.value ?? 0) & ItemPermissions.Modify)
const hasNameRef = computed(() => !!familyGroupRef.value?.name)
const title = computed(() => familyGroupRef.value?.name ? familyGroupRef.value.name : "New group")
const membersRef = computed(() => familyGroupMemberStore.getMembersForFamilyGroups([props.familyGroupId]))
const sharedResearchGroupsRef = computed(() => {
  const sharedDataGroupIds = dataGroupMemberStore.getMembersForFamilyGroups([props.familyGroupId], LoadMode.EnsureLoaded)
    .map(m => m.dataGroupId)
  return dataGroupStore.getAsyncGroupList(sharedDataGroupIds) // loaded with members
    .map(a => a.data).filter(isDefined)
    .filter(m => m.groupType == DataGroupType.Research)
})
const sharedResearchGroupIdsRef = computed(() => sharedResearchGroupsRef.value.map(rg => rg.id!))
const researchGroupInvitesRef = computed(() => {
  const inviteIds = dataGroupInviteStore.getAsyncKeyListForFamilyGroup(props.familyGroupId, LoadMode.EnsureLoaded)?.data?.keys ?? []
  return dataGroupInviteStore.getAsyncInviteList(inviteIds) // loaded with key list
    .map(a => a.data).filter(isDefined)
    .filter(inv => showClosedRequestsRef.value || inv.result == InvitationResult.Pending)
})
watch(researchGroupInvitesRef, () => {
  // ensure workspaces are loaded too
  workspaceStore.getAsyncKeyListsForGroups(researchGroupInvitesRef.value.map(i => i.dataGroupId), LoadMode.EnsureLoaded)
}, { immediate: true })

usePageTitle("Group", () => familyGroupRef.value?.name)
useTabsTelemetry(tabsElemRef)

// actions
async function changeScope(newFamilyId: string) {
  const viewFamily = viewFamilyStore.getAsyncFamily(newFamilyId)?.data // should be loaded by choose family modal
  const defaultGroupName = getDefaultGroupName(viewFamily)

  const changes = [
    new PatchChange("/scopeFamilyId", newFamilyId),
    new PatchChange("/name", defaultGroupName)
  ]
  await familyGroupStore.patchAsync(props.familyGroupId!, changes)
}

function getDefaultGroupName(scopeFamily: ViewFamily | undefined) {
  const father = viewPersonStore.getAsyncPerson(scopeFamily?.fatherId)?.data
  const mother = viewPersonStore.getAsyncPerson(scopeFamily?.motherId)?.data
  return ViewFamily.getDisplayName(father, mother)
}

function onShareResearchGroup() {
  dataGroupChooseModalRef.value?.open(props.familyGroupId, sharedResearchGroupIdsRef.value)
}

function onRequestShare(dataGroupId: string) {
  dataGroupInviteModalRef.value?.openNew(dataGroupId, props.familyGroupId)
}

function onSelectDataGroupInvite(inviteId: string) {
  dataGroupInviteModalRef.value?.openEdit(inviteId)
}

function onRemoveResearchGroup(id: string) {
  selectedResearchGroupIdRef.value = id
  removeResearchGroupModalRef.value?.open()
}

async function removeResearchGroup(dataGroupId: string) {
  const dm = await dataGroupMemberStore.getMemberByPrincipalAsync(dataGroupId, PrincipalType.FamilyGroup, props.familyGroupId)
  if (dm) {
    await dataGroupMemberStore.deleteAsync(dm)
  }

  removeResearchGroupModalRef.value?.close()
}

async function deleteGroup() {
  await familyGroupStore.deleteAsync(props.familyGroupId!)
  deleteModalRef.value?.close()
  router.push("/groups")
}
</script>
