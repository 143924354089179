import { onUnmounted } from "vue"
import { TokenManager } from "@webapp/auth/TokenManager"
import { Environment } from "@webapp/util/Environment"
import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr'
import { SyncState } from "./ResearchDataModel"
import { useWorkspaceStore } from "./WorkspaceStore"
import { useSyncStore } from "./SyncStore"
import { LoadMode } from "@webapp/util/AsyncData"

const rdUrl = Environment.get('RESEARCH_DATA_URL')
let notificationHub: HubConnection | undefined;

type Listener = (args: any) => void
const listenerSets = new Map<string, Set<Listener>>()

export function useResearchDataNotifications() {
    const localListeners: Listener[] = []

    function initialize() {
        if (!TokenManager.isAccessTokenValid)
            return

        const workspaceStore = useWorkspaceStore()
        const syncStore = useSyncStore()

        notificationHub = new HubConnectionBuilder()
            .withUrl(`${rdUrl}notifications`, {
                transport: HttpTransportType.WebSockets | HttpTransportType.LongPolling,
                accessTokenFactory: async () => (await TokenManager.getAccessTokenAsync()) ?? '',
            })
            .withAutomaticReconnect()
            .build()

        notificationHub.on('Group.DataChanged', (args: GroupDataChangedArgs) => {
            console.log(`Group.DataChanged: ${JSON.stringify(args)}`)
            listenerSets.get('Group.DataChanged')?.forEach(ls => ls(args))
        })

        notificationHub.on('Sync.Added', async (args: SyncAddedArgs) => {
            console.log(`Sync.Added: ${JSON.stringify(args)}`)

            // CONSIDER: reload only if already present?

            // this should load both the workspace (which may be new) and the last sync for the workspace,
            // which should be the one that was just added
            await workspaceStore.getWorkspacesForGroupsAsync([args.groupId], LoadMode.Reload)
            
            listenerSets.get('Sync.Added')?.forEach(ls => ls(args))
        })

        notificationHub.on('Sync.StateChanged', async (args: SyncStateChangedArgs) => {
            console.log(`Sync.StateChanged: ${JSON.stringify(args)}`)

            const asyncSync = syncStore.asyncSyncs.get(args.itemId)
            if (asyncSync) {
                const newSync = await syncStore.getAsyncSync(args.itemId, LoadMode.Reload)?.whenLoadCompleted
                console.log(`Sync.Reloaded: ${JSON.stringify(newSync)}`)
            }

            listenerSets.get('Sync.StateChanged')?.forEach(ls => ls(args))
        })
        
        notificationHub.start()

        onUnmounted(() => {
            if (notificationHub) {
                notificationHub.stop()
                notificationHub = undefined
            }
        })    
    }

    const getConnectionId = () => notificationHub?.connectionId

    function addListener(methodName: string, listener: (args: any) => void) {
        if (!listenerSets.has(methodName)) {
            listenerSets.set(methodName, new Set())
        }
        listenerSets.get(methodName)!.add(listener)
        localListeners.push(listener)
    }

    function onGroupDataChanged(listener: (args: GroupDataChangedArgs) => void) {
        addListener('Group.DataChanged', listener)
    }

    function onSyncAdded(listener: (args: SyncStateChangedArgs) => void) {
        addListener('Sync.Added', listener)
    }

    function onSyncStateChanged(listener: (args: SyncStateChangedArgs) => void) {
        addListener('Sync.StateChanged', listener)
    }

    onUnmounted(() => {
        // remove all local listeners
        localListeners.forEach(ls => {
            listenerSets.forEach((s: Set<Listener>) => {
                s.delete(ls) // if present
            })
        })
    })

    return {
        initialize,
        getConnectionId,
        onGroupDataChanged,
        onSyncAdded,
        onSyncStateChanged,
    }
}

export interface GroupDataChangedArgs {
    groupId: string
    syncId: string
    trackingGroupIds: string[]
}

export interface SyncAddedArgs {
    itemId: string
    groupId: string
}

export interface SyncStateChangedArgs {
    itemId: string
    state: SyncState
}
