import { DataGroup, DataGroupType, User } from "@/gp/GroupAdminModel"
import { Family, Person } from "@/rd/ResearchDataModel"
import { CompositeId } from "@/rd/CompositeId"
import { MaybeRefOrGetter, computed, toValue } from "vue"
import { isDefined } from "@/util/TypeScriptUtil"

export class ProfileUtils {
    /**
     * Gets a value indicating whether the specified person is a placeholder in the specified group. 
     * @param personId a person id
     * @param dataGroup the data group for the specified person
     * @returns True if the data group is a profile group, and the person is NOT the group's primary person.
     */
    static isPlaceholder(personId: string, dataGroup: DataGroup) {
        if (!CompositeId.hasGroupId(personId, dataGroup.id!))
            throw `personId ${personId} is not in dataGroup ${dataGroup.id}}`

        return dataGroup.groupType == DataGroupType.Profile && dataGroup.startItemId != personId;
    }

    /**
     * Gets a list of person ids that are NOT placeholders in the specified data groups. Persons in research groups are included.
     * @param personIds the source list of person ids
     * @param dataGroups the data groups for the specified person ids (should all be present)
     * @returns 
     */
    static getNonPlaceholderIds(personIds: string[], dataGroups: DataGroup[]) {
        return personIds.filter(personId => {
            const dataGroup = dataGroups.find(g => g.id == CompositeId.getGroupId(personId))
            return dataGroup && !ProfileUtils.isPlaceholder(personId, dataGroup)
        })
    }

    /**
     * Gets a value indicating whether the specified person is the primary person in a profile group.
     * @param personId a person id
     * @param dataGroup the data group for the specified person
     * @returns true if the group is a profile group and the person is the primary person (i.e. NOT a placeholder) in the group
     */
    static isProfilePerson(personId: string, dataGroup: DataGroup) {
        if (!CompositeId.hasGroupId(personId, dataGroup.id!))
            throw `personId ${personId} is not in dataGroup ${dataGroup.id}}`
        
        return dataGroup.groupType == DataGroupType.Profile && dataGroup.startItemId == personId
    }
    
    static getProfilePersonIds(personIds: string[], dataGroups: DataGroup[]) {
        return personIds.filter(personId => {
            const personGroupId = CompositeId.getGroupId(personId)
            const dataGroup = dataGroups.find(g => g.id == personGroupId)
            return dataGroup && ProfileUtils.isProfilePerson(personId, dataGroup)
        })
    }

    static getProfileType(person: Person | undefined, dataGroup: DataGroup | undefined, owner: User | undefined) {
        if (dataGroup?.groupType == DataGroupType.Research) {
            return person?.restricted ? ProfileType.ResearchRestricted : ProfileType.Research
        }
        if (person?.placeholder) {
            return ProfileType.Placeholder
        }
        if (owner?.profileDataGroupId == dataGroup?.id) {
            return ProfileType.User
        }
        if (dataGroup?.workspaceId) {
            return ProfileType.ManagedLinked
        }
        return (person && dataGroup && owner) ? ProfileType.Managed : undefined
    }

    static getProfileSubtitle(profileType?: ProfileType) {
        switch (profileType) {
            case ProfileType.Research:  return "Tree profile managed by "
            case ProfileType.ResearchRestricted:  return "Tree profile managed by "
            case ProfileType.Managed: return "Profile managed by "
            case ProfileType.ManagedLinked:  return "Linked profile managed by "
            case ProfileType.User:  return "Tree profile for "
            case ProfileType.Placeholder:  return "Placeholder profile managed by "
            default: return undefined
        }
    }

    static getComputedProfileSubtitle(person: MaybeRefOrGetter<Person | undefined>, dataGroup: MaybeRefOrGetter<DataGroup | undefined>, owner: MaybeRefOrGetter<User | undefined>) {
        // TODO: Isn't it better to just do this at the call site?
         return computed(() => {
            return this.getProfileSubtitle(this.getProfileType(toValue(person), toValue(dataGroup), toValue(owner)))
        })
    }

    static getProfileSpouseFamilyIds(families: Family[], dataGroups: DataGroup[]) {
        // include families from profile groups where the primary person is a spouse
        const profileGroupMatchIds = dataGroups
            .filter(dg => dg.groupType == DataGroupType.Profile)
            .map(dg => ({ group: dg, family: families.find(f => f.groupId == dg.id)}))
            .filter(gf => gf.family?.hasSpouse(gf.group.startItemId!))
            .map(gf => gf.group.startItemId!)
        // include father (or mother if not present) of matching families in research groups
        const researchGroupMatchIds = dataGroups
            .filter(dg => dg.groupType == DataGroupType.Research)
            .map(dg => families.find(f => f.groupId == dg.id)!)
            .filter(isDefined)
            .map(f => (f.fatherId ?? f.motherId)!)

        return [...profileGroupMatchIds, ...researchGroupMatchIds]    
    }

}

export enum ProfileType {
    Research = 0,
    ResearchRestricted = 1,
    Managed = 2,
    ManagedLinked = 3,
    User = 4,
    Placeholder = 5,
}