<template>
  <div class="person-list-item" :class="{ 'user': isUserRef, disabled: props.disabled }">
    <div class="gender-bar" :class="genderClassRef"></div>
    <img src="@/assets/icons/person_gray_150.png"/>
    <span v-if="viewPersonRef">
      {{ viewPersonRef.displayName }} 
      <small v-if="viewPersonRef.birthYear">({{ viewPersonRef.birthYear }})</small>
    </span>
  </div>
</template>

<style lang="scss" scoped>
.person-list-item {
  display: flex;
  gap: 0.25rem;
  align-items: center;

  .gender-bar {
    width: 8px;
    align-self: stretch;
    background-color: #e9e9e9;

    &.male {
      background-color: #c3d5ea;
    }

    &.female {
      background-color: #fadce1;
    }
  }

  img {
    width: 20px;
    opacity: 20%;
  }

  &.user img {
    opacity: 60%;
  }

  &.disabled {
    opacity: 50%;
  }
}
</style>

<script lang="ts" setup>
import { computed } from "vue"
import { useViewPersonStore } from "@/rd/ViewPersonStore"
import { useProfileUserCache } from "@/manage/profiles/ProfileUserCache"

const props = defineProps<{
  personId?: string
  disabled?: boolean
}>()

const viewPersonStore = useViewPersonStore()
const profileUserCache = useProfileUserCache()

const viewPersonRef = computed(() => viewPersonStore.getAsyncPerson(props.personId)?.data)
const genderClassRef = computed(() => [viewPersonRef.value?.displayProperties.gender?.toLowerCase()])

const isUserRef = computed(() => {
  for (const personId of viewPersonRef.value?.matchIds ?? []) {
    if (profileUserCache.getProfileUser(personId))
      return true
  }
  return false
})

</script>