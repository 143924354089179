<template>
  <div class="profile-matches">
    <Construction></Construction>
    <div v-if="showSetsRef" class="instructions">
      Select a match set owner to see which profiles it considers matches.
    </div>
    <div v-else-if="personSetsByOwnerIdRef.size" class="instructions">
      These profiles are matches in your view:
    </div>
    <div v-else class="instructions">
      This profile is not included in any match sets.
    </div>
    <div class="options">
      <label v-if="TokenManager.isPrivilegedUser" class="form-label">
        <input type="checkbox" v-model="showSingletonSetsRef" :disabled="!hasSingletonsRef" class="form-check-input"/>
        Show singleton sets
      </label>
    </div>
    <ul class="list-unstyled matches-list" :class="{ ['show-members']: showSetsRef }">
      <li v-for="id in matchIdsRef" :key="id.value">
        <button type="button" v-if="showSetsRef && personSetsByOwnerIdRef.get(id.value)" class="btn btn-inline set-indicator" 
          :class="{ current: selectedOwnerIdRef == id.value }"
          @click="toggleSelectedItem(id.value)">
        </button>
        <input type="checkbox" v-if="showSetsRef" class="form-check-input" 
          :checked="getMembership(id.value) == true"
          :disabled="!canModifySetRef || !isEditModeRef"
        />
        <PersonCard :person-id="id.value" profile layout="small" 
          :select-mode="showSetsRef"
          @click="toggleSelectedItem(id.value)">
        </PersonCard>
      </li>
    </ul>
    <div v-if="canShowSetsRef && !showSetsRef">
      <button type="button" class="btn btn-inline btn-link action" @click="showSetsRef = true">
        Show match sets
      </button>
    </div>
    <ul v-if="showSetsRef && otherPersonSetOwnerGroupsRef.length" class="list-unstyled other-owners-list" :class="{ ['show-members']: showSetsRef }">
      <li v-for="dg in otherPersonSetOwnerGroupsRef" :key="dg.id">
        <button type="button" v-if="personSetsByOwnerIdRef.get(dg.id!)" 
          class="btn btn-inline set-indicator" 
          :class="{ current: selectedOwnerIdRef == dg.id }"
          @click="toggleSelectedItem(dg.id!)">
        </button>
        <PersonCard v-if="dg.groupType == DataGroupType.Profile" :person-id="dg.startItemId" profile layout="small" select-mode></PersonCard>
        <ResearchGroupItem v-if="dg.groupType == DataGroupType.Research" :data-group-id="dg.id"></ResearchGroupItem>
        <SystemGroupItem v-if="dg.groupType == DataGroupType.System" @click="toggleSelectedItem(dg.id!)"></SystemGroupItem>
      </li>
    </ul>
    <div v-if="showSetsRef" class="set-details">
      <span v-if="selectedSetRef">
        Match set last modified {{ DateTimeUtil.toUserActionDate(selectedSetRef?.modifiedDate) }} by 
        <UserLink :user-id="selectedSetRef?.modifiedUserId" dimmed></UserLink>
      </span>
      <span v-else-if="selectedOwnerIdRef">
        This profile does not define a match set
      </span>
      <span v-else>
        No match set selected
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.profile-matches {
  .construction {
    margin-bottom: 1rem;
  }

  .instructions {
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    color: #888;
  }

  .options {
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    input[type="checkbox"] {
      margin-right: 0.25rem;
    }
  }

  .matches-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    li {
      display: grid;
      grid-template-columns: 0 0 auto;
      column-gap: 0;
      align-items: center;

      .form-check-input {
        grid-column: 2;
      }

      .person-card {
        grid-column: 3;
      }
    }

    .match-placeholder > button {
      height: 48px;
      width: 400px;
      border: 1px dashed #ddd;
      padding-left: 1.5rem;
      display: flex;
      align-items: center;
      font-size: 0.875rem;
      color: #bbb;
    }

    &.show-members {
      li {
        grid-template-columns: 28px 16px auto;
        column-gap: 0.5rem;
      }
    }
  }

  .set-indicator {
    position: relative;
    width: 16px;
    height: 16px;
    margin-top: 4px;
    margin-left: 2px;
    border: 2px solid #aaa;
    border-radius: 50%;
    transition: none;

    &.current::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 6px;
      height: 6px;
      background-color: #000;
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .other-owners-list {
    max-width: 500px;
    border-top: 1px solid #eee;
    margin-top: 1.5rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    li {
      display: grid;
      grid-template-columns: 52px auto;
      align-items: center;
      column-gap: 0.5rem;
    }
  }

  .set-details {
    height: 2rem; // take up space even if no content
    font-size: 0.875rem;
    color: #aaa;
  }

  .person-card {
    width: 400px;
  }

  .action {
    font-size: 0.875rem;
  }
}
</style>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import { useProfileMatches } from './ProfileMatches'
import { DataGroupType } from '@/gp/GroupAdminModel'
import { TokenManager } from '@/auth/TokenManager'
import { DateTimeUtil } from '@/util/LuxonUtil'
import _ from 'lodash'
import PersonCard from '@/manage/PersonCard.vue'
import ResearchGroupItem from '@/manage/researchgroups/ResearchGroupItem.vue'
import SystemGroupItem from '@/manage/SystemGroupItem.vue'
import UserLink from '@/manage/users/UserLink.vue'
import Construction from '@/util/Construction.vue'

const props = defineProps<{
  personId?: string,
}>()

const includePlaceholdersRef = ref(false)
const { 
  profileMatchIdsRef,
  personSetOwnerGroupsRef,
  personSetsByOwnerIdRef,
  otherPersonSetOwnerGroupsRef
} = useProfileMatches(() => props.personId)

const showSetsRef = ref(false)
const showSingletonSetsRef = ref(false)
const canShowSetsRef = computed(() => personSetsByOwnerIdRef.value.size > 0)

// a "singleton" is a set with only one member. These sets have no effect, so we normally do not
// show them in the UI. If the group owner decides to define a set that includes that member, the
// existing set will be extended, and will then become visible again.

// a "placeholder" person is an empty non-primary person in a profile group that is used to represent a 
// relationship to another person

// a relationship person set should only be shown as a profile match set if it contains more than one
// member other than the placeholder person. This person not only defines the relationship but also 
// contributes to the combination of other profiles into a match set.

// the profile page should only show relationships defined by that profile
// if relationships inherited from matches are visible, they should clearly indicate they come from elsewhere
// related persons are chosen and presented as view persons (not profiles)
// the user has the option to see and edit the "related to" match set defined by the profile

const matchIdsRef = computed(() => profileMatchIdsRef.value.filter(id => showSetsRef.value || id.value != props.personId))
const hasSingletonsRef = computed(() => [...personSetsByOwnerIdRef.value.values()].some(set => set.memberIds.length == 1))

const selectedOwnerIdRef = ref<string | undefined>(undefined)
const selectedSetRef = computed(() => selectedOwnerIdRef.value ? personSetsByOwnerIdRef.value.get(selectedOwnerIdRef.value) : undefined)

const canModifySetRef = computed(() => personSetOwnerGroupsRef.value.get(selectedSetRef.value?.groupId ?? '')?.ownerId == TokenManager.userId)
const isEditModeRef = ref(false)

watch(() => props.personId, () => {
  // reset interactive state
  showSetsRef.value = false
  selectedOwnerIdRef.value = undefined
  isEditModeRef.value = false
})

watch(profileMatchIdsRef, () => {
  selectedOwnerIdRef.value = profileMatchIdsRef.value.at(0)?.value
})

function getMembership(personId: string) {
  return selectedSetRef.value?.memberIds.includes(personId)
}

function toggleSelectedItem(id: string) {
  selectedOwnerIdRef.value = selectedOwnerIdRef.value == id ? undefined : id
}

function getSetMembershipForProfile(id: string) {
  // NOTE: return a value for each set (whether included or not), always in the same order
  return false //return dataGroupsRef.value.map(dg => ({ id: dg.id, included: setsByGroupIdRef.value?.get(dg.id!)?.memberIds.includes(id) ?? false }))
}

function defineSet() {
  // do something
}

</script>