<template>
  <div class="family-group-create-page container">
    <h1 class="page-title">
      {{ title }}
    </h1>
    <p class="instruction">
      It's easy to share your tree. Just create a group, share your tree with it,
      and invite family members to join.
    </p>
    <section>
      <h4>{{ shareTreesTextRef }}</h4>
      <p>Living people in your tree won't be shared by default.</p>
      <LoadingIndicator v-if="loadingRef"></LoadingIndicator>
      <ul v-else class="list-unstyled research-group-list">
        <li v-for="rg in ownedResearchGroupsRef" :key="rg.id">
          <input type="checkbox" :checked="isSelected(rg.id!)" :disabled="busyRef" @change="toggleSelection(rg.id!)">
          <ResearchGroupItem :data-group-id="rg.id" small ensure-loaded></ResearchGroupItem>
        </li>
      </ul>
    </section>
    <section>
      <h4>Choose the group's common ancestors:</h4>
      <p>We'll only suggest group members who are descendants of this family.</p>
      <LoadingIndicator v-if="loadingRef"></LoadingIndicator>
      <ul v-else class="list-unstyled scope-family-list">
        <li v-for="f in scopeFamiliesRef" :key="f.id">
          <input type="radio" :value="f.id" v-model="scopeFamilyIdRef" :disabled="busyRef">
          <CompactItem class="scope-family-item" select-mode @click="scopeFamilyIdRef = f.id">
            <template #icon>
              <img src="@/assets/icons/couple_gray_150.png">
            </template>
            <template #title>{{ f.name }}</template>
            <template #subtitle>{{ relationshipText.get(f.relationship) }}</template>
          </CompactItem>
        </li>
        <li class="no-scope">
          <label>
            <input type="radio" :value="undefined" v-model="scopeFamilyIdRef" :disabled="busyRef">
            I'll choose later
          </label>            
        </li>
      </ul>
    </section>
    <section>
      <h4>Share profiles</h4>
      <p>
        This will allow suggested group members to see each other in the tree.
      </p>
      <div class="share-profiles-option">
        <label>
          <input type="checkbox" v-model="shareProfilesSelectedRef" :disabled="!canShareProfilesRef || busyRef">
          <span>Share suggested profiles with the group</span>
        </label>
      </div>
    </section>
    <div id="actions">
      <button type="button" class="btn btn-primary" :disabled="busyRef" @click="createGroup">
        <Spinner v-if="busyRef"></Spinner>
        {{ createGroupText }}
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.family-group-create-page {
  max-width: 600px;  
}

h1 {
  margin: 2rem 0 0.5rem;
}

.instruction {
  margin-bottom: 1.5rem;
  //font-size: 0.875rem;
  color: #888;
}

section {
  margin-bottom: 2rem;
}

h4 {
  margin: 0.1rem 0;
  font-weight: 400; // normal
  font-size: 1rem;

  & + p {
    margin: 0;
    font-size: 0.875rem;
    color: #aaa;
  }
}

ul.research-group-list, 
ul.family-group-list,
ul.scope-family-list {
  margin-top: 0.5rem;
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  li {
    display: grid;
    column-gap: 0.5rem;
    grid-template-columns: auto 1fr;
  }
}

ul.scope-family-list {
  li {
    img {
      opacity: 40%;
    }

    &.no-scope {
      input[type="radio"] {
        margin-right: 0.5rem;
      }
    }
  }
}

.share-profiles-option {
  margin-top: 1rem;
  margin-left: 1rem;

  input[type="checkbox"] {
    margin-right: 0.5rem;

    &:disabled + span {
      color: #aaa;
    }
  }
}

#actions {
  margin-top: 2.5rem;
  margin-bottom: 2rem;

  button {
    min-width: 5rem;
  }
}
</style>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { usePageTitle } from '@/util/AppUtil'
import { useWorkspaceStore } from '@/rd/WorkspaceStore'
import { useViewPersonStore } from '@/rd/ViewPersonStore'
import { ViewFamily } from '@/rd/ResearchDataModel'
import { useViewFamilyStore } from '@/rd/ViewFamilyStore'
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { useDataGroupMemberStore } from '@/gp/DataGroupMemberStore'
import { DataGroup, DataGroupMember, DataGroupType, FamilyGroup, PrincipalType } from '@/gp/GroupAdminModel'
import { useFamilyGroupStore } from '@/gp/FamilyGroupStore'
import { useFamilyGroupCreator } from '@/manage/familygroups/FamilyGroupCreator'
import { getPotentialMembersAsync, shareOwnProfilesAsync } from '@/manage/familygroups/FamilyGroupMemberManager'
import { TokenManager } from '@/auth/TokenManager'
import { LoadMode } from '@/util/AsyncData'
import LoadingIndicator from '@/util/LoadingIndicator.vue'
import ResearchGroupItem from '@/manage/researchgroups/ResearchGroupItem.vue'
import CompactItem from '@/util/CompactItem.vue'
import Spinner from '@/util/Spinner.vue'
import { isDefined } from '@/util/TypeScriptUtil'

const props = defineProps({
  defaultShareMode: Boolean,
  workspaceId: String
})

const dataGroupStore = useDataGroupStore()
const dataGroupMemberStore = useDataGroupMemberStore()
const workspaceStore = useWorkspaceStore()
const familyGroupStore = useFamilyGroupStore()
const viewPersonStore = useViewPersonStore()
const viewFamilyStore = useViewFamilyStore()
const { createFamilyGroupAsync } = useFamilyGroupCreator()
const router = useRouter()

const title = "Share my tree"
usePageTitle(title)

interface ScopeFamily {
  id: string
  viewFamily: ViewFamily
  name: string
  relationship: string
}

const relationshipText = new Map<string, string>([
  ['parents', 'My parents'],
  ['grandparents', 'My grandparents'],
  ['spouse', 'Myself and spouse']
])

const loadingRef = ref(false)
const busyRef = ref(false)
const ownedResearchGroupsRef = ref<DataGroup[]>([])
const selectedResearchGroupIdsRef = ref<string[]>([])
const scopeFamiliesRef = ref<ScopeFamily[]>([])
const scopeFamilyIdRef = ref<string>()
const shareProfilesRef = ref(false)

const shareTreesTextRef = computed(() => ownedResearchGroupsRef.value.length == 1
  ? 'Share this tree with the group:'
  : 'Share these trees with the group:')
const canShareProfilesRef = computed(() => !!scopeFamilyIdRef.value)
const shareProfilesSelectedRef = computed({
  get: () => canShareProfilesRef.value && shareProfilesRef.value,
  set: (value: boolean) => shareProfilesRef.value = value
})
const createGroupText = computed(() => busyRef.value ? 'Creating...' : 'Create a group')

onMounted(async () => {
  loadingRef.value = true
  busyRef.value = false
  ownedResearchGroupsRef.value = []
  selectedResearchGroupIdsRef.value = []
  scopeFamiliesRef.value = []
  scopeFamilyIdRef.value = undefined
  shareProfilesRef.value = false

  const allDataGroupsKeyList = await dataGroupStore.getAsyncKeyListForVisible(LoadMode.EnsureLoaded).whenLoadCompleted

  const allResearchGroups = dataGroupStore.getLoadedGroupList(allDataGroupsKeyList?.keys ?? [])
    .filter(dg => dg.groupType == DataGroupType.Research)

  ownedResearchGroupsRef.value = allResearchGroups.filter(rg => rg.ownerId == TokenManager.userId)
  if (ownedResearchGroupsRef.value.length == 1) {
    selectedResearchGroupIdsRef.value = [ownedResearchGroupsRef.value[0].id!]
  }

  const selfProfileGroup = await dataGroupStore.getAsyncGroup(TokenManager.userProfileId, LoadMode.EnsureLoaded)?.whenLoadCompleted
  const selfPerson = await viewPersonStore.getAsyncPerson(selfProfileGroup?.startItemId, LoadMode.EnsureLoaded)?.whenLoadCompleted
  console.log(`selfPerson: ${selfProfileGroup?.startItemId} ${selfPerson?.displayName}`)

  if (selfPerson?.id) {
    const getAncestors = viewPersonStore.ensureAncestorsLoadedAsync(selfPerson?.id, 2)
    const getSpouses = viewPersonStore.ensureDescendantsLoadedAsync(selfPerson?.id, 1) // don't need children
    await Promise.all([getAncestors, getSpouses])

    const parentFams = viewPersonStore.getLoadedParentFamilies(selfPerson?.id).parentFamilies
    scopeFamiliesRef.value.push(...parentFams.map(vf => getScopeFamily(vf, 'parents')))
    for (const fam of parentFams) {
      if (fam.fatherId) {
        const faParentFams = viewPersonStore.getLoadedParentFamilies(fam.fatherId).parentFamilies
        scopeFamiliesRef.value.push(...faParentFams.map(vf => getScopeFamily(vf, 'grandparents')))
      }
      if (fam.motherId) {
        const moParentFams = viewPersonStore.getLoadedParentFamilies(fam.motherId).parentFamilies
        scopeFamiliesRef.value.push(...moParentFams.map(vf => getScopeFamily(vf, 'grandparents')))
      }
    }
    const spouseFams = viewFamilyStore.getLoadedSpouseFamilies(selfPerson?.id).spouseFamilies
    scopeFamiliesRef.value.push(...spouseFams.map(vf => getScopeFamily(vf, 'spouse')))

    scopeFamilyIdRef.value = scopeFamiliesRef.value.at(0)?.id
  }

  shareProfilesRef.value = !!scopeFamilyIdRef.value

  loadingRef.value = false
})

function getScopeFamily(vf: ViewFamily, rel: string) {
  const fa = viewPersonStore.getAsyncPerson(vf.fatherId)?.data
  const mo = viewPersonStore.getAsyncPerson(vf.motherId)?.data
  return {
    id: vf.id!,
    viewFamily: vf,
    name: ViewFamily.getDisplayName(fa, mo),
    relationship: rel
  }
}

function isSelected(dataGroupId: string): boolean {
  return selectedResearchGroupIdsRef.value.includes(dataGroupId)
}

function toggleSelection(dataGroupId: string) {
  if (isSelected(dataGroupId)) {
    selectedResearchGroupIdsRef.value = selectedResearchGroupIdsRef.value.filter(id => id != dataGroupId)
  }
  else {
    selectedResearchGroupIdsRef.value.push(dataGroupId)
  }
}

async function createGroup() {
  busyRef.value = true

  const scopeFamily = scopeFamiliesRef.value.find(f => f.id == scopeFamilyIdRef.value)

  const newGroup = new FamilyGroup()
  newGroup.name = scopeFamily?.name ?? "New Group"
  newGroup.scopeFamilyId = scopeFamily?.id

  const newGroupId = await familyGroupStore.addAsync(newGroup)
  const familyGroup = familyGroupStore.getAsyncGroup(newGroupId)!.data! // should be loaded

  for (const rg of ownedResearchGroupsRef.value) {
    if (selectedResearchGroupIdsRef.value.includes(rg.id!)) {
      const member = new DataGroupMember()
      member.dataGroupId = rg.id
      member.principalType = PrincipalType.FamilyGroup
      member.principalId = newGroupId

      await dataGroupMemberStore.addAsync(rg.id!, member)
    } 
  }

  const potentialMembers = await getPotentialMembersAsync(familyGroup)
  await shareOwnProfilesAsync(familyGroup.id!, potentialMembers)

  busyRef.value = false
  router.push(familyGroup.pageUrl)
}
</script>
