<template>
  <div class="modal" ref="modalRef" data-bs-backdrop="static" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <form ref="formRef" class="modal-content" novalidate>
        <div class="modal-header">
          <h3 class="modal-title">
            Invite someone else
          </h3>
          <button type="button" class="btn-close" v-bind="{ disabled: busyRef }" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <ProfileCreatorSelector
            ref="creatorSelectorRef"
            v-model:use-existing="useExistingRef"
            v-model:existing-person-id="existingProfilePersonIdRef"
            :disabled="busyRef"
            :validate-existing="validateInviteExisting">
          </ProfileCreatorSelector>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary" :disabled="!canInviteRef" @click.prevent.stop="invite">
            {{ inviteTextRef }}
          </button>
          <button type="button" class="btn btn-outline-primary" :disabled="busyRef" data-bs-dismiss="modal">
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-body {
  padding: 1rem 2.5rem 1rem 1.5rem;
}

.modal-footer {
  button {
    min-width: 90px;
  }
}
</style>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useDataGroupMemberStore } from '@/gp/DataGroupMemberStore'
import { DataGroupMember, GroupMemberRole, PrincipalType } from '@/gp/GroupAdminModel'
import { PotentialMember } from './FamilyGroupMemberManager'
import ProfileCreatorSelector from '@/manage/profiles/ProfileCreatorSelector.vue'
import { Modal } from 'bootstrap'
import { CompositeId } from '@/rd/CompositeId'

const props = defineProps<{
  familyGroupId: string
  potentialMembers: PotentialMember[]
}>()

const emit = defineEmits([
  'invite'
])

const dataGroupMemberStore = useDataGroupMemberStore()

const useExistingRef = ref(true)
const existingProfilePersonIdRef = ref<string>()

const canInviteRef = computed(() => creatorSelectorRef.value?.isValid ?? false)
const inviteTextRef = computed(() =>  useExistingRef.value ? 'Invite' : 'Create')

const busyRef = ref(false)
const modalRef = ref<InstanceType<typeof HTMLElement>>()
const creatorSelectorRef = ref<InstanceType<typeof ProfileCreatorSelector>>()

function validateInviteExisting(personId: string) {
  const potentialMember = props.potentialMembers.find(pm => pm.viewPerson?.id == personId)
  if (potentialMember?.member) {
    return 'This person is already a member of the group.'
  }
  if (potentialMember?.lastUserInvite) {
    return 'This person has already been invited.'
  }
}

async function invite() {
  const modal = Modal.getOrCreateInstance(modalRef.value!)

  if (useExistingRef.value) {
    emit('invite', existingProfilePersonIdRef.value)

    // NOTE: don't pre-emptively share the existing person's profile with the group if it isn't already. The profile will be 
    // shared when/if they accept the invitation. If the person sending the invitation isn't the owner of the existing person's 
    // profile (e.g. if the existing person is already a user), the current user can't share the profile without the owner's 
    // consent anyway.
  }
  else {
    busyRef.value = true

    const newProfilePersonId = await creatorSelectorRef.value!.getOrCreateProfileAsync()
    await creatorSelectorRef.value!.getOrCreateRelationshipAsync(newProfilePersonId)

    // NOTE: immediately share the new profile with the group, since the current user is the owner and the clear intent
    // is to invite the person to join the group.
    const newProfileGroupId = CompositeId.getGroupId(newProfilePersonId)!
    await shareNewProfileAsync(newProfileGroupId)

    busyRef.value = false
    emit('invite', newProfilePersonId)
  }
}

async function shareNewProfileAsync(profileGroupId: string) {
  const dm = {
    dataGroupId: profileGroupId,
    principalType: PrincipalType.FamilyGroup,
    principalId: props.familyGroupId,
    role: GroupMemberRole.Viewer,
  } as DataGroupMember
  await dataGroupMemberStore.addAsync(profileGroupId, dm)
}

function reset() {
  creatorSelectorRef.value?.reset()
  busyRef.value = false
}

onMounted(() => {
  modalRef.value?.addEventListener('show.bs.modal', reset)
})

onUnmounted(() => {
  modalRef.value?.removeEventListener('show.bs.modal', reset)
})
</script>