<template>
  <div v-if="assertion" class="assertion">
    <div class="assertion-fields">
      <div class="assertion-date">{{ assertion.date.toString(HistoricalDateFormat.ShortDisplay) }}</div>
      <div class="assertion-age">{{ ageText }}</div>
      <div class="assertion-type-icon" :class="typeIconClassRef"><img :src="assertionTypeIcon"/></div>
      <div class="assertion-type">
        <button type="button" class="btn btn-link btn-inline link-dark" @click="emit('click', props.assertionId)">
          {{ assertion.assertionType }}
        </button>
      </div>
      <div class="assertion-details">
          <div v-if="assertion.relatedItemId">Relation {{ assertion.relatedItemId }}</div>
          <div v-if="assertion.value">{{ assertionValue }}</div>
          <div v-if="assertion.placeId" class="assertion-place">
            <div v-if="place?.countryCode" class="flag fi" :class="flagClass"></div>
            <div class="place-name">{{ place?.shortName ?? place?.name }}</div>
          </div>
          <div v-if="assertion.siteId" class="assertion-site">{{ site?.description }}</div>
      </div>
    </div>
    <div v-if="!props.noAttrib" class="attribution">
      <small>in a {{ profileTextRef }}<UserLink :user-id="ownerRef?.id" dimmed></UserLink></small>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.assertion {
  font-size: 0.75em;

  @media (min-width: 768px) {
    font-size: 0.875rem;  
  }
}

.assertion-fields {
  display: grid;
  grid-template-columns: 70px 4fr 19fr 50fr;
  column-gap: 0.5rem;

  @media (min-width: 768px) {
    grid-template-columns: 18fr 5fr 4fr 19fr 50fr;
  }
}

.assertion-date {
  grid-column: 1;
  text-align: end;
  font-size: 0.75rem;
}

.assertion-age {
  display: none;  

  @media (min-width: 768px) {
    grid-column: 2;
    display: block;
    text-align: center;
    color: #aaa;
  }
}

.assertion-type-icon {
  grid-column: 2;

  @media (min-width: 768px) {
    grid-column: 3;
  }

  img {
    width: 20px;
  }

  &.Name {
    filter: brightness(0) saturate(100%) invert(36%) sepia(72%) saturate(1034%) hue-rotate(194deg) brightness(87%) contrast(77%);
  }

  &.Birth {
    filter: brightness(0) saturate(100%) invert(87%) sepia(39%) saturate(676%) hue-rotate(356deg) brightness(100%) contrast(101%);
    //filter: brightness(0) saturate(100%) invert(76%) sepia(52%) saturate(739%) hue-rotate(350deg) brightness(106%) contrast(99%);
  }

  &.Marriage {
    filter: brightness(0) saturate(100%) invert(75%) sepia(63%) saturate(877%) hue-rotate(5deg) brightness(95%) contrast(112%);    
  }

  &.Death {
    filter: brightness(0) saturate(100%) invert(78%) sepia(49%) saturate(3624%) hue-rotate(358deg) brightness(94%) contrast(106%);
  }

  &.Burial {
    filter: brightness(0) saturate(100%) invert(95%) sepia(4%) saturate(1078%) hue-rotate(193deg) brightness(80%) contrast(100%);
  }
}

.assertion-type {
  grid-column: 3;

  @media (min-width: 768px) {
    grid-column: 4;
  }

  button {
    font-size: inherit;
    line-height: inherit;
    vertical-align: inherit;
  }

  svg {
    margin-left: 0.5rem;
    width: 0.8rem;
    color: #888;
  }
}

.assertion-details {
  grid-column: 4;

  @media (min-width: 768px) {
    grid-column: 5;
  }
}

.assertion-place {
  display: grid;
  align-items: start;
  grid-template-columns: 1.5rem auto;
  font-size: 0.75rem;

  .flag {
    grid-column: 1;
    margin-top: 0.25rem;
    outline: 1px solid #eee;
  }

  .place-name {
    grid-column: 1 / 3;
  }

  .flag ~ .place-name {
    grid-column: 2;
  }
}

.assertion-site {
  font-size: 0.75rem;
}

.assertion-actions {
  width: 1rem;
  padding-left: 0.2rem;

  svg {
    width: 0.8rem;
    color: #888;
  }
}

.attribution {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
}
</style>

<script lang="ts" setup>
import { computed } from 'vue'
import { ItemType, PersonName } from '@/rd/ResearchDataModel'
import { useAssertionStore } from '@/rd/AssertionStore'
import { usePersonStore } from '@/rd/PersonStore'
import { usePlaceStore } from '@/rd/PlaceStore'
import { useSiteStore } from '@/rd/SiteStore'
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { useUserStore } from '@/gp/UserStore'
import { FuzzyDate } from '@/rd/FuzzyDate'
import { HistoricalDateFormat } from '@/rd/HistoricalDateEnums'
import { DurationUtil } from '@/util/LuxonUtil'
import { AssertionTypeIcons } from './AssertionTypeIcons'
import { Edit2Icon } from '@zhuowenli/vue-feather-icons'
import { ProfileUtils } from '@/manage/profiles/ProfileUtils'
import { ItemPermissions } from '@/gp/GroupAdminModel'
import UserLink from '@/manage/users/UserLink.vue'

const props = defineProps({
  assertionId: String,
  baseDate: FuzzyDate,
  noAttrib: Boolean,
  enableEdit: Boolean,
})
const emit = defineEmits([
  "click",
])

const assertionStore = useAssertionStore()
const placeStore = usePlaceStore()
const siteStore = useSiteStore()
const dataGroupStore = useDataGroupStore()
const userStore = useUserStore()
const personStore = usePersonStore()

const assertion = computed(() => assertionStore.getAsyncAssertion(props.assertionId)?.data)
const age = computed(() => props.baseDate ? assertion.value?.date.date1?.diff(props.baseDate) : undefined)
const ageText = computed(() => age.value ? DurationUtil.toPersonAge(age.value) : '')
const assertionTypeIcon = computed(() => AssertionTypeIcons.getIcon(assertion.value?.assertionType))
const typeIconClassRef = computed(() => assertionTypeIcon.value ? assertion.value!.assertionType : undefined )
const assertionValue = computed(() => 
  assertion.value?.assertionType == "Name" ? PersonName.getDisplayName(assertion.value?.value) : assertion.value?.value)
const place = computed(() => placeStore.trackAsyncPlace(assertion.value?.placeId)?.data)
const flagClass = computed(() => place.value?.countryCode ? "fi-" + place.value.countryCode.toLowerCase() : undefined)
const site = computed(() => siteStore.trackAsyncSite(assertion.value?.siteId)?.data)

const dataGroupRef = computed(() => dataGroupStore.getAsyncGroup(assertion.value?.groupId)?.data) // should be pre-loaded
const ownerRef = computed(() => userStore.getAsyncUser(dataGroupRef.value?.ownerId)?.data) // should be pre-loaded
const personRef = computed(() => assertion.value?.subjectType == ItemType.Person ? personStore.getAsyncPerson(assertion.value?.subjectId)?.data : undefined) // should be pre-loaded
const profileTypeRef = computed(() => ProfileUtils.getProfileType(personRef.value, dataGroupRef.value, ownerRef.value))
const profileTextRef = computed(() => ProfileUtils.getProfileSubtitle(profileTypeRef.value)?.toLowerCase())

const permissionsRef = computed(() =>  dataGroupStore.getAsyncPermissions(assertion.value?.groupId)?.data ?? ItemPermissions.None) // loaded by parent

</script>
