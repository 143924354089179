<template>
  <div class="person-creator-selector">
    <div class="use-existing">
      <div class="form-check">
        <label class="form-check-label">
          <input type="radio" class="form-check-input" :value="true" v-model="useExistingRef" :disabled="props.disabled" />
          Select an existing person or user:
        </label>
      </div>
      <PersonSelector ref="existingSelectorRef"
        v-model="selectedPersonIdRef" 
        :disabled="!canSelectExistingRef"
        :post-filter="getSelectableExistingPersonsAsync"
        @focus="useExistingRef = true">
      </PersonSelector>
      <div v-if="existingPersonErrorRef" class="invalid-feedback">{{ existingPersonErrorRef }}</div>
    </div>
    <div class="create-new">
      <div class="create-new-option form-check">
        <label class="form-check-label">
          <input type="radio" class="form-check-input" 
          :value="false" 
          v-bind="{ disabled: props.disabled }"
          v-model="useExistingRef" />
          <template v-if="showChooseRelationshipRef">
            Create a new profile as a
            <ProfileRelationshipSelector 
              v-model="newRelationshipRef" 
              :disabled="props.disabled" 
              @focus="useExistingRef = false">
            </ProfileRelationshipSelector>
            of:
          </template>
          <template v-else>
            Create a new profile
          </template>
        </label>
      </div>
      <template v-if="showChooseRelatedRef">
        <PersonSelector
          v-if="relatedItemTypeRef == ItemType.Person"
          v-model="relatedItemIdRef"
          :placeholder="relatedItemSearchPlaceholderRef"
          :disabled="!canEditNewRef"
          :post-filter="getSelectableRelatedPersonsAsync"
          @focus="useExistingRef = false">
        </PersonSelector>
        <FamilySelector
          v-else
          v-model="relatedItemIdRef"
          :placeholder="relatedItemSearchPlaceholderRef"
          :disabled="!canEditNewRef"
          :post-filter="getSelectableRelatedPersonsAsync"
          @focus="useExistingRef = false">
        </FamilySelector>
      </template>
      <div class="gender" v-if="props.chooseGender">
        <label class="form-label">
          <div>Gender</div>
          <select class="form-select" v-model="genderRef" :disabled="!canEditNewRef">
            <option :value="undefined"></option>
            <option v-for="v in genderValues" :value="v.value" :key="v.value">{{ v.name }}</option>
          </select> 
        </label>
      </div>
      <div v-if="relatedItemErrorRef" class="invalid-feedback">{{ relatedItemErrorRef }}</div>
      <div class="given">
        <label class="form-label">
          <div>Given name</div>
          <input type="text" id="givenInput" class="form-control"
          v-model="givenRef"
          :disabled="useExistingRef || !canEditNewRef"/>
        </label>
      </div>
      <div>
        <label class="form-label">
          <div>Surname</div>
          <input type="text" id="surnameInput" class="form-control"
            v-model="surnameRef"
            :disabled="useExistingRef || !canEditNewRef"/>
        </label>
      </div>
      <div class="online-only">
        <small>The new profile will only exist online.</small>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.person-creator-selector {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.invalid-feedback {
  display: block;
}

.use-existing {
  margin-bottom: 1rem;
  padding-left: 1.5rem;

  .form-check {
    margin-left: -1.5rem;
    margin-bottom: 0.5rem;
  }
}

.create-new {
  .form-check {
    margin-bottom: 0.5rem;
  }  

  .form-check-input {
    margin-top: 9px; // HACK
  }

  &>:not(.form-check) {
    margin-left: 1.5rem;
  }
}

.invalid-feedback {
  display: block;
}

.gender {
  select {
    width: auto;
  }
}

.given {
  margin-top: 0.5rem;
}

.online-only {
  margin-top: 1rem;
  small { color: #888; }
}
</style>

<script lang="ts" setup>
// This component can be used to select or create a profile without any pre-existing relationship context, or
// to select or create a profile for the purpose of creating a specific type of relationship. The combination of 
// these two overlapping but not entirely compatible use cases makes the component a bit convoluted, so it may 
// be worth refactoring into two separate components.
import { computed, nextTick, ref, watch } from 'vue'
import { useViewPersonStore } from '@/rd/ViewPersonStore'
import { FamilyRelationshipRole, Gender, ItemType, ViewPerson } from '@/rd/ResearchDataModel'
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { DataGroupType } from '@/gp/GroupAdminModel'
import { 
  NewProfileRelationship, 
  createProfilePersonAsync, 
  getNewProfileRelationshipGender, 
  getOrCreateChildRelationshipAsync, 
  getOrCreateSpouseRelationshipAsync, 
  getOrCreateProfileFamilyAsync,
  isChildRelationship, 
  isSpouseRelationship, 
  isParentRelationship,
  getNewProfileSearchRelatedPlaceholder
} from '@/manage/profiles/ProfileManager'
import { isDefined } from '@/util/TypeScriptUtil'
import { TokenManager } from '@/auth/TokenManager'
import { CompositeId } from '@/rd/CompositeId'
import { LoadMode } from '@/util/AsyncData'
import PersonSelector from '@/manage/PersonSelector.vue'
import FamilySelector from '@/manage/FamilySelector.vue'
import ProfileRelationshipSelector from './ProfileRelationshipSelector.vue'

const props = defineProps<{
  relationship?: NewProfileRelationship,
  chooseGender?: boolean,
  relatedItemId?: string,
  useExisting?: boolean,
  existingPersonId?: string,
  disabled?: boolean,
  validateExisting?: (personId: string) => string | undefined
}>()

const emit = defineEmits([
  'update:useExisting',
  'update:existingPersonId',
])

const viewPersonStore = useViewPersonStore()
const dataGroupStore = useDataGroupStore()

const useExistingRef = ref(true)
const selectedPersonIdRef = ref<string>()
const existingSelectorRef = ref<InstanceType<typeof PersonSelector>>()
const existingPersonErrorRef = ref<string>()
const existingPersonIdRef = ref<string>()
const newRelationshipRef = ref(NewProfileRelationship.Son)
const relatedItemIdRef = ref<string>()
const relatedItemErrorRef = ref<string>()
const genderRef = ref<Gender>()
const givenRef = ref("")
const surnameRef = ref("")

const canSelectExistingRef = computed(() => !props.disabled)
const canEditNewRef = computed(() => !props.disabled)

const relatedItemTypeRef = computed(() => isChildRelationship(newRelationshipRef.value) ? ItemType.Family : ItemType.Person)
const relatedItemSearchPlaceholderRef = computed(() => getNewProfileSearchRelatedPlaceholder(newRelationshipRef.value))
const showChooseRelationshipRef = computed(() => !props.relationship)
const showChooseRelatedRef = computed(() => !props.relatedItemId)
const genderValues = [
  { value: Gender.Male, name: Gender.Male },
  { value: Gender.Female, name: Gender.Female },
]
const hasNameRef = computed(() => !!givenRef.value.trim() || !!surnameRef.value.trim())
const isValidRef = computed(() => !!(useExistingRef.value 
  ? (existingPersonIdRef.value && !existingPersonErrorRef.value)
  : (relatedItemIdRef.value && !relatedItemErrorRef.value && hasNameRef.value)))

defineExpose({
  isValid: isValidRef,
  reset,
  getOrCreateProfileAsync,
  getOrCreateRelationshipAsync,
})

watch(props, () =>{
  if (props.relationship) {
    // this should be set if the relationship is pre-defined, and the user should not be able to change it
    newRelationshipRef.value = props.relationship
    genderRef.value = getNewProfileRelationshipGender(props.relationship)
  }

  if (props.relatedItemId) {
    // this should be set if the related person or family is pre-defined, and the user does not need to see or change it
    if (!props.relationship)
      throw 'Cannot set related item without a pre-defined relationship'

    relatedItemIdRef.value = props.relatedItemId
  }
}, { immediate: true })

watch(() => props.useExisting, () => { if (props.useExisting) useExistingRef.value = props.useExisting })
watch(() => props.existingPersonId, () => { if (props.existingPersonId) existingPersonIdRef.value = props.existingPersonId })
watch(useExistingRef, () => emit('update:useExisting', useExistingRef.value))
watch(existingPersonIdRef, () => emit('update:existingPersonId', existingPersonIdRef.value))

async function getSelectableExistingPersonsAsync(vps: ViewPerson[]) {
  // only include managed profiles that are not assumed deceased
  const groupIds = new Set<string>()
  vps.forEach(vp => vp.groupIds.forEach(gid => groupIds.add(gid)))

  const personGroups = await dataGroupStore.getGroupListAsync([...groupIds])
  const profilePersonIds = new Set<string>(personGroups
    .filter(g => g && g.groupType == DataGroupType.Profile)
    .map(g => g.startItemId!))

    return vps.filter(vp => !vp.assumeDeceased && vp.matchIds.some(id => profilePersonIds.has(id)))
}

watch(selectedPersonIdRef, async () => {
  existingPersonIdRef.value = ''
  existingPersonErrorRef.value = ''
  
  if (!useExistingRef.value || !selectedPersonIdRef.value)
    return // irrelevant or obviously invalid, so no error message

  const existingPerson = await viewPersonStore.getAsyncPerson(selectedPersonIdRef.value)?.whenLoadCompleted
  if (!existingPerson) {
    existingPersonErrorRef.value = 'Person not found' // should never happen
    return
  }
  
  const personGroups = await dataGroupStore.getGroupListAsync(existingPerson.groupIds)
  if (!personGroups.every(isDefined)) {
    existingPersonErrorRef.value = 'Profile not found' // should never happen
    return
  }

  const profilePersonGroups = personGroups
    .filter(g => g.groupType == DataGroupType.Profile && existingPerson.matchIds.includes(g.startItemId!))
  if (profilePersonGroups.length == 0) {
    existingPersonErrorRef.value = 'This person does not have a managed profile.' // should never happen
    return
  }

  const otherError = props.validateExisting?.(selectedPersonIdRef.value)
  if (otherError) {
    existingPersonErrorRef.value = otherError
    return
  }

  // TODO: Allow recruiter to invite people whose profiles are owned by FG owner

  const ownedProfilePersonGroups = profilePersonGroups.filter(g => g.ownerId == TokenManager.userId)
  if (ownedProfilePersonGroups.length == 0) {
    existingPersonErrorRef.value = 'Select someone with a managed profile that you own.'
    return
  }
  if (ownedProfilePersonGroups.length > 1) {
    existingPersonErrorRef.value = `This person has more than one managed profile.`
    return
  }

  existingPersonIdRef.value = selectedPersonIdRef.value
})

watch(newRelationshipRef, () => relatedItemIdRef.value = undefined)

watch([newRelationshipRef, relatedItemIdRef], async () => {
  relatedItemErrorRef.value = ''

  if (!relatedItemIdRef.value)
    return // obviously invalid

  if (isParentRelationship(newRelationshipRef.value)) {
    const relatedGroupId = CompositeId.getGroupId(relatedItemIdRef.value)
    const relatedGroup = await dataGroupStore.getAsyncGroup(relatedGroupId, LoadMode.EnsureLoaded)?.whenLoadCompleted
    if (relatedGroup?.ownerId != TokenManager.userId) {
      relatedItemErrorRef.value = "You don't have permission to create a new parent for this profile."
      return
    }
  }
})

async function getSelectableRelatedPersonsAsync(vps: ViewPerson[]) {
  return vps.filter(vp => !vp.assumeDeceased)
}

async function getOrCreateProfileAsync() {
  if (useExistingRef.value) {
    if (!existingPersonIdRef.value)
      throw 'No existing profile person selected'

    return existingPersonIdRef.value
  }
  else {
    const np = {
      gender: props.chooseGender ? genderRef.value : getNewProfileRelationshipGender(newRelationshipRef.value),
      givenName: givenRef.value.trim(),
      surname: surnameRef.value.trim(),
    }
    return await createProfilePersonAsync(np)
  }
}

async function getOrCreateRelationshipAsync(profilePersonId: string) {
  if (!relatedItemIdRef.value)
    throw 'Cannot create profile because no related person or family selected'

  if (isChildRelationship(newRelationshipRef.value)) {
    if (relatedItemTypeRef.value != ItemType.Family)
      throw 'Related item when creating a child profile must be a family'

    await getOrCreateChildRelationshipAsync(profilePersonId, relatedItemIdRef.value)
  }
  else {
    if (relatedItemTypeRef.value != ItemType.Person)
      throw 'Related item when creating a spouse or parent profile must be a person'

    if (isSpouseRelationship(newRelationshipRef.value)) {
      const spouseRole = newRelationshipRef.value == NewProfileRelationship.Husband 
        ? FamilyRelationshipRole.Father
        : FamilyRelationshipRole.Mother
      await getOrCreateSpouseRelationshipAsync(relatedItemIdRef.value, profilePersonId, spouseRole)
    }
    else {
      // create family in (new) parent profile group
      const profileGroupId = CompositeId.getGroupId(profilePersonId)!
      const fatherId = newRelationshipRef.value == NewProfileRelationship.Father ? profilePersonId : undefined
      const motherId = newRelationshipRef.value == NewProfileRelationship.Mother ? profilePersonId : undefined
      const profileFamily = await getOrCreateProfileFamilyAsync(profileGroupId, fatherId, motherId)

      // create child relationship in child profile group
      await getOrCreateChildRelationshipAsync(relatedItemIdRef.value, profileFamily.id!)
    }
  }

  return profilePersonId
}

async function reset() {
  useExistingRef.value = true
  selectedPersonIdRef.value = undefined
  existingSelectorRef.value?.reset()
  existingPersonErrorRef.value = ''
  newRelationshipRef.value = NewProfileRelationship.Son
  relatedItemIdRef.value = undefined
  relatedItemErrorRef.value = ''
  genderRef.value = undefined
  givenRef.value = ''
  surnameRef.value = ''

  await nextTick()
  existingSelectorRef.value?.focus()
}
</script>