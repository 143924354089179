<template>
  <div class="content-editor">
    <div v-for="t of tagsRef" :key="t.id">
      <ContentItem :content-id="t.contentId" :content-tag-id="t.id" />
    </div>
    <!-- <DragDrop :uppy="uppy" /> -->
  </div>
</template>

<style lang="scss" scoped>
// @import '@uppy/core/dist/style.css';
// @import '@uppy/drag-drop/dist/style.css';
.content-editor {
  display: flex;
  gap: 2rem;
}

</style>

<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { ItemType } from '@/rd/ResearchDataModel'
import { useContentStore } from '@/rd/ContentStore'
import { useContentTagStore } from '@/rd/ContentTagStore'
import { LoadMode } from '@/util/AsyncData'
//import { DragDrop, StatusBar } from '@uppy/vue'
//import XHRUpload from '@uppy/xhr-upload'
// import { DragDropOptions } from '@uppy/drag-drop'
import Uppy from '@uppy/core'
import ContentItem from './ContentItem.vue'

const props = defineProps<{
  itemType: ItemType
  itemId?: string
}>()

// const uppy = new Uppy()
//   .use(XHRUpload, {
//     onBeforeRequest: onBeforeUpload,
//     endpoint: 'http://rd.treexg.com:8084/contentupload',
//   })

const contentStore = useContentStore()
const contentTagStore = useContentTagStore()

const tagsRef = computed(() => contentTagStore.getTagsForItems(props.itemType, [props.itemId], LoadMode.EnsureLoaded))


onMounted(() => {
  // init uppy?
})

onBeforeUnmount(() => {
  // cleanup uppy?
})

function onBeforeUpload(xhr: XMLHttpRequest) {
  console.log("ContentEditor: ", xhr)
}

async function addContent() {
  alert("add content!")
}

</script>
