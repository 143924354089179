import { reactive } from 'vue'
import { Content, ContentDataSignedUrl, ContentDataStatus } from "./ResearchDataModel"
import { rd } from './ResearchDataApi'
import { AsyncData, LoadMode } from "@webapp/util/AsyncData"
import { AsyncDataStore } from "@webapp/util/AsyncDataStore"
import { defineStore } from "pinia"
import { DateTime } from "luxon"
import { DateTimeUtil } from "@webapp/util/LuxonUtil"
import { assignExisting, isDefined } from "@webapp/util/TypeScriptUtil"
import { HistoricalDate } from './HistoricalDate'


export const useContentStore = defineStore("contentStore", () => {
    const asyncContents = reactive(new Map<string, AsyncData<Content>>())

    function getLoadedContents(ids: (string | undefined)[]) {
        return ids.filter(isDefined).map(id => asyncContents.get(id)?.data).filter(isDefined)
    }

    function trackAsyncContents(ids: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        const loadAsync = async (ids: string[], requestTime: DateTime) => {
            const plainItems = await rd.getPlainByIdsAsync("content", ids)
            console.log(`Loaded ${plainItems.length} data groups by id: ${plainItems.map(p => p.id).join(", ")}`)
            return processExpandedContents(plainItems, requestTime)
        }
        return AsyncDataStore.getAsyncItemsByIds(asyncContents, ids, "Content", loadMode, loadAsync)
    }

    function getContents(ids: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        const asyncItems = trackAsyncContents(ids, loadMode)
        return asyncItems.map(a => a.data).filter(isDefined)
    }

    async function getContentsAsync(ids: (string | undefined)[]) {
        return (await Promise.all(trackAsyncContents(ids, LoadMode.EnsureLoaded).map(a => a.whenLoadCompleted))).filter(isDefined)
    }

    function addContentsToStore(plainContents: any[], requestTime: DateTime) {
        const contentMap = processExpandedContents(plainContents, requestTime)
        AsyncDataStore.addItemMapToStore(asyncContents, "Content", contentMap, requestTime)
    }

    function processExpandedContents(plainItems: any[], requestTime: DateTime) {
        return AsyncDataStore.createItemMap(plainItems, "Content", plainToContent, c => c.id!)
    }

    return {
        asyncContents,

        getLoadedContents,
        trackAsyncContents,
        getContents,
        getContentsAsync,
        addContentsToStore,
    }
})

function plainToContent(p: any) {
    const content = assignExisting(new Content(), p)
    content.date = HistoricalDate.parse(p.date)
    content.dataStatus = p.dataStatus ?? ContentDataStatus.NoData
    content.dataModifiedDate = DateTimeUtil.fromAPI(p.dataModifiedDate)
    content.dataSignedUrls = p.dataSignedUrls?.map(plainToSignedUrl)
    content.profile ??= false
    content.modifiedDate = DateTimeUtil.fromAPI(p.modifiedDate)
    content.createdDate = DateTimeUtil.fromAPI(p.createdDate)
    return content
}

export function plainToSignedUrl(p: any) {
    if (p) {
        p.expirationDate = DateTimeUtil.fromAPI(p.expirationDate)
        return p as ContentDataSignedUrl
    }
}
