<template>
  <CompactItem 
    class="family-group-item" 
    :select-mode="props.selectMode"
    :pageUrl="familyGroupRef?.pageUrl"
    :status-style="statusStyleRef"
    @click="emit('click')">
    <template #icon>
      <img src="@/assets/icons/everyone_500.png"/>
    </template>
    <template #title>
      {{ familyGroupRef?.name }}
    </template>
    <template #statusText>
      {{ statusTextRef }}
    </template>
    <template #subtitle>
      Group managed by {{ ownerRef?.displayName }}
    </template>
  </CompactItem>
</template>

<style lang="scss" scoped>
</style>

<script lang="ts" setup>
import { computed, ref, watchEffect } from 'vue';
import { DataGroupInvite, dataGroupInviteStatusStyle, FamilyGroup, PrincipalType } from '@/gp/GroupAdminModel'
import { useFamilyGroupStore } from '@/gp/FamilyGroupStore'
import { useDataGroupInviteStore } from '@/gp/DataGroupInviteStore'
import { useUserStore } from '@/gp/UserStore'
import { LoadMode } from '@/util/AsyncData'
import CompactItem from '@/util/CompactItem.vue'
import { useFamilyGroupMemberStore } from '@/gp/FamilyGroupMemberStore';
import { useDataGroupMemberStore } from '@/gp/DataGroupMemberStore';

const props = defineProps({
  familyGroupId: String,
  dataGroupInviteId: String,
  userId: String,
  small: Boolean,
  selectMode: Boolean,
})

const emit = defineEmits([
  'click',
])

const familyGroupRef = ref<FamilyGroup>()
const inviteRef = ref<DataGroupInvite>()

const familyGroupStore = useFamilyGroupStore()
const familyGroupMemberStore = useFamilyGroupMemberStore()
const dataGroupMemberStore = useDataGroupMemberStore()
const dataGroupInviteStore = useDataGroupInviteStore()
const userStore = useUserStore()

const memberStatusText = {
  useronly: 'User only',
  profileonly: 'Profile only',
  owner: 'Owner',
  member: undefined,
}
const memberStatusStyle = {
  useronly: 'info',
  profileonly: 'info',
  owner: 'warning',
  member: undefined,
}

watchEffect(() => {
  if (props.familyGroupId) {
    familyGroupRef.value = familyGroupStore.getAsyncGroup(props.familyGroupId)?.data
    inviteRef.value = undefined
  }
  else if (props.dataGroupInviteId) {
    inviteRef.value = dataGroupInviteStore.getAsyncInvite(props.dataGroupInviteId, LoadMode.EnsureLoaded)?.data
    familyGroupRef.value = inviteRef.value?.principalType == PrincipalType.FamilyGroup
      ? familyGroupStore.getAsyncGroup(inviteRef.value?.principalId)?.data
      : undefined
  }
  else {
    familyGroupRef.value = undefined
    inviteRef.value = undefined
  }
})
const ownerRef = computed(() => userStore.getAsyncUser(familyGroupRef.value?.ownerId)?.data)
const userRef = computed(() => userStore.getUserList([props.userId], LoadMode.EnsureLoaded).at(0))
const userMemberRef = computed(() => familyGroupMemberStore.getMembersForUsers([props.userId], LoadMode.EnsureLoaded)
  .find(fm => fm.familyGroupId == props.familyGroupId))
const profileMemberRef = computed(() => dataGroupMemberStore.getMembersForDataGroups([userRef.value?.profileDataGroupId], LoadMode.EnsureLoaded)
  .find(dm => dm.principalType == PrincipalType.FamilyGroup && dm.principalId == props.familyGroupId))

const memberStatusRef = computed(() => 
  !profileMemberRef.value ? 'useronly' :
  !userMemberRef.value ? 'profileonly' :
  ownerRef.value?.id == props.userId ? 'owner' :
  'member')

const statusTextRef = computed(() => inviteRef.value
  ? inviteRef.value.statusText
  : memberStatusText[memberStatusRef.value])

const statusStyleRef = computed(() => inviteRef.value
  ? dataGroupInviteStatusStyle[inviteRef.value.status]
  : memberStatusStyle[memberStatusRef.value])
</script>
