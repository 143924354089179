<template>
  <div class="supervision-editor">
    <div class="help-text">
      <template v-if="isSelfRef && supervisorsRef.length">
        Parent accounts help parents protect your personal information 
        and decide which features are enabled for you on the site.
      </template>
      <template v-else>
        Parent accounts can manage one or more child accounts 
        to help protect the child’s personal information 
        and decide which features are enabled for them on the site.
      </template>
    </div>
    <template v-if="supervisorsRef.length">
      <h5>
        <template v-if="isSelfRef">
          Parents who manage your account
        </template>
        <template v-else>
          Parents who manage this account
        </template>
      </h5>
      <ul class="list-unstyled user-list">
        <li v-for="sup in supervisorsRef" :key="sup.id">
          <UserItem :user-id="sup.supervisorId"/>
          <div class="item-actions">
            <button type="button" v-if="isUserAdminRef" class="btn btn-inline btn-link" @click="onRemove(sup.id!)">
              Remove
            </button>
          </div>
        </li>
      </ul>
    </template>
    <template v-if="supervisedUsersRef.length">
      <h5>
        Child accounts you manage
      </h5>
      <ul class="list-unstyled user-list">
        <li v-for="sup in supervisedUsersRef" :key="sup.id">
          <UserItem :user-id="sup.forUserId"/>
          <div class="item-actions">
            <button type="button" class="btn btn-inline btn-link" @click="onRemove(sup.id!)">
              Remove
            </button>
          </div>
        </li>
      </ul>
    </template>
    <div class="list-actions">
      <button type="button" v-if="showAddSupervisedRef" class="btn btn-inline btn-link" @click="addSupervisedUser">
        Add a child account
      </button>
      <button type="button" v-if="showAddFirstSupervisorRef" class="btn btn-inline btn-link" @click="addSupervisor">
        Make this a child account
      </button>
      <button type="button" v-if="isSupervisorRef" class="btn btn-inline btn-link" @click="addSupervisor">
        Add another parent account
      </button>
    </div>
    <ProfileAddRelatedModal ref="profileAddRelatedModalRef"/>
    <UserAddModal ref="userAddModalRef"/>
    <UserSupervisorAddModal ref="userSupervisorAddModalRef"/>
    <UserSupervisorRemoveModal ref="userSupervisorRemoveModalRef"/>
  </div>
</template>
<style lang="scss" scoped>
.supervision-editor {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

h5 {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
}

.user-list {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.item-actions {
  margin-left: calc(35px + 0.5rem);
  display: flex;
  gap: 1rem;
  font-size: 0.875rem;
}

.list-actions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 0.875rem;
}
</style>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useUserStore } from '@/gp/UserStore'
import { useUserSupervisorStore } from '@/gp/UserSupervisorStore'
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { ItemPermissions } from '@/gp/GroupAdminModel'
import { LoadMode } from '@/util/AsyncData'
import { TokenManager } from '@/auth/TokenManager'
import { NewProfileRelationship } from '@/manage/profiles/ProfileManager'
import UserItem from './UserItem.vue'
import UserAddModal from './UserAddModal.vue'
import ProfileAddRelatedModal from '@/manage/profiles/ProfileAddRelatedModal.vue'
import UserSupervisorRemoveModal from './UserSupervisorRemoveModal.vue'
import UserSupervisorAddModal from './UserSupervisorAddModal.vue'

const props = defineProps<{
  userId?: string
}>();

const profileAddRelatedModalRef = ref<InstanceType<typeof ProfileAddRelatedModal>>()
const userAddModalRef = ref<InstanceType<typeof UserAddModal>>()
const userSupervisorAddModalRef = ref<InstanceType<typeof UserSupervisorAddModal>>()
const userSupervisorRemoveModalRef = ref<InstanceType<typeof UserSupervisorRemoveModal>>()

const userStore = useUserStore()
const userSupervisorStore = useUserSupervisorStore()
const dataGroupStore = useDataGroupStore()

const userIdRef = computed(() => props.userId ?? TokenManager.userId!)
const isSelfRef = computed(() => userIdRef.value == TokenManager.userId)
const userRef = computed(() => userStore.getAsyncUser(userIdRef.value, LoadMode.EnsureLoaded)?.data)
const userProfileGroupRef = computed(() => dataGroupStore.getAsyncGroup(userRef.value?.profileDataGroupId, LoadMode.EnsureLoaded)?.data)
const permissionsRef = computed(() => userStore.getAsyncPermissions(userIdRef.value, LoadMode.EnsureLoaded)?.data ?? ItemPermissions.None)
const isUserAdminRef = computed(() => (permissionsRef.value & ItemPermissions.Modify) > 0)
const isSupervisorRef = computed(() => !isSelfRef.value && isUserAdminRef.value)
const supervisedUsersRef = computed(() => !isUserAdminRef.value ? [] :
  userSupervisorStore.getForSupervisor(userIdRef.value, LoadMode.EnsureLoaded))
const supervisorsRef = computed(() => !(isUserAdminRef.value || isSelfRef.value) ? [] :
  userSupervisorStore.getForSupervisedUser(userIdRef.value, LoadMode.EnsureLoaded))
const showAddSupervisedRef = computed(() => isSelfRef.value && isUserAdminRef.value)
const showAddFirstSupervisorRef = computed(() => isSelfRef.value && isUserAdminRef.value && supervisedUsersRef.value.length == 0)

function onRemove(userSupervisedId: string) {
  userSupervisorRemoveModalRef.value?.open(userSupervisedId)
}

function addSupervisedUser() {
  if (userProfileGroupRef.value) {
    profileAddRelatedModalRef.value?.open(
      NewProfileRelationship.Son,
      userProfileGroupRef.value.startItemId!)
  }
}

function addSupervisor() {
  userSupervisorAddModalRef.value?.open(userIdRef.value)
}

</script>
