<template>
  <div class="compact-item">
    <component :is="elementTypeRef" :href="pageUrlRef" @click="onSelect" :class="classRef" class="item-button">
      <div class="item-icon">
        <slot name="icon"></slot>
      </div>
      <div class="item-body">
        <div class="item-title-row">
          <div class="item-title no-overflow">
            <slot name="title"></slot>
          </div>
          <div v-if="$slots.statusText" class="item-status" :class="props.statusStyle">
            <slot name="statusText"></slot>
          </div>
        </div>
        <div class="item-subtitle no-overflow">
          <slot name="subtitle"></slot>
        </div>
      </div>
    </component>
  </div>
</template>

<style lang="scss">
.compact-item {
  min-height: 45px;
  padding: 0.25rem 0;

  .item-button {
    display: grid;
    column-gap: 0.5rem;
    grid-template-columns: auto 1fr;
    align-items: center;
  }

  .item-icon {
    padding: 2px;

    img {
      width: 31px;
      height: 31px;
      object-fit: contain;
    }
  }

  .item-body {
    display: flex;
    flex-direction: column;    
    min-width: 0; // allow content to shrink (will trigger ellipsis)
  }

  .item-title-row {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .item-title {
    font-size: 0.875rem;
  }

  .item-status {
    padding: 0 0.25rem 0;
    background-color: #ddd;
    font-size: 0.75rem;
    text-transform: uppercase;
    color: #333;

    &.success {
      background-color: var(--bs-success);
    }
    &.warning {
      background-color: var(--bs-warning);
    }
    &.info {
      background-color: var(--bs-info);
      color: white;
    }
  }

  .item-subtitle {
    font-size: 0.75rem;
    color: #888;
  }

  @media (min-width: 768px) {
    .item-title {
      font-size: 1rem;
    }
  }
}
</style>

<script lang="ts" setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'

const props = defineProps({
  pageUrl: String,
  selectMode: Boolean,
  statusText: String,
  statusStyle: String,
})

const emit = defineEmits([
  'click',
])

const router = useRouter()

const elementTypeRef = computed(() => props.selectMode ? 'button' : 'a')
const pageUrlRef = computed(() => props.selectMode ? undefined : props.pageUrl)
const classRef = computed(() => props.selectMode ? 'btn btn-inline' : 'link-dark')

function onSelect(e: Event) {
  e.preventDefault()
  if (props.selectMode) {
    emit('click')
  }
  else if (props.pageUrl) {
    router.push(props.pageUrl)
  }
}

</script>
