import _ from 'lodash'

export function some<T>(it: Iterable<T>, predicate?: (e: T) => boolean) {
    for (const e of it) {
        if (!predicate || predicate(e))
            return true 
    }
    return false
}

export function every<T>(it: Iterable<T>, predicate: (e: T) => boolean) {
    for (const e of it) {
        if (!predicate(e))
            return false
    }
    return true
}

export function setEqual<T>(a: Iterable<T>, b: Iterable<T>) {
    const aSet = new Set(a)
    const bSet = new Set(b)

    if (aSet.size != bSet.size)
        return false

    for (const e of aSet) {
        if (!bSet.has(e))
            return false
    }
    return true
}   
