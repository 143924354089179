<template>
  <div class="plans-page container">
    <h1>{{ titleRef }}</h1>
    <ul class="row list-unstyled plan-list">
      <li v-for="p in subscriptionPlans" :key="p.id" class="col-sm" :class="getPlanClasses(p.id)">
        <div v-if="canEditRef" class="current-indicator over">
          <span v-if="p.id == currentPlanIdRef">Current</span>
        </div>
        <div class="plan">
          <button type="button" class="overlay" @click="selectedPlanIdRef = p.id"></button>
          <label :class="planType(p.id)">
            <input type="radio" v-if="canEditRef" name="plan" :value="p.id" v-model="selectedPlanIdRef" />
            <span>{{ p.displayName }}</span>
          </label>
          <div v-if="canEditRef" class="current-indicator inner">
            <span v-if="p.id == currentPlanIdRef">(current)</span>
          </div>
          <div class="plan-details">
            <div class="plan-description">
              {{ p.description }}
            </div>
            <SubscriptionPrice :plan="p"></SubscriptionPrice>
          </div>
          <div class="plan-feature-list">
            <ul class="list-unstyled">
              <li v-for="f in getPlanFeatures(p.id)" :key="f.id" class="feature">
                <div class="plan-feature" :class="firstPlanType(f.id)">
                  <!-- <img v-if="hasFeature(p.id, f.id)" src="@/assets/tree-crossing-icon-128.png"/> -->
                  <CheckIcon v-if="hasFeature(p.id, f.id)" class="feature-check"></CheckIcon>
                  <div v-if="f.id == 'UploadTrees'">
                    <template v-if="getPlanLimits(p.id).treeLimit == 1">
                      Upload 1 tree
                    </template>
                    <template v-else-if="getPlanLimits(p.id).treeLimit > 1">
                      Upload up to {{ getPlanLimits(p.id).treeLimit }} trees
                    </template>
                    <template v-else>
                      Upload as many trees as you like
                    </template>
                  </div>
                  <div v-else-if="f.id == 'ShareTrees'">
                    <template v-if="getPlanLimits(p.id).shareLimit == -1">
                      Share with as many people as you like
                    </template>
                    <template v-else>
                      Share with 
                      <template v-if="getPlanLimits(p.id).shareLimit > 1">
                        up to {{ getPlanLimits(p.id).shareLimit }} people
                      </template>
                      <template v-else>
                        1 person
                      </template>
                      <template v-if="getPlanLimits(p.id).treeLimit == -1">per tree</template>
                    </template>
                  </div>
                  <div v-else>{{ f.text }}</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul>
    <div class="action-row">
      <button type="button" v-if="canEditRef" class="btn btn-primary" :disabled="!canChangePlanRef" @click="onChangePlan">
        Change my plan
      </button>
    </div>
    <div class="feature-list">
      <h4>Compare plans</h4>
      <div class="feature-list-header">
        <div v-for="p in subscriptionPlans" :key="p.id" :class="planType(p.id)">
          {{ p.displayName }}
        </div>
      </div>
      <ul class="list-unstyled feature-list">
        <li v-for="f in features" :key="f.id" class="feature">
          <div class="feature-text">{{ f.text }}</div>
          <div v-for="p in subscriptionPlans" :key="p.id" class="plan-feature" :class="planType(p.id)">
            <!-- show the img only if the plan has this feature -->
            <!-- <img v-if="hasFeature(p.id, f.id)" src="@/assets/tree-crossing-icon-128.png"/> -->
            <CheckIcon v-if="hasFeature(p.id, f.id)" class="feature-check"></CheckIcon>
            <div v-if="f.id == 'ShareTrees' && getPlanLimits(p.id).shareLimit > 0" class="limit">
              up to {{ getPlanLimits(p.id).shareLimit }} people
            </div>
            <div v-else-if="getPlanLimit(p.id, f.id) > 0" class="limit">
              up to {{ getPlanLimit(p.id, f.id) }}
            </div>
          </div>
        </li>
      </ul>
    </div>
    <SubscriptionConfirmPlanModal ref="confirmModalRef" @add="addAsync"/>
    <PaymentModal ref="paymentModalRef"/>
  </div>
</template>

<style lang="scss" scoped>
.plans-page {
  max-width: 1000px;

  h1 {
    margin: 1rem 0 2rem;
    text-align: center;

    @media (min-width: 768px) {
      margin: 3rem 0 3rem;      
    }
  }
}

$memColor: #dae3f3;
$genColor: #f6d0ba;
$plusColor: #f2ab7c;
$proColor: #ed7d31;

label, .feature-list-header div {
  &.mem {
    background-color: $memColor;
  }
  &.gen {
    background-color: $genColor;
  }
  &.plus {
    background-color: $plusColor;
  }
  &.pro {
    background-color: $proColor;
  }
}

$plan-border-radius: 10px;

.plan-list {

  li {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .current-indicator {
      display: none;
      align-items: center;
      justify-content: center;
    }

    .current-indicator.over {
      height: 2rem;
    }

    @media (min-width: 768px) {
      .current-indicator.inner {
        display: none;
      }
    }

    .plan {
      position: relative;
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      button.overlay {
        left: 12px;
        right: 12px;
        width: calc(100% - 24px);
      }
    }

    &.current {
      .current-indicator.over {
        border-radius: 4px;
        background-color: #ddd;
      }

      .plan-details, .plan-feature-list {
        background-color: #f2f2f2;
      }
    }

    &.selected .plan::before {
      content: "";
      position: absolute;
      top: -8px;
      left: -8px;
      right: -8px;
      bottom: -8px;
      border: 4px solid var(--bs-primary);
      border-radius: calc($plan-border-radius + 6px);
      z-index: -1;
    }

    &.selected.current .plan::before {
      border-color: #aaa;
    }
  }

  label {
    display: grid;
    grid-template-columns: 2rem 1fr 2rem;
    height: 2.5rem;
    justify-items: center;
    align-items: center;
    font-size: 1.15rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    input {
      transform: scale(1.2);
    }

    span {
      grid-column: 2;
      //padding-right: 2rem;
    }
  }

  input[type="radio"] {
    justify-self: center;
    width: 1rem;
  }

  .plan-details {
    border: 1px solid #ddd;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    @media (min-width: 768px) {
      border-radius: 0;
    }
  }

  .plan-description {
    padding: 0.5rem;
    font-size: 0.875rem;

    @media (min-width: 768px) {
      min-height: 5rem;
    }
  }

  @media (min-width: 768px) {
    .subscription-price {
      border-top: 1px solid #ddd;
    }
  }
}

.plan-feature-list {
  flex-grow: 1; // fill the remaining space
  border: 1px solid #ddd;
  border-top: none;
  border-bottom-left-radius: $plan-border-radius;
  border-bottom-right-radius: $plan-border-radius;
  font-size: 0.875rem;

  @media (max-width: 768px) {
    display: none;
  }

  ul {
    padding: 0.5rem;

    li {
      margin-bottom: 0.5rem;
      padding: 0;

      .feature-check {
        margin-top: 4px;
      }
    }
  }
}

.action-row {
  text-align: center;
}

.plan-feature {
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: start;

  .feature-check {
    width: 24px;
  }

  svg {
    color: green;
    stroke-width: 3px;
  }

  &.mem img {
    filter: contrast(0.5) sepia(1) hue-rotate(180deg) saturate(1.1) brightness(1.2);
  }

  &.gen img {
    filter: contrast(0.5) sepia(1) hue-rotate(-30deg) saturate(1.1) brightness(1.2);
  }

  &.plus img {
    filter: contrast(0.7) sepia(0.5) saturate(1.4) brightness(1.1);
  }
}

.feature-list {
  max-width: 600px;
  margin: 2rem auto 0;

  @media (min-width: 768px) {
    display: none;
  }

  h4 {
    text-align: center;
  }

  .feature-list-header {
    min-height: 3rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    font-size: 0.75rem;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }

  li {
    margin-bottom: 0.5rem;
  }

  .feature {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 0.5rem;
    row-gap: 0.25rem;
    padding: 0.5rem 0;

    .feature-text {
      font-size: 0.875rem;
      grid-column: 1 / -1;
    }

    .plan-feature {
      grid-template-columns: 1fr;
      justify-items: center;
      text-align: center;
    }

    .limit {
      font-size: 0.75rem;
      color: #888;
    }
  }
}
</style>

<script setup lang="ts">
import { computed, nextTick, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { hideModalAsync, usePageTitle } from '@/util/AppUtil'
import { defaultPlanId, subscriptionPlans } from '@/gp/GroupAdminModel'
import { useSubscriptionStore } from '@/gp/SubscriptionStore'
import { TokenManager } from '@/auth/TokenManager'
import { CheckIcon } from '@zhuowenli/vue-feather-icons'
import { LoadMode } from '@/util/AsyncData'
import Modal from '@/util/Modal.vue'
import SubscriptionConfirmPlanModal from '@/account/SubscriptionConfirmPlanModal.vue'
import SubscriptionPrice from '@/account/SubscriptionPrice.vue'
import PaymentModal from '@/account/PaymentModal.vue'

const subStore = useSubscriptionStore()
const router = useRouter()

usePageTitle('Plans')

const features = [
  { id: "JoinGroups", text: "Join groups when invited" },
  { id: "ViewTrees", text: "View trees shared by others" },
  { id: "UploadTrees", text: "Upload trees" },
  { id: "ShareTrees", text: "Share your trees"},
  { id: "CreateGroups", text: "Create groups and invite others"},
]

const memberFeatures = ['JoinGroups', 'ViewTrees', 'UploadTrees']
const genealogistFeatures = memberFeatures.concat(['UploadTrees', 'CreateGroups', 'ShareTrees'])
const professionalFeatures = memberFeatures.concat(['UploadTrees', 'ShareTrees'])
const planFeatures = new Map<string, string[]>([
  ['mem', memberFeatures],
  ['gen', genealogistFeatures],
  ['plus', genealogistFeatures],
  ['pro', professionalFeatures],
])

interface PlanLimits {
  treeLimit: number,
  shareLimit: number,
}

const planLimits = new Map<string, PlanLimits>([
  ['mem', { treeLimit: 1, shareLimit: 0 }],
  ['gen', { treeLimit: 1, shareLimit: 5 }],
  ['plus', { treeLimit: 5, shareLimit: -1 }],
  ['pro', { treeLimit: -1, shareLimit: 1 }],
])

const selectedPlanIdRef = ref(defaultPlanId)
const confirmChangeModalRef = ref<InstanceType<typeof Modal>>()
const confirmModalRef = ref<InstanceType<typeof SubscriptionConfirmPlanModal>>()
const paymentModalRef = ref<InstanceType<typeof PaymentModal>>()

const canEditRef = computed(() => TokenManager.isAccessTokenValid)
const titleRef = computed(() => canEditRef.value ? 'Choose your plan' : 'Membership plans')
const subRef = computed(() => {
  const keyList = subStore.getAsyncKeyListForSelf(LoadMode.EnsureLoaded)?.data
  const subs = subStore.getLoadedSubscriptionList(keyList?.keys ?? [])
  return subs?.at(0) // HACK: assume max 1 subscription for now
})
const currentPlanIdRef = computed(() => subRef.value?.items.at(0)?.price?.productId ?? defaultPlanId)
const canChangePlanRef = computed(() => selectedPlanIdRef.value != currentPlanIdRef.value)

watch(subRef, () => {
  selectedPlanIdRef.value = currentPlanIdRef.value
}, { immediate: true })

function getPlanClasses(planId: string) {
  return {
    selected: canEditRef.value && planId == selectedPlanIdRef.value,
    current: planId == currentPlanIdRef.value,
  }
}

function planType(planId: string) {
  return planId.split('.')[0] // "mem.1" => "mem"
}

function firstPlanType(featureId: string) {
  return planType(subscriptionPlans.find(p => hasFeature(p.id, featureId))!.id)
}

function hasFeature(planId: string, featureId: string) {
  return planFeatures.get(planType(planId))?.includes(featureId)
}

function getPlanLimits(planId: string) {
  return planLimits.get(planType(planId))!
}

function getPlanLimit(planId: string, featureId: string) {
  const limits = getPlanLimits(planId)
  switch (featureId) {
    case 'UploadTrees':   return limits.treeLimit
    case 'ShareTrees':    return limits.shareLimit
  }
  return 0
}

function getPlanFeatures(planId: string) {
  return features.filter(f => hasFeature(planId, f.id))
}

function onChangePlan() {
  confirmModalRef.value?.open(selectedPlanIdRef.value)
}

async function addAsync() {
  const addResponse = await subStore.addAsync(selectedPlanIdRef.value!)
  await hideModalAsync(confirmModalRef.value!.$el)
  paymentModalRef.value!.open(addResponse.paymentIntentId!, addResponse.clientSecret!)
}
</script>
