<template>
  <div>
    <div class="options">
      <label v-if="canInviteRef">
        <input type="checkbox" class="form-check-input" v-model="showSuggestedRef" />
        Show suggested members
      </label>
    </div>
    <div v-if="refreshingMembersRef" class="loading-indicator">
      <Spinner color="#bbb"></Spinner> Loading members...
    </div>
    <ul v-if="showRelationshipsRef" class="category-list list-unstyled">
      <li v-for="rel in orderedCategories" :key="rel">
        <ExpandableItem 
          v-if="shouldShowCategory(rel)"
          :checkbox="false"
          :checked="categorySelected(rel) === true"
          :indeterminate="categorySelected(rel) === undefined"
          :checkDisabled="!canSelectCategory(rel)"
          expanded
          @check="() => toggleCategorySelected(rel)">
          <template #item>
            <h5 class="category-item">
              {{ categoryName(rel) }}
              <span v-if="categorySelected(rel) !== false" class="category-subtext">({{ selectedCategoryMembers(rel).length }} selected)</span>
              <span v-else class="category-subtext">({{ categoryMembers(rel).length }})</span>
            </h5>
          </template>
          <template #content>
            <ul class="member-list list-unstyled" :class="{ 'show-select': showSelectRef }">
              <li v-for="pm in categoryMembers(rel)" :key="pm.key" class="potential-member">
                <ExpandableItem v-if="pm.getProfilePersons().length > 1" class="multiple-profiles">
                  <template #item>
                    <div class="multiple-profiles-item" :class="{ 'member': !!pm.member, 'profile': pm.isShared, 'suggested': pm.isSuggested }">
                      <div>
                        <img v-if="pm.isSuggested" src="@/assets/icons/person_dotted_80.png">
                        <img v-else src="@/assets/icons/person_gray_150.png">
                      </div>
                      <div class="member-name">{{ pm.displayName }}</div>
                      <div class="profile-count">({{ pm.getProfilePersons().length }} profiles)</div>
                    </div>
                  </template>
                  <template #content>
                    <ul class="profile-list list-unstyled">
                      <li v-for="p in pm.getProfilePersons()" :key="p.personId">
                        <FamilyGroupMemberItem 
                          :family-group-id="props.familyGroupId" 
                          :pm="pm"
                          :profile-id="p.personId"
                          @click="onMemberClick">
                        </FamilyGroupMemberItem>
                      </li>
                    </ul>
                  </template>
                </ExpandableItem>
                <template v-else>
                  <input type="checkbox" v-if="false" :checked="memberSelected(pm)" @input="toggleMemberSelected(pm)" class="form-check-input" :disabled="!canSelectMember(pm)"/>
                  <FamilyGroupMemberItem 
                    :family-group-id="props.familyGroupId" 
                    :pm="pm"
                    :profile-id="pm.getProfilePersons()[0].personId"
                    @click="onMemberClick">
                  </FamilyGroupMemberItem>
                </template>
              </li>
            </ul>
          </template>
        </ExpandableItem>
      </li>
    </ul>
    <div v-else>
      <ul class="member-list list-unstyled">
        <li v-for="pm in currentMembersRef" :key="pm.key" class="potential-member">
          <FamilyGroupMemberItem 
            :family-group-id="props.familyGroupId" 
            :pm="pm"
            :profile-id="pm.memberProfilePersonId!"
            @click="onMemberClick">
          </FamilyGroupMemberItem>
        </li>
      </ul>
    </div>
    <div v-if="canInviteRef && !refreshingMembersRef" class="list-actions">
      <button type="button" class="btn btn-inline btn-link" data-bs-toggle="modal" data-bs-target="#inviteOtherModal">
        Invite someone else
      </button>
    </div>
    <Modal ref="shareOwnProfilesModalRef" title="Share profiles" ok-text="Share" @ok="shareOwnProfiles">
      <p>Share these profiles that you manage with the group?</p>
      <ul class="profile-list list-unstyled fs-small">
        <li v-for="pm in membersWithUnsharedOwnedProfilesRef.slice(0, 5)" :key="pm.key">
          {{ pm.displayName }} <span v-if="getUnsharedOwnedProfiles(pm).length > 1">({{ getUnsharedOwnedProfiles(pm).length }} profiles)</span>
        </li>
      </ul>
      <div v-if="membersWithUnsharedOwnedProfilesRef.length > 5" class="more fs-small">
        ...and {{ membersWithUnsharedOwnedProfilesRef.length - 5 }} more
      </div>
    </Modal>
    <FamilyGroupMemberModal ref="memberModalRef" 
      @request-share="onRequestShare"
      @invite="onInvite"
      @add-user="onAddUser"
      @view-share-request="onViewShareRequest"
      @view-invite="onViewInvite"
      @remove="onRemove"/>
    <DataGroupInviteModal ref="dataGroupInviteModalRef"/>
    <FamilyGroupInviteModal ref="userInviteModalRef"/>
    <FamilyGroupInviteOtherModal
      id="inviteOtherModal"
      ref="userInviteOtherModalRef"
      :family-group-id="props.familyGroupId"
      :potential-members="potentialMembers"
      @invite="onInviteOther"/>
    <FamilyGroupMemberRemoveModal ref="removeModalRef" @ok="removeMember"/>
    <UserAddModal ref="userAddModalRef"/>
  </div>
</template>

<style lang="scss" scoped>
.options {
  margin-bottom: 1rem;
  font-size: 0.875rem;
  color: #666;
}

.inline-help {
  font-size: 0.875rem;
}

.actions {
  margin-bottom: 1rem;
  display: flex;
  gap: 1rem;
  font-size: 0.875rem;
}

.loading-indicator {
  margin-top: 1rem;
  font-size: 0.875rem;
  color: #888;
}

.member-list {
  margin-left: 1rem;
  padding-bottom: 1rem;

  @media (min-width: 768px) {
    margin-left: 36px;
  }

  &.show-select .potential-member {
    display: grid;
    grid-template-columns: 1.5rem auto;
  }

  .multiple-profiles {
    margin-left: -20px;
    grid-column: span 2;
  }

  .multiple-profiles-item {
    display: grid;
    grid-template-columns: 25px 300px 200px;
    color: #333;

    img {
      width: 18px;
      opacity: 60%;
      transform: translateY(-2px);
    }

    &.shared {
      color: #888;
    }

    &.suggested {
      color: #bbb;
    }
  }

  .profile-list {
    margin-left: 2.5rem;
  }
}

input[type="checkbox"] {
  margin-right: 0.5rem;
}

.category-item {
  margin: 0 0 0.25rem;
  font-weight: inherit;
  font-size: 1rem;
  color: #888;

  .category-subtext {
    margin-left: 2px;
    color: #999;
    font-size: 0.875rem;
  }
}

.collapse {
  transition-duration: 0.5s;
}

.list-actions {
  font-size: 0.875rem;
}

#shareOwnProfilesModal {
  .profile-list, .more {
    margin-left: 1rem;
  }
}
</style>

<script setup lang="ts">
import { computed, ref, reactive, watch, onMounted } from 'vue'
import { usePersonStore } from '@/rd/PersonStore'
import { CompositeId } from '@/rd/CompositeId'
import { FamilyGroup, InvitationResult, ItemPermissions } from '@/gp/GroupAdminModel'
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { useDataGroupMemberStore } from '@/gp/DataGroupMemberStore'
import { useDataGroupInviteStore } from '@/gp/DataGroupInviteStore'
import { useFamilyGroupStore } from '@/gp/FamilyGroupStore'
import { useFamilyGroupMemberStore } from '@/gp/FamilyGroupMemberStore'
import { useFamilyGroupInviteStore } from '@/gp/FamilyGroupInviteStore'
import { RelationshipCategory, PotentialMember, getPotentialMembersAsync, getUnsharedOwnedProfiles, shareOwnProfilesAsync } from '@/manage/familygroups/FamilyGroupMemberManager'
import { hideModalAsync, notImplemented } from '@/util/AppUtil'
import _ from 'lodash'
import Spinner from '@/util/Spinner.vue'
import ExpandableItem from '@/util/ExpandableItem.vue'
import FamilyGroupMemberItem from './FamilyGroupMemberItem.vue'
import Modal from '@/util/Modal.vue'
import FamilyGroupMemberModal from './FamilyGroupMemberModal.vue'
import FamilyGroupInviteModal from './FamilyGroupInviteModal.vue'
import FamilyGroupInviteOtherModal from './FamilyGroupInviteOtherModal.vue'
import FamilyGroupMemberRemoveModal from './FamilyGroupMemberRemoveModal.vue'
import DataGroupInviteModal from '@/manage/datagroups/DataGroupInviteModal.vue'
import UserAddModal from '@/manage/users/UserAddModal.vue'

const props = defineProps<{
  familyGroupId: string
}>()

const familyGroupRef = ref<FamilyGroup>()
const potentialMembers = reactive([]) as PotentialMember[]
const showSuggestedRef = ref(true)
const refreshingMembersRef = ref(false)
const selectedMembers = reactive(new Set<PotentialMember>())

const shareOwnProfilesModalRef = ref<InstanceType<typeof Modal> | null>(null)
const memberModalRef = ref<InstanceType<typeof FamilyGroupMemberModal>>()
const removeModalRef = ref<InstanceType<typeof FamilyGroupMemberRemoveModal>>()
const dataGroupInviteModalRef = ref<InstanceType<typeof DataGroupInviteModal>>()
const userInviteModalRef = ref<InstanceType<typeof FamilyGroupInviteModal>>()
const userInviteOtherModalRef = ref<InstanceType<typeof FamilyGroupInviteOtherModal>>()
const userAddModalRef = ref<InstanceType<typeof UserAddModal>>()

const familyGroupStore = useFamilyGroupStore()
const familyGroupMemberStore = useFamilyGroupMemberStore()
const personStore = usePersonStore();
const dataGroupStore = useDataGroupStore()
const dataGroupMemberStore = useDataGroupMemberStore()
const dataGroupInviteStore = useDataGroupInviteStore()
const userInviteStore = useFamilyGroupInviteStore()

const scopeFamilyIdRef = computed(() => familyGroupRef.value?.scopeFamilyId)
const currentMembersRef = computed(() => potentialMembers.filter(pm => pm.member?.key))
const asyncMemberKeysRef = computed(() => familyGroupMemberStore.getAsyncKeyListsForFamilyGroups([props.familyGroupId])[0])
const asyncDataGroupMemberKeysRef = computed(() => dataGroupMemberStore.getAsyncKeyListsForFamilyGroups([props.familyGroupId]).at(0))
const asyncDataGroupInviteKeysRef = computed(() => dataGroupInviteStore.getAsyncKeyListForFamilyGroup(props.familyGroupId))
const asyncUserInviteKeysRef = computed(() => userInviteStore.getAsyncKeyListForFamilyGroup(props.familyGroupId))
const showRelationshipsRef = computed(() => potentialMembers.some(pm => pm.relationship != RelationshipCategory.None))

const permissionsRef = computed(() => familyGroupStore.getAsyncPermissions(props.familyGroupId)?.data ?? ItemPermissions.None) // loaded by parent
const canInviteRef = computed(() => (permissionsRef.value & ItemPermissions.AddItems) > 0)

watch([
  props,
  scopeFamilyIdRef, // ref changes when scope family changed
  () => asyncMemberKeysRef.value, // reactive object changes when users added or removed
  () => asyncDataGroupMemberKeysRef.value, // reactive object changes when trees or profiles added or removed
  () => asyncDataGroupInviteKeysRef.value, // reactive object changes when invites added
  () => asyncUserInviteKeysRef.value, // reactive object changes when invites added
],
refreshPotentialMembersAsync, 
{ immediate: true, deep: true })

async function refreshPotentialMembersAsync() {
  if (refreshingMembersRef.value)
    return // ignore nested or early requests
  refreshingMembersRef.value = true

  if (!props.familyGroupId)
    return

  familyGroupRef.value = await familyGroupStore.getAsyncGroup(props.familyGroupId)?.whenLoadCompleted
  if (!familyGroupRef.value)
    return
  
  console.log('Refreshing potential members') 

  const pms = await getPotentialMembersAsync(familyGroupRef.value)

  // ensure permissions and profiles are loaded
  const allProfileGroupIds = pms.map(pm => pm.getProfilePersons()).flat().map(p => CompositeId.getGroupId(p.personId)!)
  await dataGroupStore.getPermissionsListAsync(allProfileGroupIds)
  
  const profileIds = pms.map(pm => pm.getProfilePersons()).flat().map(p => p.personId)
  await personStore.getPersonListAsync(profileIds)

  potentialMembers.length = 0
  potentialMembers.push(...pms)

  selectedMembers.clear() // some may no longer be allowed, e.g. if just invited they can no longer be selected for invite

  refreshingMembersRef.value = false
}

// enum ActionMode {
//   None,
//   Invite,
// }

// const actionModeRef = ref(ActionMode.None)

const showSelectRef = ref(false) //computed(() => actionModeRef.value != ActionMode.None)

// function resetActionMode() {
//   actionModeRef.value = ActionMode.None
//   selectedMembers.clear()
// }

// const canInviteSomeRef = computed(() => canManageRef.value && potentialMembers.some(pm => pm.canInvite))
// const showInvitePeopleRef = computed(() => actionModeRef.value == ActionMode.None && canInviteSomeRef.value)
// const showInviteSelectedRef = computed(() => actionModeRef.value == ActionMode.Invite && canInviteSomeRef.value)
// const showInviteOtherRef = computed(() => actionModeRef.value == ActionMode.Invite)
// const inviteOtherTextRef = computed(() => showInviteSelectedRef.value ? 'Invite someone else' : 'Invite someone not listed')

const orderedCategories = [
    RelationshipCategory.Ancestors,
    RelationshipCategory.Scope,
    RelationshipCategory.Child,
    RelationshipCategory.Grandchild,
    RelationshipCategory.None,
]

function categoryName(rel: RelationshipCategory) {
  switch (rel) {
    case RelationshipCategory.Ancestors: return 'Parents and grandparents'
    case RelationshipCategory.Scope: return 'Common ancestors'
    case RelationshipCategory.Child: return 'Children and spouses'
    case RelationshipCategory.Grandchild: return 'Grandchildren and spouses'
    default: return 'Other Members'
  }
}

function shouldShowCategory(rel: RelationshipCategory) {
  return (rel >= RelationshipCategory.Scope) || categoryMembers(rel).length > 0
}

function canSelectMember(pm: PotentialMember) {
  // switch (actionModeRef.value) {
  //   case ActionMode.Invite: return pm.canUserInvite()
  //   default: return false
  // }
  return false
}

function memberSelected(pm: PotentialMember) {
  return selectedMembers.has(pm)
}

function toggleMemberSelected(pm: PotentialMember) {
  if (selectedMembers.has(pm)) {
    selectedMembers.delete(pm)
  }
  else {
    selectedMembers.add(pm)
  }
}

function categoryMembers(rel: RelationshipCategory) {
  return potentialMembers.filter(pm => pm.relationship === rel && (showSuggestedRef.value || pm.isShared))
}

function selectedCategoryMembers(rel: RelationshipCategory) {
  return categoryMembers(rel).filter(pm => selectedMembers.has(pm))
}

function canSelectCategory(rel: RelationshipCategory) {
  return categoryMembers(rel).some(pm => canSelectMember(pm))
}

function categorySelected(rel: RelationshipCategory) {
  const selectableMembers = categoryMembers(rel).filter(m => canSelectMember(m))
  const selectedMemberCount = selectableMembers.filter(pm => selectedMembers.has(pm)).length
  return selectableMembers.length > 0 && selectedMemberCount == selectableMembers.length ? true
    : selectedMemberCount > 0 ? undefined
    : false
}

function toggleCategorySelected(rel: RelationshipCategory) {
  const members = categoryMembers(rel)
  if (categorySelected(rel)) {
    members.forEach(pm => selectedMembers.delete(pm)) // select none if all currently selected
  } else {
    members
      .filter(m => canSelectMember(m))
      .forEach(pm => selectedMembers.add(pm)) // select all if either some or none currently selected
  }
}

const membersWithUnsharedOwnedProfilesRef = computed(() => potentialMembers.filter(pm => getUnsharedOwnedProfiles(pm).length > 0))

async function shareOwnProfiles() {
  await shareOwnProfilesAsync(props.familyGroupId, potentialMembers)
  shareOwnProfilesModalRef.value!.close()
}

function onMemberClick(pm: PotentialMember, profileId: string) {
  memberModalRef.value?.open(props.familyGroupId, pm, profileId)
}

async function onRequestShare(profileId: string) {
  await hideModalAsync(memberModalRef.value!.$el)
  const profileGroupId = CompositeId.getGroupId(profileId)
  dataGroupInviteModalRef.value?.openNew(profileGroupId!, props.familyGroupId)
}

async function onInvite(profileId: string) {
  await hideModalAsync(memberModalRef.value!.$el)
  userInviteModalRef.value?.openNew(props.familyGroupId, profileId)
}

async function onAddUser(profileId: string) {
  await hideModalAsync(memberModalRef.value!.$el)
  userAddModalRef.value?.open(profileId)
}

async function onViewShareRequest(inviteId: string) {
  await hideModalAsync(memberModalRef.value!.$el)
  dataGroupInviteModalRef.value?.openEdit(inviteId)
}

async function onViewInvite(pm: PotentialMember, profileId: string) {
  await hideModalAsync(memberModalRef.value!.$el)
  userInviteModalRef.value?.openEdit(pm.lastUserInvite!.id!)
}

async function onRemove(pm: PotentialMember, profileId: string) {
  await hideModalAsync(memberModalRef.value!.$el)
  removeModalRef.value?.open(pm, profileId)
}

async function removeMember(pm: PotentialMember, profileId: string) {
  if (pm.member) {
    await familyGroupMemberStore.deleteAsync(pm.member)
  }
  else {
    if (pm.lastUserInvite?.result == InvitationResult.Pending) {
      await userInviteStore.cancelAsync(pm.lastUserInvite.id!)
    }
    const groupMember = pm.getProfileGroupMember(profileId)
    if (groupMember) {
      await dataGroupMemberStore.deleteAsync(groupMember)
    }
  }
  removeModalRef.value?.close()
}

async function onInviteOther(profilePersonId: string) {
  const profileId = CompositeId.getGroupId(profilePersonId)!
  await hideModalAsync(userInviteOtherModalRef.value!.$el)
  userInviteModalRef.value?.openNew(props.familyGroupId, profileId)
}
</script>
