<template>
  <Modal ref="modalRef" class="user-add-modal" @ok="addUser">
    <template #title>Create child account</template>
    <p>
      Create a supervised user account for this profile?
    </p>
    <PersonCard :person-id="profilePersonIdRef" profile select-mode layout="small"/>
    <p class="details">
      You will be the initial account supervisor and will be able to manage all aspects of the account.
      You can add other supervisors once the account is created.
      The supervised user will have limits on what they can do.
      <router-link to="/learn/supervised-users" @click="onNavigate">Learn more</router-link>
    </p>
    <template #okText>Create account</template>
    <template #busyText>Creating...</template>
  </Modal>
</template>

<style lang="scss" scoped>
.person-card {
  margin: 1rem;
}

.details {
  font-size: 0.875rem;
  color: #888;
}
</style>

<script lang="ts" setup>
import { ref } from 'vue'
import { useUserStore } from '@/gp/UserStore'
import Modal from '@/util/Modal.vue'
import PersonCard from '@/manage/PersonCard.vue'
import { CompositeId } from '@/rd/CompositeId';


const profilePersonIdRef = ref<string>()
const modalRef = ref<InstanceType<typeof Modal>>()

const userStore = useUserStore();

defineExpose({
  open
})

function open(profilePersonId: string) {
  profilePersonIdRef.value = profilePersonId
  modalRef.value?.open()  
}

async function addUser() {
  const profileGroupId = CompositeId.getGroupId(profilePersonIdRef.value)!
  const newUserId = await userStore.addForProfileAsync(profileGroupId)
  modalRef.value?.close()
}

function onNavigate() {
  modalRef.value?.close()
}
</script>