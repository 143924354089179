import { reactive } from 'vue'
import { Sync } from "./ResearchDataModel"
import { rd } from './ResearchDataApi'
import { AsyncData, LoadMode } from "@webapp/util/AsyncData"
import { AsyncDataStore, VersionedKeyList } from "@webapp/util/AsyncDataStore"
import { defineStore } from "pinia"
import { DateTime } from "luxon"
import { DateTimeUtil } from "@webapp/util/LuxonUtil"
import { assignExisting, isDefined } from "@webapp/util/TypeScriptUtil"
import { useWorkspaceStore } from './WorkspaceStore'

export const useSyncStore = defineStore("syncStore", () => {
    const asyncSyncs = reactive(new Map<string, AsyncData<Sync>>())

    const workspaceStore = useWorkspaceStore()
    
    function getAsyncSync(id: string | undefined, loadMode = LoadMode.TrackOnly) {
        return id ? getAsyncSyncList([id], loadMode)[0] : undefined
    }

    function getAsyncSyncList(ids: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        const loadAsync = async (idsToLoad: string[], requestTime: DateTime) => {
            const plainItems = await rd.getPlainByIdsAsync("syncs", idsToLoad)
            return processExpandedSyncs(plainItems, requestTime)
        }
        return AsyncDataStore.getAsyncItemsByIds(asyncSyncs, ids, "Sync", loadMode, loadAsync)
    }

    function getAsyncKeyListForWorkspace(workspaceId: string | undefined, loadMode = LoadMode.TrackOnly) {
        const loadAsync = async (idsToLoad: string[], requestTime: DateTime) => {
            const plainItems = await rd.getPlainByIdsAsync(id => `workspaces/${id}/syncs`, idsToLoad)
            return processExpandedSyncs(plainItems, requestTime)
        }
        return AsyncDataStore.getAsyncKeyListForRelatedItems(workspaceId, "Workspace", workspaceStore.asyncSyncKeys, 
            asyncSyncs, "Sync", loadMode, loadAsync)
    }

    function getLoadedSyncs(ids: (string | undefined)[]) {
        return getAsyncSyncList(ids, LoadMode.TrackOnly).map(a => a.data).filter(isDefined)
    }

    function addSyncsToStore(plainSyncs: any[], requestTime: DateTime) {
        const syncMap = processExpandedSyncs(plainSyncs, requestTime)
        const syncStore = useSyncStore()
        AsyncDataStore.addItemMapToStore(syncStore.asyncSyncs, "Sync", syncMap, requestTime)
    }

    function processExpandedSyncs(plainSyncs: any[], requestTime: DateTime) {
        return AsyncDataStore.createItemMap(plainSyncs, "Sync", plainToSync, s => s.id!)
    }

    return {
        asyncSyncs,

        getAsyncSync,
        getAsyncSyncList,
        getLoadedSyncs,
        getAsyncKeyListForWorkspace,

        addSyncsToStore,
        processExpandedSyncs,
    }
})

function plainToSync(p: any) {
    const s = assignExisting(new Sync(), p)
    s.createdDate = DateTimeUtil.fromAPI(p.createdDate)
    if (p.completedDate) {
        s.completedDate = DateTimeUtil.fromAPI(p.completedDate)
    }
    return s;
}
