import { workspaceTypeInfos } from "./ResearchDataModel"

// NOTE: These are defined in display order
export enum ResearchToolCategory {
    DataManager,
    GlobalTree,
    OnlineRecords,
    // Education,
    DNA,
    Other,
}

export const researchToolCategoryNames = {
    [ResearchToolCategory.DataManager]: 'Genealogy apps',
    [ResearchToolCategory.OnlineRecords]: 'Online records',
    [ResearchToolCategory.GlobalTree]: 'Global trees',
    // [ResearchToolCategory.Education]: 'Education',
    [ResearchToolCategory.DNA]: 'DNA',
    [ResearchToolCategory.Other]: 'Other tools',
}

export interface ResearchToolInfo {
    id: string
    name: string
    category: ResearchToolCategory
    icon: any
}

export const researchToolInfos: ResearchToolInfo[] = [
    ...workspaceTypeInfos.map(w => ({
        id: w.id,
        name: w.product,
        category: ResearchToolCategory.DataManager,
        icon: w.icon,
    })),
    {
        id: 'FamilySearchTree',
        name: 'FamilySearch Tree',
        category: ResearchToolCategory.GlobalTree,
        icon: new URL('@webapp/assets/logos/FamilySearch.png', import.meta.url).href,
    },
    {
        id: 'FamilySearchRecords',
        name: 'FamilySearch Records',
        category: ResearchToolCategory.OnlineRecords,
        icon: new URL('@webapp/assets/logos/FamilySearch.png', import.meta.url).href,
    },
    {
        id: 'AncestryRecords',
        name: 'Ancestry Records',
        category: ResearchToolCategory.OnlineRecords,
        icon: new URL('@webapp/assets/logos/Ancestry.png', import.meta.url).href,
    },
    {
        id: 'AncestryDNA',
        name: 'Ancestry DNA',
        category: ResearchToolCategory.DNA,
        icon: new URL('@webapp/assets/logos/Ancestry.png', import.meta.url).href,
    },
    {
        id: 'Findmypast',
        name: 'Findmypast',
        category: ResearchToolCategory.OnlineRecords,
        icon: new URL('@webapp/assets/logos/Findmypast.png', import.meta.url).href,
    },
    {
        id: 'MyHeritage',
        name: 'MyHeritage',
        category: ResearchToolCategory.OnlineRecords,
        icon: new URL('@webapp/assets/logos/MyHeritage.png', import.meta.url).href,
    },
    {
        id: 'MyHeritageDNA',
        name: 'MyHeritage DNA',
        category: ResearchToolCategory.DNA,
        icon: new URL('@webapp/assets/logos/MyHeritage.png', import.meta.url).href,
    },
    {
        id: '23andMe',
        name: '23andMe',
        category: ResearchToolCategory.DNA,
        icon: new URL('@webapp/assets/logos/23andme.png', import.meta.url).href,
    },
    {
        id: 'WikiTree',
        name: 'WikiTree',
        category: ResearchToolCategory.GlobalTree,
        icon: new URL('@webapp/assets/logos/WikiTree.png', import.meta.url).href,
    },
    {
        id: 'GoldieMay',
        name: 'Goldie May',
        category: ResearchToolCategory.Other,
        icon: new URL('@webapp/assets/logos/GoldieMay_64.png', import.meta.url).href,
    },
].sort((a, b) => {
    // sort in display order
    const catDiff = a.category - b.category
    return (catDiff != 0) ? catDiff
        : a.name.localeCompare(b.name)
})
