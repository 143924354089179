import { ref, computed, MaybeRef, toValue } from 'vue'
import { AsyncUtil } from './AsyncUtil'
import { setEqual } from './IterableUtil'
import { PrimitiveValue } from './Api'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useEditMode<T extends { [key in string]: any }>(
    origObj: MaybeRef<T | undefined>, 
    editObj: T, 
    editProperties: (keyof T)[], 
    allowEdit?: MaybeRef<boolean>,
    allowSave?: MaybeRef<boolean>) {

    const _isEditing = ref(false)
    const _busy = ref(false)

    const isEditing = computed(() => _isEditing.value)
    const busy = computed(() => _busy.value)
    const canEdit = computed(() => !!toValue(origObj) && !isEditing.value && (toValue(allowEdit) ?? true))
    const canEditValues = computed(() => isEditing.value && !busy.value)

    const hasChanges = computed(() => {
        const unrefObj = toValue(origObj)
        if (unrefObj) {
            for (const key of editProperties) {
                if (propertyChanged(editObj[key], unrefObj[key])) {
                    return true
                }
            }
            return false
        }
        return true
    })
    
    const canSave = computed(() => isEditing.value && hasChanges.value && !busy.value && (toValue(allowSave) ?? true))
    const canCancel = computed(() => isEditing.value && !busy.value)
    const saveText = computed(() => busy.value ? "Saving..." : "Save")

    function edit() {
        if (canEdit.value) {
            if (toValue(origObj)) {
                // if editObj is a reactive proxy, this will trigger reactive effects
                const source = toValue(origObj)!
                Object.assign(editObj, source) // shallow copy

                // clone arrays (don't use original on editObj!)
                for (const key of editProperties) {
                    if (Array.isArray(source[key])) {
                        editObj[key] = [...(source[key] as PrimitiveValue[])] as T[keyof T]
                    }
                }
            }
            _isEditing.value = true
        }
    }

    async function saveChanges(doSave: () => void | Promise<void>) {
        _busy.value = true

        try {
            await AsyncUtil.atLeast(500, doSave())
            _isEditing.value = false;
        } finally {
            _busy.value = false
        }
    }

    function cancel() {
        _isEditing.value = false;
    }
      
    return {
        isEditing,
        busy,
        canEdit,
        canEditValues,
        edit,
        saveText,
        hasChanges,
        canSave,
        saveChanges,
        canCancel,
        cancel,
    }   
}

export type EditableValue = PrimitiveValue | PrimitiveValue[]

export function propertyChanged(a: any, b: any) {
    const isArray = Array.isArray(a) || Array.isArray(b)
    return isArray
        ? !setEqual(a as any[] ?? [], b as any[] ?? [])
        : a != b
}
