import { computed } from 'vue'
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { useWorkspaceStore } from '@/rd/WorkspaceStore'
import { useSyncStore } from '@/rd/SyncStore'
import { useViewPersonStore } from '@/rd/ViewPersonStore'
import { LoadMode } from '@/util/AsyncData'
import { SyncState } from '@/rd/ResearchDataModel'
import { isDefined } from '@/util/TypeScriptUtil'
import { TokenManager } from '@/auth/TokenManager'

export function useExploreAction() {
    const dataGroupStore = useDataGroupStore()
    const workspaceStore = useWorkspaceStore()
    const syncStore = useSyncStore()
    const viewPersonStore = useViewPersonStore()
    
    const selfPersonIdRef = computed(() => dataGroupStore.getAsyncGroup(TokenManager.userProfileId, LoadMode.EnsureLoaded)?.data?.startItemId)

    const canExploreRef = computed(() => {
        const rgs = dataGroupStore.getOwnedResearchGroups(LoadMode.EnsureLoaded)
        const rgIds = rgs.map(rg => rg.id!)
        const wksps = workspaceStore.getWorkspacesForGroups(rgIds, LoadMode.EnsureLoaded)
        const lastSyncIds = wksps.map(w => w.lastSyncId).filter(isDefined)
        const syncs = syncStore.getLoadedSyncs(lastSyncIds)
        const completed = syncs.filter(s => s.state == SyncState.Completed)
        return completed.length > 0
    })

    const exploreFamilyUrlRef = computed(() => viewPersonStore.getAsyncPerson(selfPersonIdRef.value, LoadMode.EnsureLoaded)?.data?.pageUrl)

    return {
        canExploreRef,
        exploreFamilyUrlRef,
    }
}
