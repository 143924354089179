<template>
  <div ref="modalElemRef" class="modal" data-bs-backdrop="static" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            <img class="modal-icon" src="@/assets/icons/mail_gray_60.png"/> Sharing request
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="invite-header">
            <div class="invite-header-item">
              <label class="form-label">From</label>
              <div>{{ senderUserRef?.displayName }}</div>
            </div>
            <div class="invite-header-item">
              <label class="form-label">To</label>
              <div v-if="isDataGroupApprovedRef">
                {{ familyGroupOwnerRef?.displayName }}
              </div>
              <div v-else>
                {{ dataGroupOwnerRef?.displayName }}
              </div>
            </div>
            <div v-if="!isNewRef" class="invite-header-item">
              <label class="form-label">Sent</label>
              <div class="invite-date">
                {{ DateTimeUtil.toUserActionDate(inviteRef?.lastSentDate) }}
                ({{ DateTimeUtil.toRelativePastDate(inviteRef?.lastSentDate) }})
              </div>
            </div>
            <div v-if="!isNewRef && inviteRef?.result != InvitationResult.Accepted" class="invite-header-item">
              <label class="form-label">Expires</label>
              <div class="invite-date">
                {{ DateTimeUtil.toUserActionDate(inviteRef?.expirationDate, true) }}
                ({{ DateTimeUtil.toRelativeDate(inviteRef?.expirationDate) }})
              </div>
            </div>
            <div v-if="!isNewRef && inviteRef?.status != DataGroupInviteStatus.Pending" class="invite-header-item">
              <label class="form-label">Status</label>
              <div class="invite-status" :class="inviteRef!.status.toLowerCase()">
                <template v-if="isDataGroupApprovedRef">
                  {{ inviteRef!.statusText }}
                </template>
                <template v-else>
                  {{ inviteRef!.statusText }}
                </template>
              </div>
            </div>
            <div v-if="inviteRef?.result == InvitationResult.Accepted" class="invite-header-item">
              <label class="form-label">Approved</label>
              <div class="invite-date">
                {{ DateTimeUtil.toUserActionDate(inviteRef?.closedDate, true) }}
                ({{ DateTimeUtil.toRelativeDate(inviteRef?.closedDate) }})
              </div>
            </div>
          </div>
          <div v-if="isRejectedRef && canApproveRef" class="alert alert-primary">
            Even though this request has been declined, you can still approve it until it is either cancelled or expires.
          </div>
          <LoadingIndicator v-if="loadingRef"></LoadingIndicator>
          <div v-else-if="inviteRef" class="invite-body">
            <div v-if="isProfileRef" class="data-group">
              <div>
                {{ senderUserRef?.displayName }} would like to share this profile:
              </div>
              <div>
                <PersonCard :person-id="dataGroupRef?.startItemId" profile layout="small" select-mode></PersonCard>
              </div>
            </div>
            <div v-else class="data-group">
              <div>
                {{ senderUserRef?.displayName }} would like to share this tree:
              </div>
              <div>
                <ResearchGroupCard :data-group-id="inviteRef.dataGroupId" small select-mode></ResearchGroupCard>
              </div>
            </div>
            <div class="family-group">
              <div>
                with this group:
              </div>
              <div>
                <FamilyGroupItem :family-group-id="inviteRef.principalId" small select-mode></FamilyGroupItem>
              </div>
            </div>
            <div v-if="senderMessageRef || canEditRef" class="message">
              <label class="form-label">Message</label>
              <textarea v-if="canEditRef" v-model="senderMessageRef" class="form-control"></textarea>
              <div v-else>{{ inviteRef.senderMessage }}</div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" v-if="canCancelRef" class="btn btn-outline-danger secondary-action" :disabled="busyRef" @click="cancelInvite">
            <template v-if="busyRef">
              <Spinner></Spinner> Cancelling...
            </template>
            <template v-else>
              <Trash2Icon></Trash2Icon> Cancel request
            </template>
          </button>
          <button type="button" v-if="canRejectRef" class="btn btn-outline-danger secondary-action" :disabled="busyRef" @click="rejectInvite">
            <template v-if="busyRef">
              <Spinner></Spinner> Declining...
            </template>
            <template v-else>
              Decline
            </template>
          </button>
          <button type="button" v-if="canSendRef" class="btn btn-primary" :disabled="busyRef" @click="sendInvite">
            <template v-if="busyRef">
              <Spinner></Spinner> Sending...
            </template>
            <template v-else>
              <SendIcon></SendIcon> Send
            </template>
          </button>
          <button type="button" v-if="canResendRef" class="btn btn-primary" :disabled="busyRef" @click="resendInvite">
            <template v-if="busyRef">
              <Spinner></Spinner> Resending...
            </template>
            <template v-else>
              <SendIcon></SendIcon> Resend
            </template>
          </button>
          <button type="button" v-if="canApproveRef" class="btn btn-primary" :disabled="busyRef" @click="approveInvite">
            <template v-if="busyRef">
              <Spinner></Spinner> Approving...
            </template>
            <template v-else>
              <CheckIcon></CheckIcon> Approve
            </template>
          </button>
          <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal">
            {{ closeActionTextRef }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-icon {
  margin-top: -4px;
  margin-right: 0.25rem;
  width: 2.5rem;
  filter: brightness(0) saturate(100%) invert(63%) sepia(76%) saturate(5097%) hue-rotate(184deg) brightness(100%) contrast(94%);
}

.modal-body {
  padding: 0 2rem 1rem;
}

.invite-header {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.invite-header-item {
  display: grid;
  grid-template-columns: 80px max-content;
  align-items: baseline;

  label.form-label {
    margin-top: 3px;
    font-size: 0.875rem;
  }
}

.invite-date {
  font-size: 0.875rem;
  color: #888;
}

.invite-status {
  margin: 4px 0 2px;
  padding: 0 4px;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: white;
  
  &.pending {
    background-color: var(--bs-warning);
    color: #333;
  }

  &.approved {
    background-color: var(--bs-success);
  }

  &.rejected {
    background-color: #888;
  }

  &.expired {
    background-color: #bbb;    
  }
}

label.form-label {
  color: #666;
}

.alert {
  padding: 0.5rem;
  font-size: 0.875rem;
}

.invite-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.message {
  min-height: 3rem;
}

.person-card, .research-group-card, .family-group-item {
  margin: 0.5rem 1rem;
}

.modal-footer {
  padding: 1rem 1.5rem;

  button {
    min-width: 80px;
  }

  .secondary-action {
    margin-left: 0;
    margin-right: auto;
  }
}
</style>

<script setup lang="ts">
import { computed, ref, watchEffect } from 'vue'
import { useDataGroupInviteStore } from '@/gp/DataGroupInviteStore'
import { useUserStore } from '@/gp/UserStore'
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { useFamilyGroupStore } from '@/gp/FamilyGroupStore'
import { ApprovalStatus, DataGroupInvite, DataGroupInviteStatus, DataGroupType, InvitationResult, PrincipalType } from '@/gp/GroupAdminModel'
import { LoadMode } from '@/util/AsyncData'
import { DateTimeUtil } from '@/util/LuxonUtil'
import { TokenManager } from '@/auth/TokenManager'
import { CheckIcon, SendIcon, Trash2Icon } from '@zhuowenli/vue-feather-icons'
import { Modal } from 'bootstrap'
import { DateTime, Duration } from 'luxon'
import PersonCard from '@/manage/PersonCard.vue'
import ResearchGroupCard from '@/manage/researchgroups/ResearchGroupCard.vue'
import FamilyGroupItem from '@/manage/familygroups/FamilyGroupItem.vue'
import Spinner from '@/util/Spinner.vue'
import LoadingIndicator from '@/util/LoadingIndicator.vue'

const minResendInterval = Duration.fromObject({ days: 3 })

const inviteRef = ref<DataGroupInvite>()
const senderMessageRef = ref('')
const busyRef = ref(false)
const modalElemRef = ref<InstanceType<typeof HTMLElement>>()

const inviteStore = useDataGroupInviteStore()
const userStore = useUserStore()
const dataGroupStore = useDataGroupStore()
const familyGroupStore = useFamilyGroupStore()

const isNewRef = computed(() => !inviteRef.value?.id)
const senderUserRef = computed(() => userStore.getAsyncUser(inviteRef.value?.senderUserId, LoadMode.EnsureLoaded)?.data)
const dataGroupRef = computed(() => dataGroupStore.getAsyncGroup(inviteRef.value?.dataGroupId, LoadMode.EnsureLoaded)?.data)
const dataGroupOwnerRef = computed(() => userStore.getAsyncUser(dataGroupRef.value?.ownerId, LoadMode.EnsureLoaded)?.data)
const familyGroupRef = computed(() => familyGroupStore.getAsyncGroup(inviteRef.value?.principalId, LoadMode.EnsureLoaded)?.data)
const familyGroupOwnerRef = computed(() => userStore.getAsyncUser(familyGroupRef.value?.ownerId, LoadMode.EnsureLoaded)?.data)

const loadingRef = computed(() => !inviteRef.value || !dataGroupOwnerRef.value || !familyGroupOwnerRef.value)
const isProfileRef = computed(() => dataGroupRef.value?.groupType == DataGroupType.Profile)
const isSenderRef = computed(() => inviteRef.value?.senderUserId == TokenManager.userId)
const isDataGroupOwnerRef = computed(() => dataGroupRef.value?.ownerId == TokenManager.userId)
const isFamilyGroupOwnerRef = computed(() => familyGroupRef.value?.ownerId == TokenManager.userId)
const isDataGroupApprovedRef = computed(() => inviteRef.value?.dataGroupApproval == ApprovalStatus.Approved)
const isPrincipalApprovedRef = computed(() => inviteRef.value?.principalApproval == ApprovalStatus.Approved)
const isRejectedRef = computed(() => inviteRef.value?.dataGroupApproval == ApprovalStatus.Rejected || inviteRef.value?.principalApproval == ApprovalStatus.Rejected)
const canEditRef = computed(() => canSendRef.value || canResendRef.value)
const canSendRef = computed(() => isNewRef.value && !loadingRef.value)
const minResendDateRef = computed(() => inviteRef.value?.lastSentDate?.plus(minResendInterval))
const canResendRef = computed(() => inviteRef.value && !isNewRef.value && isSenderRef.value && 
  inviteRef.value.result == InvitationResult.Pending &&
  minResendDateRef.value &&
  DateTime.utc() >= minResendDateRef.value)
const canCancelRef = computed(() => !isNewRef.value && inviteRef.value?.result == InvitationResult.Pending &&
  (isSenderRef.value ||
  (!isDataGroupApprovedRef.value && isFamilyGroupOwnerRef.value) ||
  (!isPrincipalApprovedRef.value && isDataGroupOwnerRef.value)))
const canApproveRef = computed(() => 
  (!isDataGroupApprovedRef.value && isDataGroupOwnerRef.value) ||
  (!isPrincipalApprovedRef.value && isFamilyGroupOwnerRef.value))
const canRejectRef = computed(() => 
  (inviteRef.value?.dataGroupApproval == ApprovalStatus.Pending && isDataGroupOwnerRef.value) ||
  (inviteRef.value?.principalApproval == ApprovalStatus.Pending && isFamilyGroupOwnerRef.value))
const closeActionTextRef = computed(() => canSendRef.value ? "Cancel" : "Close")

watchEffect(() => {
  if (isNewRef.value) {
    if (dataGroupRef.value && isDataGroupOwnerRef.value) {
      inviteRef.value!.dataGroupApproval = ApprovalStatus.Approved
    }
    if (familyGroupRef.value && isFamilyGroupOwnerRef.value) {
      inviteRef.value!.principalApproval = ApprovalStatus.Approved
    }
  }
})

defineExpose({
  openNew,
  openEdit,
})

function openNew(dataGroupId: string, familyGroupId: string) {
  const inv = new DataGroupInvite()
  inv.senderUserId = TokenManager.userId
  inv.dataGroupId = dataGroupId
  inv.principalType = PrincipalType.FamilyGroup
  inv.principalId = familyGroupId
  inviteRef.value = inv
  busyRef.value = false
  Modal.getOrCreateInstance(modalElemRef.value!)?.show()
}

function openEdit(inviteId: string) {
  inviteRef.value = inviteStore.getAsyncInvite(inviteId)?.data // loaded by parent
  senderMessageRef.value = inviteRef.value?.senderMessage ?? ''
  busyRef.value = false
  Modal.getOrCreateInstance(modalElemRef.value!)?.show()
}

async function sendInvite() {
  try {
    busyRef.value = true
    inviteRef.value!.senderMessage = senderMessageRef.value
    await inviteStore.sendAsync(inviteRef.value!)
    Modal.getOrCreateInstance(modalElemRef.value!)?.hide()
  }
  finally {
    busyRef.value = false    
  }
}

async function resendInvite() {
  try {
    busyRef.value = true
    await inviteStore.resendAsync(inviteRef.value!.id!, senderMessageRef.value)
    Modal.getOrCreateInstance(modalElemRef.value!)?.hide()
  }
  finally {
    busyRef.value = false    
  }
}

async function cancelInvite() {
  try {
    busyRef.value = true
    await inviteStore.cancelAsync(inviteRef.value!.id!)
    Modal.getOrCreateInstance(modalElemRef.value!)?.hide()
  }
  finally {
    busyRef.value = false    
  }
}

async function approveInvite() {
  try {
    busyRef.value = true
    await inviteStore.approveAsync(inviteRef.value!.id!)
    Modal.getOrCreateInstance(modalElemRef.value!)?.hide()
  }
  finally {
    busyRef.value = false
  }
}

async function rejectInvite() {
  try {
    busyRef.value = true
    await inviteStore.rejectAsync(inviteRef.value!.id!)
    Modal.getOrCreateInstance(modalElemRef.value!)?.hide()
  }
  finally {
    busyRef.value = false
  }
}
</script>
