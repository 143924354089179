<template>
  <div class="auth-code">
    <button type="button" v-if="!codeRef || isCodeExpiredRef" class="btn btn-inline btn-link" @click="refreshCode">
        Get a new code
      </button>
      <div v-else class="code">
        {{ codeRef?.code }}
      </div>
      <div class="remaining" :class="{ show: showTimerRef}">
        Expires in {{ remainingTextRef }}
      </div>
  </div>
</template>

<style lang="scss" scoped>
.code {
  font-size: 2rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: black;
}

.remaining {
  font-size: 0.875rem;
  color: transparent;

  &.show {
    color: #888;
  }
}
</style>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { LocalTokenApi, AuthCodeResponse } from '@/auth/LocalTokenApi'
import { TokenManager } from '@/auth/TokenManager'
import { DateTime, Duration } from 'luxon'
import { useGroupAdminNotifications } from '@/gp/GroupAdminNotifications' 

const props = defineProps<{
  alwaysShowTimer?: boolean
}>()

const emit = defineEmits([
  'authCodeUsed',
])

defineExpose({
  ensureRequested,
})

const gpNotify = useGroupAdminNotifications()

const syncAppClientId = "Cofnxru6I9" // public client id for sync app

const expiringDuration = 9 * 60 * 1000 // 9 minutes

const codeRef = ref<AuthCodeResponse>()
const remainingTimeRef = ref(0)
let timerHandle = 0

const isCodeExpiredRef = computed(() => codeRef.value && remainingTimeRef.value <= 0)
const showTimerRef = computed(() => codeRef.value && remainingTimeRef.value > 0 && (props.alwaysShowTimer || remainingTimeRef.value < expiringDuration))
const remainingTextRef = computed(() => {
  const d = Duration.fromMillis(remainingTimeRef.value)
  return d.as('minutes') >= 1 ? `${Math.ceil(d.as('minutes'))} min` : d.toFormat('m:ss')
})

function ensureRequested() {
  if (!codeRef.value) {
    refreshCode()
  }
}

function authCodeUsed() {
  codeRef.value = undefined
  if (timerHandle) {
    clearInterval(timerHandle)
  }
  emit('authCodeUsed')
}

onMounted(() => {
  gpNotify.onAuthCodeUsed(args => {
    if (args.clientId == syncAppClientId && args.notificationId == codeRef.value?.notificationId) {
      authCodeUsed();
    }
  })
})

onUnmounted(() => {
  if (timerHandle) {
    clearInterval(timerHandle)
  }
})

async function refreshCode() {
  const token = await TokenManager.getAccessTokenAsync()
  if (token) {
    codeRef.value = await LocalTokenApi.getLocalAuthCodeAsync(token, syncAppClientId)
    updateRemainingTime()

    timerHandle = setInterval(() => {
      updateRemainingTime()      
      if (remainingTimeRef.value == 0) {
        clearInterval(timerHandle)
      }
    }, 1000)
  }
}

function updateRemainingTime() {
  remainingTimeRef.value = Math.max(0, codeRef.value!.expirationDate.diff(DateTime.utc()).milliseconds)
}

</script>
