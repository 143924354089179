<template>
  <div class="research-tool-item">
    <img :src="toolInfoRef?.icon" :alt="toolInfoRef?.name"/>
    {{ toolInfoRef?.name }}
  </div>
</template>

<style lang="scss" scoped>
.research-tool-item {
  width: 90px;
  height: 90px;
  padding: 8px 4px;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: center;
  text-align: center;
  font-size: 0.75rem;
  color: #888;

  img {
    width: 40px;
    max-height: 40px;
  }
}
</style>

<script setup lang="ts">
import { researchToolInfos } from '@/rd/ResearchTools'
import { computed } from 'vue';

const props = defineProps<{
  toolId: string
}>()

const toolInfoRef = computed(() => researchToolInfos.find(t => t.id == props.toolId))

</script>
