<template>
  <div class="research-group-page">
    <div class="page-title">
      <img :src="logoRef">
      <div class="page-title-text-area">
        <h1 class="with-subhead">{{ dataGroupRef?.name }}</h1>
        <p class="subhead">{{ workspaceRef?.workspaceTypeInfo?.description }}</p>
      </div>
    </div>
    <div>
      <ul ref="tabsElemRef" class="nav nav-pills research-group-tabs" role="tablist">
        <li class="nav-item">
          <button type="button" id="generalTab" class="nav-link active" data-bs-toggle="tab" data-bs-target="#generalPane" role="tab" aria-controls="generalPane" aria-selected="false">
            General
          </button>
        </li>
        <li class="nav-item">
          <button type="button" id="contentsTab" class="nav-link" data-bs-toggle="tab" data-bs-target="#contentsPane" role="tab" aria-controls="contentsPane" aria-selected="true">
            Contents
          </button>
        </li>
        <li class="nav-item">
          <button type="button" id="sharingTab" class="nav-link" data-bs-toggle="tab" data-bs-target="#sharingPane" role="tab" aria-controls="sharingPane" aria-selected="true">
            Sharing
          </button>
        </li>
      </ul>
    </div>
    <div class="tab-content content-area">
      <section id="generalPane" class="tab-pane active" role="tabpanel" aria-labelled-by="generalTab" tab-index="0">
        <div class="general-content">
          <div>
            <label class="form-label">
              Display name
              <button type="button" v-if="canEditRef && !isEditing" class="btn btn-inline btn-link inline-action" @click="edit">
                edit
              </button>
            </label>
            <input type="text" v-if="isEditing" id="nameInput" class="form-control"
              :readonly="!canEditValues"
              v-model="editDataGroup.name"/>
            <div v-else class="input-value">{{ dataGroupRef?.name }}</div>
          </div>
          <div>
            <label class="form-label">
              Description
              <button type="button" v-if="canEditRef && !isEditing" class="btn btn-inline btn-link inline-action" @click="edit">
                edit
              </button>
            </label>
            <input type="text" v-if="isEditing" id="descriptionInput" class="form-control" 
              :readonly="!canEditValues" 
              v-model="editDataGroup.description"/>
            <div v-else class="input-value">
              <span v-if="dataGroupRef?.description">{{ dataGroupRef?.description }}</span>
              <span v-else class="none"></span>
            </div>
          </div>
          <div v-if="isEditing" class="actions">
            <button type="submit" class="btn btn-primary btn-sm" :disabled="!canSave" @click="save">{{saveText}}</button>
            <button type="button" class="btn btn-outline-primary btn-sm" :disabled="!canCancel" @click="cancel">Cancel</button>
          </div>
          <div class="form-item">
            <label class="form-label">Owner</label>
            <UserItem :user-id="workspaceRef?.ownerId"></UserItem>
          </div>
          <div>
            <label class="form-label">Last synced</label>
            <div class="input-value">
              {{ DateTimeUtil.toUserActionDate(lastSyncRef?.createdDate) }} <span class="date-rel">({{ DateTimeUtil.toRelativeDate(lastSyncRef?.createdDate) }})</span>
            </div>
          </div>
          <div v-if="canEditRef" class="form-item restricted">
            <label class="form-label">File location</label>
            <CompactItem class="computer-item">
              <template #icon>
                <MonitorIcon size="2x"></MonitorIcon>
              </template>
              <template #title>
                {{ workspaceRef?.clientDevice.name }}
                <span v-if="workspaceRef?.clientDevice.model" class="model">({{ workspaceRef?.clientDevice.model }})</span>
              </template>
              <template #subtitle>
                {{ workspaceRef?.dataFilePath }}
              </template>
            </CompactItem>
            <div class="input-help"><LockIcon></LockIcon> This information is only visible to the tree owner.</div>
          </div>
          <div class="actions">
            <button type="button" v-if="canDeleteRef" class="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#deleteGroupModal" :disabled="!dataGroupRef">
              <LockIcon></LockIcon> Delete online tree
            </button>
          </div>
        </div>
      </section>
      <section id="contentsPane" class="tab-pane" role="tabpanel" aria-labelled-by="contentsTab" tab-index="0">
        <div class="contents-content">
          <div>
            <label class="form-label">People</label>
            <div>{{ statsRef?.personCount.toLocaleString() }}</div>
          </div>
          <div>
            <label class="form-label">Families</label>
            <div>{{ statsRef?.familyCount.toLocaleString() }}</div>
          </div>
        </div>
      </section>
      <section id="sharingPane" class="tab-pane" role="tabpanel" aria-labelled-by="sharingTab" tab-index="0">
        <div class="tab-subhead">
          Sharing a tree allows members of the group to explore the tree together.
          Sharing a tree does not allow anyone to edit it.
        </div>
        <h5>
          Shared with
        </h5>
        <ul class="list-unstyled related-list">
          <li v-if="familyGroupMembersRef.length == 0" class="empty-list">
            None
          </li>
          <li v-for="dm in familyGroupMembersRef" :key="dm.id">
            <FamilyGroupItem :family-group-id="dm.principalId"></FamilyGroupItem>
            <div class="item-actions">
              <button type="button" v-if="canEditSharingRef" class="btn btn-inline btn-link" @click="onRemoveFamilyGroup(dm)">
                Remove
              </button>
            </div>
          </li>
        </ul>
        <h5>
          Sharing requests
        </h5>
        <div class="area-options">
          <label class="form-label">
            <input type="checkbox" v-model="showClosedRequestsRef" class="form-check-input">&nbsp;
            Show approved and expired
          </label>
        </div>
        <ul class="list-unstyled related-list">
          <li v-if="dataGroupInvitesRef.length == 0" class="empty-list">
            <template v-if="showClosedRequestsRef">
              No requests
            </template>
            <template v-else>
              No pending requests
            </template>
          </li>
          <li v-for="inv in dataGroupInvitesRef" :key="inv.id">
            <FamilyGroupItem 
              :data-group-invite-id="inv.id" 
              select-mode 
              @click="onSelectDataGroupInvite(inv.id!)">
            </FamilyGroupItem>
          </li>
        </ul>
        <div class="actions">
          <button type="button" v-if="canEditSharingRef" class="btn btn-primary" @click="onShare">
            <PlusIcon size="1.2x" class="mb-1"/> Share this tree
          </button>
        </div>
      </section>
    </div>
    <DataGroupShareModal ref="dataGroupShareModalRef"
      @request-share="onRequestShare">
    </DataGroupShareModal>
    <DataGroupInviteModal ref="dataGroupInviteModalRef"></DataGroupInviteModal>
    <DataGroupMemberRemoveModal ref="removeFamilyGroupModalRef"></DataGroupMemberRemoveModal>
    <Modal id="deleteGroupModal" ref="deleteGroupModalRef" ok-style="danger" @ok="deleteGroup">
      <template #title>Delete this tree?</template>
      <div>
        <p class="mb-1">This will:</p>
        <ul>
          <li>Stop sharing the tree with other users and groups</li>
          <li>Permanently delete the online copy of your tree</li>
        </ul>
      </div>
      <template #okText>Confirm delete</template>
      <template #busyText>Deleting....</template>
    </Modal>
  </div>
</template>

<style lang="scss" scoped>
.research-group-page {
  .page-title img {
    padding-top: 8px;
  }

  .research-group-tabs {
    max-width: 800px;
  }

  .tab-content {
    max-width: 800px;
  }

  .general-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .contents-content {
    display: flex;
    gap: 2.5rem;
  }

  .form-item {
    margin-bottom: 0.5rem;

    &.restricted {
      padding: 0.5rem 1rem 1rem;
      background-color: #eee;
    }
  }

  .inline-action {
    margin-left: 0.5rem;
    font-size: 0.75rem;
  }

  .input-value .none::before {
    content: "(none)";
    font-size: 0.875rem;
    color: #bbb;
  }

  .computer-item {
    .model {
      font-size: 0.875rem;
      color: #888;
    }

    svg {
      color: #888;
    }
  }

  .tab-subhead {
    max-width: 600px;
    margin-bottom: 1rem;
    font-size: 0.875rem;
    color: #888;
  }

  section h5 {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: normal;
  }

  .area-options {
    margin-bottom: 0.5rem;
  }

  .related-list {
    margin-left: 1rem;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .item-actions {
      margin-top: 4px;
      margin-left: calc(44px + 0.5rem); // from CompactItem
      font-size: 0.875rem;
    }
  }

  .date-rel {
    font-size: 0.875rem;
    color: #888;
  }

  .input-help {
    font-size: 0.75rem;
    color: #888;

    svg {
      color: #888;
    }
  }

  .actions {
    margin-top: 1.5rem;
    display: flex;
    gap: 0.5rem;
  }
}
</style>

<script setup lang="ts">
import { computed, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { usePageTitle } from '@/util/AppUtil'
import { computedAsync} from '@vueuse/core'
import { useWorkspaceStore } from '@/rd/WorkspaceStore'
import { useSyncStore } from '@/rd/SyncStore'
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { useDataGroupMemberStore } from '@/gp/DataGroupMemberStore'
import { useDataGroupInviteStore } from '@/gp/DataGroupInviteStore'
import { ItemPermissions, DataGroup, PrincipalType, InvitationResult, DataGroupMember } from '@/gp/GroupAdminModel'
import { MonitorIcon, LockIcon, PlusIcon } from '@zhuowenli/vue-feather-icons'
import { LoadMode } from '@/util/AsyncData'
import { DateTimeUtil } from '@/util/LuxonUtil'
import { useEditMode } from '@/util/EditMode'
import { PatchChange, PatchChangeValue } from '@/util/Api'
import { useTabsTelemetry } from '@/util/TabTelemetry'
import { researchGroupLogos } from './ResearchGroupLogos'
import { isDefined } from '@/util/TypeScriptUtil'
import UserItem from '@/manage/users/UserItem.vue'
import CompactItem from '@/util/CompactItem.vue'
import Modal from '@/util/Modal.vue'
import FamilyGroupItem from '@/manage/familygroups/FamilyGroupItem.vue'
import DataGroupShareModal from '@/manage/datagroups/DataGroupShareModal.vue'
import DataGroupInviteModal from '@/manage/datagroups/DataGroupInviteModal.vue'
import DataGroupMemberRemoveModal from '@/manage/datagroups/DataGroupMemberRemoveModal.vue'

const props = defineProps({
  dataGroupId: String
})

const editDataGroup = reactive(new DataGroup())
const editProperties = [
  'name',
  'description',
] as (keyof DataGroup)[]

const showClosedRequestsRef = ref(false)
const tabsElemRef = ref<HTMLUListElement>()
const dataGroupShareModalRef = ref<InstanceType<typeof DataGroupShareModal>>()
const dataGroupInviteModalRef = ref<InstanceType<typeof DataGroupInviteModal>>()
const removeFamilyGroupModalRef = ref<InstanceType<typeof DataGroupMemberRemoveModal>>()
const deleteGroupModalRef = ref<InstanceType<typeof Modal>>()

const workspaceStore = useWorkspaceStore()
const dataGroupStore = useDataGroupStore()
const dataGroupMemberStore = useDataGroupMemberStore()
const dataGroupInviteStore = useDataGroupInviteStore()
const syncStore = useSyncStore()
const router = useRouter()

const dataGroupRef = computed(() => dataGroupStore.getAsyncGroup(props.dataGroupId, LoadMode.EnsureLoaded)?.data)
const workspaceRef = computed(() => workspaceStore.getAsyncWorkspacesForGroups([props.dataGroupId], LoadMode.EnsureLoaded).at(0)?.data)
const logoRef = computed(() => researchGroupLogos[workspaceRef.value?.dataFileBaseType ?? 'TestData'])
const lastSyncRef = computed(() => syncStore.getAsyncSync(workspaceRef.value?.lastSyncId, LoadMode.EnsureLoaded)?.data)

const statsRef = computedAsync(
  async () => (await workspaceStore.getStatisticsAsync([workspaceRef.value?.id])).at(0))

const familyGroupMembersRef = computed(() => {
  return dataGroupMemberStore.getMembersForDataGroups([props.dataGroupId], LoadMode.EnsureLoaded)
    .filter(m => m.principalType == PrincipalType.FamilyGroup)
})
const dataGroupInvitesRef = computed(() => {
  const inviteIds = dataGroupInviteStore.getAsyncKeyListForDataGroup(props.dataGroupId, LoadMode.EnsureLoaded)?.data?.keys ?? []
  return dataGroupInviteStore.getAsyncInviteList(inviteIds) // loaded with key list
    .map(a => a.data).filter(isDefined)
    .filter(inv => showClosedRequestsRef.value || inv.result == InvitationResult.Pending)
})

const permissionsRef = computedAsync(
  async () => (await dataGroupStore.getAsyncPermissions(props.dataGroupId, LoadMode.EnsureLoaded)?.whenLoadCompleted) || ItemPermissions.None,
  ItemPermissions.None)

const canEditRef = computed(() => (permissionsRef.value & ItemPermissions.Modify) > 0)
const canEditSharingRef = computed(() => (permissionsRef.value & ItemPermissions.ModifyPermissions) > 0)
const canDeleteRef = computed(() => (permissionsRef.value & ItemPermissions.Owner) > 0)
const allowSaveRef = computed(() => !!editDataGroup.name)

usePageTitle("Tree", () => dataGroupRef.value?.name)
useTabsTelemetry(tabsElemRef)

const { isEditing, canEdit, canEditValues, edit, saveText, canSave, saveChanges, canCancel, cancel } = 
  useEditMode(dataGroupRef, editDataGroup, editProperties, canEditRef, allowSaveRef)

function save(e: Event) {
  e.preventDefault()
  saveChanges(async () => {
    if (!dataGroupRef.value)
      return

    const changes: PatchChange[] = []
    for (const key of editProperties) {
      if (editDataGroup[key] != dataGroupRef.value[key]) {
        changes.push(new PatchChange(`/${key}`, editDataGroup[key] as PatchChangeValue))
      }
    }

    if (changes.length) {
      await dataGroupStore.patchAsync(dataGroupRef.value!.id!, changes)
    }
  })
}

function onSelectDataGroupInvite(inviteId: string) {
  dataGroupInviteModalRef.value?.openEdit(inviteId)
}

function onShare() {
  const familyGroupIds = familyGroupMembersRef.value.map(m => m.principalId!)
  familyGroupIds.push(...dataGroupInvitesRef.value.map(inv => inv.principalId!))
  dataGroupShareModalRef.value?.open(props.dataGroupId!, familyGroupIds)
}

function onRequestShare(familyGroupId: string) {
  dataGroupInviteModalRef.value?.openNew(props.dataGroupId!, familyGroupId)
}

function onRemoveFamilyGroup(dm: DataGroupMember) {
  removeFamilyGroupModalRef.value?.open(dm)
}

async function deleteGroup() {
  await dataGroupStore.deleteAsync(dataGroupRef.value!.id!)

  deleteGroupModalRef.value!.close() // get rid of static backdrop
  router.push('/trees')
}
</script>
