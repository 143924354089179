<template>
  <Modal ref="modalRef" info @ok="doneEditing">
    <template #title>Research tools</template>
    <p class="help-text">
      Select the tools you use most in your research.
    </p>
    <div class="editor-container">
      <ResearchToolsEditor
        :model-value="props.modelValue"
        @update:model-value="v => emit('update:modelValue', v)"/>
    </div>
    <template #okText>Done</template>
  </Modal>
</template>

<style lang="scss" scoped>
.help-text {
  margin: 0;
}

.editor-container {
  max-height: 400px;
  overflow-y: auto;
}
</style>

<script setup lang="ts">
import { ref } from 'vue'
import Modal from '@/util/Modal.vue'
import ResearchToolsEditor from './ResearchToolsEditor.vue';

const props = defineProps<{
  modelValue?: string[]
}>()

const emit = defineEmits([
  'update:modelValue'
])

const modalRef = ref<InstanceType<typeof Modal>>()

function doneEditing() {
  modalRef.value?.close()
}
</script>
