<template>
  <div class="contact-info">
    <form v-if="userRef">
      <h4 class="section-edit">
        <span>Contact info</span>
        <EditActions :edit-mode="{ canEdit, isEditing, canSave, saveText, canCancel }"
          @edit="edit" @save="save" @cancel="cancel" />
      </h4>
      <div class="row input-row">
        <div class="col-5 col-lg-3">
          <label for="givenNameInput" class="form-label">Given name</label>
          <input type="text" class="form-control" id="givenNameInput" v-if="isEditing" :readonly="!canEditValues" v-model="editUser.givenName"/>
          <div class="input-value" v-show="!isEditing">{{userRef.givenName}}</div>
        </div>
        <div class="col-5 col-lg-3">
          <label for="familyNameInput" class="form-label">Surname</label>
          <input type="text" class="form-control" id="familyNameInput" v-if="isEditing" :readonly="!canEditValues" v-model="editUser.surname"/>
          <div class="input-value" v-show="!isEditing">{{userRef.surname}}</div>
        </div>
        <div :class="privacyColClassRef">
          <PrivacyLevelSelect id="namePrivacySelect" v-if="isEditing" :disabled="!canEditValues" v-model="editUser.namePrivacy"></PrivacyLevelSelect>
          <PrivacyLevelIndicator :value="userRef.namePrivacy" v-show="!isEditing"></PrivacyLevelIndicator>
        </div>
      </div>
      <div class="row input-row">
        <div class="col-sm-3">
          <label for="genderSelect" class="form-label">Gender</label>
          <select class="form-select" id="genderSelect" v-if="isEditing" :disabled="!canEditValues" v-model="editUser.gender">
            <option></option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
          <div class="input-value" v-show="!isEditing">
            {{userRef.gender}}
          </div>
        </div>
      </div>
      <div class="row input-row">
        <div class="col-10 col-sm-6">
          <label for="emailInput" class="form-label">Contact email</label>
          <input type="text" class="form-control" id="emailInput" v-if="isEditing" :readonly="!canEditValues" v-model="editUser.email"/>
          <div class="input-value" v-show="!isEditing">{{userRef.email}}</div>
        </div>
        <div :class="privacyColClassRef">
          <PrivacyLevelSelect id="emailPrivacySelect" v-if="isEditing" :disabled="!canEditValues" v-model="editUser.emailPrivacy"></PrivacyLevelSelect>
          <PrivacyLevelIndicator :value="userRef.emailPrivacy" v-show="!isEditing"></PrivacyLevelIndicator>
        </div>
      </div>
      <div class="row input-row">
        <div class="col-10 col-sm-6">
          <label for="altEmailInput" class="form-label">Alternate email</label>
          <input type="text" class="form-control" id="altEmailInput" v-if="isEditing" :readonly="!canEditValues" v-model="editUser.email2"/>
          <div class="input-value" v-show="!isEditing">{{userRef.email2 || '(none)'}}</div>
        </div>
        <div :class="privacyColClassRef">
          <PrivacyLevelSelect id="email2PrivacySelect" v-if="isEditing" :disabled="!canEditValues" v-model="editUser.email2Privacy"></PrivacyLevelSelect>
          <PrivacyLevelIndicator :value="userRef.email2Privacy" v-show="!isEditing"></PrivacyLevelIndicator>
        </div>
      </div>
      <div class="row input-row">
        <div class="col-10 col-sm-6">
          <label for="mobileNumberInput" class="form-label">Mobile number</label>
          <input type="text" class="form-control" id="mobileNumberInput" v-if="isEditing" :readonly="!canEditValues" v-model="editUser.mobileNumber"/>
          <div class="input-value" v-show="!isEditing">{{userRef.mobileNumber || '(none)'}}</div>
        </div>
        <div :class="privacyColClassRef">
          <PrivacyLevelSelect id="mobileNumberPrivacySelect" v-if="isEditing" :disabled="!canEditValues" v-model="editUser.mobileNumberPrivacy"></PrivacyLevelSelect>
          <PrivacyLevelIndicator :value="userRef.mobileNumberPrivacy" v-show="!isEditing"></PrivacyLevelIndicator>
        </div>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
.row.input-row.private {
  margin-bottom: 0;
  padding-bottom: 1rem;
  background-color: #f8f8f8;
}
</style>

<script setup lang="ts">
import { reactive, computed } from "vue"
import { useUserStore } from '@/gp/UserStore'
import { ItemPermissions, User } from '@/gp/GroupAdminModel'
import { useEditMode } from '@/util/EditMode'
import { PatchChange, PatchChangeValue } from '@/util/Api'
import { TokenManager } from "@/auth/TokenManager"
import PrivacyLevelIndicator from '@/account/PrivacyLevelIndicator.vue'
import PrivacyLevelSelect from '@/account/PrivacyLevelSelect.vue'
import { LoadMode } from "@/util/AsyncData"
import EditActions from "@/util/EditActions.vue"

const props = defineProps<{
  userId: string
}>()

const userStore = useUserStore()

const userIdRef = computed(() => props.userId ?? TokenManager.userId!)
const userRef = computed(() => userStore.getAsyncUser(userIdRef.value)?.data)
const editUser = reactive(new User())
const editProperties = [
  'givenName',
  'surname', 
  'namePrivacy',
  'gender',
  'email',
  'emailPrivacy',
  'email2',
  'email2Privacy',
  'mobileNumber',
  'mobileNumberPrivacy',
] as (keyof User)[]

const allowEdit = computed(() => {
  const perms = userStore.getAsyncPermissions(userIdRef.value, LoadMode.EnsureLoaded)?.data ?? ItemPermissions.None
  return (perms & ItemPermissions.Modify) > 0
})
const allowSave = computed(() => !!editUser.givenName || !!editUser.surname)

const { canEdit, canEditValues, isEditing, edit, canSave, saveText, saveChanges, canCancel, cancel } = 
  useEditMode(userRef, editUser, editProperties, allowEdit, allowSave)

const privacyColClassRef = computed(() => isEditing.value ? 'col-2' : 'col-1')

function save() {
  saveChanges(async () => {
    const changes: PatchChange[] = []
    if (!userRef.value)
      return
    
    for (const key of editProperties) {
      if (editUser[key] != userRef.value[key]) {
        changes.push(new PatchChange(`/${key}`, editUser[key] as PatchChangeValue))
      }
    }

    if (changes.length) {
      await userStore.patchAsync(userIdRef.value!, changes)
    }
  })
}

</script>
