<template>
  <div class="content-item">
    <img v-if="contentRef?.isImage" :src="contentRef?.galleryUrl"/>
    <img v-else-if="contentRef?.icon" :src="contentRef.icon" class="file"/>
    <div class="caption">
      {{ contentRef?.caption}}
    </div>
  </div>
</template>

<style lang="scss" scope>
.content-item {
  display: grid;
  justify-items: center;
  min-width: 150px;
  width: min-content;
  grid-template-rows: 160px 3rem;
}

img {
  max-height: 160px;
  width: auto;
  
  &.file {
    padding: 2rem;
  }
}

.caption {
  grid-row: 2;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  text-align: center;
  overflow: hidden;
  overflow-wrap: normal;
  text-overflow: ellipsis;
  font-size: 0.875rem;
  color: #888;
}
</style>

<script lang="ts" setup>
import { computed } from 'vue'
import { useContentStore } from '@/rd/ContentStore'
import { useContentTagStore } from '@/rd/ContentTagStore'

const props = defineProps<{
  contentId?: string
  contentTagId?: string
}>()

const contentStore = useContentStore()
const contentTagStore = useContentTagStore()

// https://github.com/microsoft/fluentui/blob/master/packages/react-file-type-icons/src/initializeFileTypeIcons.tsx
// NOTE: https://res-1.cdn.office.net/files/fabric-cdn-prod_20230815.002/assets/item-types/48/pdf.svg

const contentRef = computed(() => contentStore.getLoadedContents([props.contentId]).at(0))
const contentTagRef = computed(() => contentTagStore.getLoadedTags([props.contentTagId]).at(0))

</script>
