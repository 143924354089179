<template>
  <div class="modal" ref="modalRef" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">
            <img src="@/assets/icons/mail_gray_60.png"/>
            Invitation
          </h3>
          <button type="button" class="btn-close" :disabled="busyRef" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="invite-header">
            <div v-if="!isNewRef" class="invite-header-item">
              <label class="form-label">From</label>
              <div>{{ senderUserRef?.displayName }}</div>
            </div>
            <div class="invite-header-item">
              <label class="form-label">To</label>
              <div v-if="recipientUserRef">
                {{ recipientUserRef?.displayName }}
                <span v-if="recipientUserRef.email">({{ recipientUserRef.email }})</span>
              </div>
              <div v-else-if="isNewRef">
                {{ recipientProfileRef?.displayName }}
              </div>
              <div v-else>
                {{ recipientProfileRef?.displayName }}
                <span v-if="recipientEmailRef">({{ recipientEmailRef }})</span>
              </div>
            </div>
            <div v-if="isNewRef && !recipientUserRef" class="invite-header-item">
              <label class="form-label">Address</label>
              <div>
                <FamilyGroupInviteRecipient
                  ref="recipientInputRef"
                  :profile-id="recipientProfileRef?.id"
                  :use-contacts="contactStore.contactProviderId">
                </FamilyGroupInviteRecipient>
                <ContactProviderSelector></ContactProviderSelector>
              </div>
            </div>
            <div v-if="!isNewRef" class="invite-header-item">
              <label class="form-label">Sent</label>
              <div class="invite-date">
                {{ DateTimeUtil.toUserActionDate(inviteRef?.lastSentDate) }}
                ({{ DateTimeUtil.toRelativePastDate(inviteRef?.lastSentDate) }})
              </div>
            </div>
          </div>
          <div v-if="showResentAlertRef" class="alert alert-success resent-alert">
            Invitation resent.
          </div>
          <div v-if="inviteRef?.result == InvitationResult.Cancelled" class="alert alert-warning cancelled-alert">
            This invitation was cancelled on {{ DateTimeUtil.toUserActionDate(inviteRef?.closedDate) }}.
          </div>
          <div>
            <label class="form-label">
              Invite this person to:
            </label>
          </div>
          <div class="invite-group">
            <label class="form-label">
              <input type="checkbox" class="form-check-input" checked disabled>
              Join this group
            </label>
            <FamilyGroupItem :family-group-id="inviteRef?.familyGroupId"></FamilyGroupItem>
          </div>
          <div v-if="showInviteProfileRef" class="invite-profile">
            <label class="form-label">
              <input type="checkbox" class="form-check-input" checked disabled>
              Take control of this profile
            </label>
            <PersonCard v-if="isNewRef" :person-id="recipientProfileRef?.id" profile layout="small" class="profile-card"></PersonCard>
            <FamilyGroupInviteProfile v-else :invite-id="inviteRef?.id" class="profile-card"></FamilyGroupInviteProfile>
          </div>
          <div v-if="inviteRef && (isRecruiterRef || canSetRecruiterRef)" class="invite-role">
            <label class="form-label">
              <input type="checkbox" v-model="isRecruiterRef" :disabled="!isNewRef" class="form-check-input">
              Invite others to join this group
            </label>
          </div>
          <div v-if="inviteRef && (isNewRef || inviteRef.senderMessage || showResendRef)" class="message">
            <label class="form-label">Message</label>
            <textarea v-if="isNewRef || enableResendRef" v-model="inviteRef.senderMessage" class="form-control"></textarea>
            <div v-else>{{ inviteRef.senderMessage }}</div>
          </div>
          <div v-if="showResendRef && DateTime.utc() < nextResendDateRef!" class="alert alert-primary">
            This invitation can be resent after {{ DateTimeUtil.toUserActionDate(nextResendDateRef) }}
          </div>
          <div v-if="isExpiredRef && !isForSelfRef" class="alert alert-primary">
            The link in this invitation has expired.
          </div>
          <div v-if="isAcceptedRef" class="alert alert-success">
            This invitation was accepted on
            {{ DateTimeUtil.toUserActionDate(inviteRef?.closedDate) }}
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" v-if="showCancelRef"
            class="btn btn-outline-danger secondary-action"
            :disabled="!enableCancelRef"
            @click="cancelInvite">
            <span v-if="busyRef"><Spinner></Spinner> Cancelling...</span>
            <span v-else>Cancel invitation</span>
          </button>
          <button type="button" v-if="showSendRef"
            class="btn btn-primary"
            :disabled="!enableSendRef"
            @click="sendInvite">
            <span v-if="busyRef"><Spinner></Spinner> Sending...</span>
            <span v-else><SendIcon></SendIcon> Send</span>
          </button>
          <button type="button" v-if="showResendRef"
            class="btn btn-outline-primary"
            :disabled="!enableResendRef"
            @click="resendInvite">
            <span v-if="busyRef"><Spinner></Spinner> Resending...</span>
            <span v-else><SendIcon></SendIcon> Resend</span>
          </button>
          <button type="button" v-if="canAcceptRef"
            class="btn btn-primary"
            :disabled="busyRef"
            @click="acceptInvite">
            <span v-if="busyRef"><Spinner></Spinner> Accepting...</span>
            <span v-else>Accept</span>
          </button>
          <button type="button" v-if="!showSendRef"
            class="btn"
            :class="{ 
              ['btn-primary']: !canAcceptRef,
              ['btn-outline-primary']: canAcceptRef,
            }" 
            :disabled="busyRef" 
            data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-dialog {
  max-width: 600px;
}

.modal-title {
  position: relative;
  padding-left: 4rem;

  img {
    position: absolute;
    width: 3rem;
    height: 3rem;
    top: 0;
    left: 0;
    filter: brightness(0) saturate(100%) invert(63%) sepia(76%) saturate(5097%) hue-rotate(184deg) brightness(100%) contrast(94%);
  }
}

.modal-body {
  padding: 0.5rem 2rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .alert {
    margin: 0;
  }
 
  .invite-header {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  .invite-header-item {
    display: grid;
    grid-template-columns: 80px auto;

    .form-label {
      margin-top: 3px;
      font-size: 0.875rem;
    }
  }

  .invite-date {
    font-size: 0.875rem;
    color: #888;
  }

  label.form-label {
    color: #666;
  }

  input[type="checkbox"] {
    margin-right: 0.25rem;
  }

  .family-group-item {
    margin-left: 1.5rem;
    font-size: 0.875rem;
  }

  .profile-card {
    margin-top: 0.5rem;
    margin-left: 1.5rem;
  }

  .message {
    font-size: 0.875rem;
  }

  .alert {
    font-size: 0.875rem;
  }
}

.modal-footer {
  .secondary-action {
    margin-left: 1rem;
    margin-right: auto;
  }
}
</style>

<script lang="ts" setup>
import { computed, nextTick, ref } from 'vue'
import { DateTimeUtil } from '@/util/LuxonUtil'
import { FamilyGroupInvite, GroupMemberRole, InvitationResult, ItemPermissions, UserGender } from '@/gp/GroupAdminModel'
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { useFamilyGroupInviteStore } from '@/gp/FamilyGroupInviteStore'
import { useFamilyGroupStore } from '@/gp/FamilyGroupStore'
import { useUserStore } from '@/gp/UserStore'
import { usePersonStore } from '@/rd/PersonStore'
import { useManageStore } from "@/manage/ManageStores"
import { useContactStore } from "@/manage/contacts/ContactStore"
import { LoadMode } from '@/util/AsyncData'
import { AsyncUtil } from '@/util/AsyncUtil'
import { CompositeId } from '@/rd/CompositeId'
import { TokenManager } from '@/auth/TokenManager'
import { Modal as BsModal } from 'bootstrap'
import { SendIcon } from '@zhuowenli/vue-feather-icons'
import { DateTime } from 'luxon'
import { PersonName } from '@/rd/ResearchDataModel'
import FamilyGroupInviteRecipient from './FamilyGroupInviteRecipient.vue'
import ContactProviderSelector from '@/manage/contacts/ContactProviderSelector.vue'
import FamilyGroupItem from './FamilyGroupItem.vue'
import FamilyGroupInviteProfile from './FamilyGroupInviteProfile.vue'
import PersonCard from '@/manage/PersonCard.vue'
import Spinner from '@/util/Spinner.vue'
import FamilyGroupRoleOption from './FamilyGroupRoleOption.vue'

defineExpose({
  openNew,
  openEdit,
  close,
})

const inviteStore = useFamilyGroupInviteStore()
const familyGroupStore = useFamilyGroupStore()
const dataGroupStore = useDataGroupStore()
const personStore = usePersonStore()
const userStore = useUserStore()
const manageStore = useManageStore()
const contactStore = useContactStore()

const inviteRef = ref<FamilyGroupInvite>()
const recipientEmailRef = ref<string>()
const recipientInputRef = ref<InstanceType<typeof FamilyGroupInviteRecipient>>()
const isRecruiterRef = ref(false)
const busyRef = ref(false)
const modalRef = ref<HTMLElement>()

const permissionsRef = computed(() => isNewRef.value ? ItemPermissions.Owner : 
  inviteStore.getAsyncPermissions(inviteRef.value?.id, LoadMode.EnsureLoaded)?.data ?? ItemPermissions.None)
const senderUserRef = computed(() => userStore.getAsyncUser(inviteRef.value?.senderUserId)?.data) // loaded by parent
const recipientProfileRef = computed(() => {
  const profileGroup = dataGroupStore.getAsyncGroup(inviteRef.value?.profileDataGroupId)?.data // loaded by parent
  return personStore.getAsyncPerson(profileGroup?.startItemId, LoadMode.EnsureLoaded)?.data
})
const recipientUserRef = computed(() => {
  const profileGroup = dataGroupStore.getAsyncGroup(inviteRef.value?.profileDataGroupId)?.data // loaded by parent
  const profileOwner = userStore.getAsyncUser(profileGroup?.ownerId)?.data // loaded by parent (if exists)
  return (profileGroup?.id == profileOwner?.profileDataGroupId) ? profileOwner : undefined
})

const isNewRef = computed(() => !inviteRef.value?.id)
const isForSelfRef = computed(() => inviteRef.value?.recipientUserId == TokenManager.userId)
const isAcceptedRef = computed(() => inviteRef.value?.result === InvitationResult.Accepted)
const isExpiredRef = computed(() => (inviteRef.value?.expirationDate?.diffNow().valueOf() ?? 0) < 0)

const showInviteProfileRef = computed(() => inviteRef.value?.profileDataGroupId && inviteRef.value?.profileDataGroupId != recipientUserRef.value?.profileDataGroupId)

const familyGroupPermissionsRef = computed(() => 
  familyGroupStore.getAsyncPermissions(inviteRef.value?.familyGroupId, LoadMode.EnsureLoaded)?.data ?? ItemPermissions.None)
const canSetRecruiterRef = computed(() => familyGroupPermissionsRef.value & ItemPermissions.Owner)

const showSendRef = computed(() => isNewRef.value)
const isValidRecipientRef = computed(() => !!recipientUserRef.value || !!recipientInputRef.value?.validate())
const enableSendRef = computed(() => showSendRef.value && !busyRef.value && isValidRecipientRef.value)

const MinResendInterval = { days: 3 }
const nextResendDateRef = computed(() => inviteRef.value?.lastSentDate?.plus(MinResendInterval))
const showResendRef = computed(() => inviteRef.value && !isNewRef.value && !busyRef.value && !isAcceptedRef.value &&
  inviteRef.value.senderUserId == TokenManager.userId && DateTime.utc() > nextResendDateRef.value!)
const enableResendRef = computed(() => showResendRef.value && !busyRef.value)
const showResentAlertRef = ref(false)

const showCancelRef = computed(() => !isNewRef.value && inviteRef.value?.result == InvitationResult.Pending && 
  (permissionsRef.value & ItemPermissions.Owner) > 0)
const enableCancelRef = computed(() => showCancelRef.value && !busyRef.value)

const canAcceptRef = computed(() => inviteRef.value && isForSelfRef.value && inviteRef.value.result == InvitationResult.Pending)

async function openNew(familyGroupId: string, profileId: string) {
  const inv = new FamilyGroupInvite()
  inv.familyGroupId = familyGroupId
  inv.senderUserId = TokenManager.userId
  inv.profileDataGroupId = CompositeId.getGroupId(profileId)
  inviteRef.value = inv
  recipientEmailRef.value = manageStore.inviteAddresses.get(profileId!)
  isRecruiterRef.value = false

  resetInteractiveState()

  // HACK: Not sure why getInstance isn't sufficient here, but sometimes it returns null because apparently the BS Modal
  // hasn't been initialized yet. Calling getOrCreateInstance seems to fix it.
  BsModal.getOrCreateInstance(modalRef.value!).show()

  await nextTick()
  recipientInputRef.value?.focus()  
}

function openEdit(inviteId: string) {
  inviteRef.value = inviteStore.getAsyncInvite(inviteId)?.data // loaded by parent
  recipientEmailRef.value = inviteRef.value?.recipientEmail
  isRecruiterRef.value = inviteRef.value?.recipientRole == GroupMemberRole.Recruiter

  resetInteractiveState()
  
  // HACK: Not sure why getInstance isn't sufficient here, but sometimes it returns null because apparently the BS Modal
  // hasn't been initialized yet. Calling getOrCreateInstance seems to fix it.
  BsModal.getOrCreateInstance(modalRef.value!).show()
}

function resetInteractiveState() {
  busyRef.value = false
  showResentAlertRef.value = false
}

function close() {
  BsModal.getInstance(modalRef.value!)?.hide()
}

async function sendInvite() {
  const inv = inviteRef.value!
  inv.recipientUserId = recipientUserRef.value?.id
  inv.recipientEmail = manageStore.inviteAddresses.get(recipientProfileRef.value!.id!)
  const name = new PersonName(recipientProfileRef.value!.displayProperties.name)
  inv.recipientFirstName = name.given
  inv.recipientLastName = name.surname
  inv.recipientRole = isRecruiterRef.value ? GroupMemberRole.Recruiter : GroupMemberRole.Member
  inv.profileDisplayName = recipientProfileRef.value!.displayName
  inv.profileGender = recipientProfileRef.value!.displayProperties.gender as string as UserGender // same values

  busyRef.value = true
  await inviteStore.sendAsync(inv)
  close()
}

async function cancelInvite() {
  busyRef.value = true
  await inviteStore.cancelAsync(inviteRef.value!.id!)
  close()
}

async function resendInvite(e: Event) {
  busyRef.value = true
  const inviteId = inviteRef.value!.id! // capture this before the await

  await AsyncUtil.delay(500)
  await inviteStore.resendAsync(inviteRef.value!.id!, undefined, inviteRef.value!.senderMessage)

  busyRef.value = false
  showResentAlertRef.value = true
  // don't close
}

async function acceptInvite(e: Event) {
  busyRef.value = true
  await inviteStore.acceptAsync(inviteRef.value!.id!)
  close()
}
</script>