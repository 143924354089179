<template>
  <div class="research-group-list">
    <p class="help-text">
      <template v-if="hasGroupsRef">
        <template v-if="props.forUserProfile">These are the trees this user manages that are also visible to you.</template>
        <template v-else>Upload a genealogy file to share your family tree with others.</template>
      </template>
      <template v-else>
        <template v-if="props.forUserProfile">This user does not manage any trees that are visible to you.</template>
        <template v-else>You do not manage any trees, and no trees have been shared with you.</template>
      </template>
    </p>
    <ul class="list-unstyled group-list">
      <li v-for="rg in ownedResearchGroupsRef" :key="rg.id">
        <ResearchGroupCard :data-group-id="rg.id"></ResearchGroupCard>
      </li>
      <li v-for="rg in sharedResearchGroupsRef" :key="rg.id">
        <ResearchGroupCard :data-group-id="rg.id"></ResearchGroupCard>
      </li>
    </ul>
    <div class="actions-row">
      <router-link v-if="canAddRef" to="/learn/sync">{{ addTextRef }}</router-link>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.research-group-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h5 {
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 400;
  }

  .group-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;

  }

  &.show-all .group-list {
    margin-left: 1rem;
  }
  
  .actions-row {
    margin-top: 2rem;
    font-size: 0.875rem;
  }
}
</style>

<script setup lang="ts">
import { computed } from 'vue'
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { DataGroup, DataGroupType, GroupMemberRole, ItemPermissions } from '@/gp/GroupAdminModel'
import { LoadMode } from '@/util/AsyncData'
import ResearchGroupCard from './ResearchGroupCard.vue'
import { TokenManager } from '@/auth/TokenManager'
import { useDataGroupMemberStore } from '@/gp/DataGroupMemberStore'
import { useUserStore } from '@/gp/UserStore'
import { useFamilyGroupMemberStore } from '@/gp/FamilyGroupMemberStore'
import _ from 'lodash'

const props = defineProps<{
  userId?: string
  forUserProfile?: boolean
}>()

const userStore = useUserStore()
const dataGroupStore = useDataGroupStore()
const dataGroupMemberStore = useDataGroupMemberStore()
const familyGroupMemberStore = useFamilyGroupMemberStore()

const userIdRef = computed(() => props.userId || TokenManager.userId)
const userRef = computed(() => userStore.getAsyncUser(userIdRef.value, LoadMode.EnsureLoaded)?.data)
const userPermissions = computed(() => 
  userStore.getAsyncPermissions(userIdRef.value, LoadMode.EnsureLoaded)?.data ?? ItemPermissions.None)

const visibleResearchGroupsRef = computed(() => 
  dataGroupStore.getVisibleGroups(LoadMode.EnsureLoaded).filter(dg => dg.groupType == DataGroupType.Research))

const ownedDataGroupIdsRef = computed(() => 
  dataGroupMemberStore.getMembersForUsers([userIdRef.value], LoadMode.EnsureLoaded)
    .filter(dm => dm.role == GroupMemberRole.Owner)
    .map(dm => dm.dataGroupId!)) 

const ownedResearchGroupsRef = computed(() =>
  dataGroupStore.getLoadedGroupList(ownedDataGroupIdsRef.value)
    .filter(dg => dg.groupType == DataGroupType.Research))

const familyGroupIdsRef = computed(() => 
  familyGroupMemberStore.getMembersForUsers([props.userId], LoadMode.EnsureLoaded)
  .map(fm => fm.familyGroupId!))

const sharedDataGroupIdsRef = computed(() => {
  const dms = dataGroupMemberStore.getMembersForFamilyGroups(familyGroupIdsRef.value, LoadMode.EnsureLoaded)
  return _.uniq(dms.map(dm => dm.dataGroupId!))
})
const sharedResearchGroupsRef = computed(() => {
  const researchGroups = dataGroupStore.getLoadedGroupList(sharedDataGroupIdsRef.value)
    .filter(dg => dg.groupType == DataGroupType.Research)
    .filter(dg => !ownedDataGroupIdsRef.value.includes(dg.id!))
  return _.sortBy(researchGroups, dg => dg.name)
})

const hasGroupsRef = computed(() => ownedResearchGroupsRef.value.length || sharedResearchGroupsRef.value.length)

const canAddRef = computed(() => !props.forUserProfile && !!userRef.value?.isSelf && !TokenManager.isSupervised)
const addTextRef = computed(() => ownedResearchGroupsRef.value.length === 0 ? 'How do I add a tree?' : 'Upload another tree')

</script>