<template>
  <div class="person-page">
    <div class="timeline-area">
      <Timeline :personId="primaryPersonRef?.id" />
    </div>
    <div v-if="primaryPersonRef?.id != exploreStore.startPersonId" class="relation-path-area">
      <RelationPath :person-id="primaryPersonRef?.id" />
    </div>
    <div class="children-area" :class="{ expanded: isLeftAreaExpandedRef }">
      <OverlayButton v-if="!isLeftAreaExpandedRef" @click="expandLeftArea(true)"></OverlayButton>
      <h4>
        <span class="list-label">Children</span>
        <span v-if="allChildrenCountRef > 0" class="list-count">({{ allChildrenCountRef }})</span>
        <div v-if="allChildrenCountRef > 0" class="list-count-mini">{{ allChildrenCountRef }}</div>
      </h4>
      <div v-if="spouseFamiliesWithChildrenRef.length == 0" class="empty-list">
        None
      </div>
      <ul v-else class="list-unstyled spouse-family-list">
        <li v-for="sf in spouseFamiliesWithChildrenRef" :key="sf.family.id">
          <div class="spouse-family-header" :class="{ show: spouseFamiliesWithChildrenRef.length > 1 }">
            {{ getSpouseFamilyName(sf) }} ({{ sf.childIds.length }})
          </div>
          <ul class="list-unstyled person-list">
            <li v-for="(id, index) in sf.childIds" :key="id">
              <div v-if="showChildMoreIndicator(id, sf.family.id!)" class="more-indicator" :class="{ related: isRelatedChild(id) }">
                <ChevronLeftIcon></ChevronLeftIcon>
              </div>
              <PersonCard ref="childCardsRef" :person-id="id" 
                :layout="leftCardLayoutRef"
                :placeholder="!isPrimaryChildInstance(id, sf.family.id!)"
                :data-id="id"
                :data-index="index">
              </PersonCard>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="primary-person-area">
      <div class="drawer-top">
        <PersonCard :person-id="primaryPersonRef?.id"
          ref="primaryCardRef"
          :layout="primaryCardLayoutRef"        
          current
          :style="{ opacity: primaryPersonRef ? 1 : 0 }"
          :data-id="primaryPersonRef?.id"
          :select-mode="!!expandedAreaRef"
          show-profile-count="always"
          @click="onPrimaryClick"
          @show-profiles="showProfilesRef = !showProfilesRef">
        </PersonCard>
      </div>
      <ul v-if="!expandedAreaRef" class="list-unstyled drawer-stack profiles-list" :class="{ show: showProfilesRef }">
        <li v-for="id in profileIdsRef" :key="id">
          <PersonCard :personId="id" profile layout="small"></PersonCard>
        </li>       
      </ul>
      <!-- <h4>{{ spousesTitleRef }} <small v-if="spouseFamiliesRef.length > 1">({{ spouseFamiliesRef.length }})</small></h4> -->
      <div class="spouses-area">
        <ul class="list-unstyled person-list">
          <li v-for="(sf, index) in spouseFamiliesRef" :key="sf.family.id">
            <SpouseConnector :layout="spouseCardLayoutRef" :dimmed="isFamilyDimmed(sf)"></SpouseConnector>
            <PersonCard ref="spouseCardsRef" :person-id="sf.family.spouseOf(primaryPersonRef!.id!)"
              :layout="spouseCardLayoutRef" 
              :data-id="sf.family.spouseOf(primaryPersonRef!.id!)"
              :data-index="index">
            </PersonCard>
            <div class="marriage-year">
              <div v-if="showMarriageYear(sf.family)">m. {{ getMarriageYear(sf.family) }}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="parent-fam-area" :class="{ expanded: isRightAreaExpandedRef }">
      <OverlayButton v-if="!isRightAreaExpandedRef" @click="expandRightArea(true)"></OverlayButton>
      <div class="parent-area">
        <h4>
          <span class="list-label">Parents</span>
        </h4>
        <div v-if="uniqueParentFamiliesRef.length == 0" class="empty-list">
          None
        </div>
        <ul class="list-unstyled parent-list">
          <li v-for="(pf, index) in uniqueParentFamiliesRef" :key="pf.family.id">
            <ul v-if="getOtherSpousesOf(pf, FamilyRelationshipRole.Father).length" class="list-unstyled other-parent-list">
              <li v-for="(id, index) in getOtherSpousesOf(pf, FamilyRelationshipRole.Father)" :key="id">
                <SpouseConnector :layout="rightCardLayoutRef"></SpouseConnector>
                <PersonCard ref="otherParentCardsRef" :personId="id"
                  :layout="rightCardLayoutRef"
                  :data-id="id"
                  :data-index="index">
                </PersonCard>
                <div v-if="dataLoadedRef && hasAncestors(id)" class="more-indicator" :class="{ related: isRelatedParent(id) }">
                  <ChevronRightIcon></ChevronRightIcon>
                </div>
                <div class="step-label">stepmother</div>
              </li>
            </ul>
            <div class="parent-family">
              <FamilyConnector :layout="rightCardLayoutRef" :dimmed="isFamilyDimmed(pf)" spacer></FamilyConnector>
              <div v-for="id in [pf.family.fatherId]" :key="id" class="parent-fam-spouse">
                <PersonCard ref="parentCardsRef" :person-id="id"
                  :layout="rightCardLayoutRef"
                  :data-id="id"
                  :data-index="index">
                </PersonCard>
                <div v-if="dataLoadedRef && hasAncestors(id)" class="more-indicator" :class="{ related: isRelatedParent(id) }">
                  <ChevronRightIcon></ChevronRightIcon>
                </div>
              </div>
              <div class="marriage-year"><span v-if="showMarriageYear(pf.family)">m. {{ getMarriageYear(pf.family) }}</span></div>
              <div v-for="id in [pf.family.motherId]" :key="id" class="parent-fam-spouse">
                <PersonCard ref="parentCardsRef" :person-id="id"
                  :layout="rightCardLayoutRef"
                  :data-id="id"
                  :data-index="index">
                </PersonCard>
                <div v-if="dataLoadedRef && hasAncestors(id)" class="more-indicator" :class="{ related: isRelatedParent(id) }">
                  <ChevronRightIcon></ChevronRightIcon>
                </div>
              </div>
            </div>
            <ul v-if="getOtherSpousesOf(pf, FamilyRelationshipRole.Mother).length" class="list-unstyled other-parent-list">
              <li v-for="(id, index) in getOtherSpousesOf(pf, FamilyRelationshipRole.Mother)" :key="id">
                <SpouseConnector :layout="rightCardLayoutRef"></SpouseConnector>
                <PersonCard ref="otherParentCardsRef" :personId="id"
                  :layout="rightCardLayoutRef"
                  :data-id="id"
                  :data-index="index">
                </PersonCard>
                <div v-if="dataLoadedRef && hasAncestors(id)" class="more-indicator" :class="{ related: isRelatedParent(id) }">
                  <ChevronRightIcon></ChevronRightIcon>
                </div>
                <div class="step-label">stepfather</div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="sibling-area">
        <h4>
          <span class="list-label">Siblings</span>
          <span v-if="allSiblingsRef.length > 1" class="list-count">({{ allSiblingsRef.length }})</span>
          <div v-if="allSiblingsRef.length > 1" class="list-count-mini">{{ allSiblingsRef.length }}</div>
        </h4>
        <div v-if="siblingFamiliesRef.length == 0" class="empty-list">
          None
        </div>
        <button type="button" v-if="showSiblingFamiliesOptionRef" id="showSiblingFamiliesBtn" class="btn btn-inline btn-link" 
          @click="showSiblingFamiliesRef = !showSiblingFamiliesRef">
          <span v-if="showSiblingFamiliesRef">By family</span>
          <span v-else>By birth</span>
        </button>
        <ul class="list-unstyled sibling-family-list">
          <li v-for="sf in siblingFamiliesRef" :key="sf.family.id">
            <div class="spouse-family-header" :class="{ show: siblingFamiliesRef.length > 1 }">
              {{ getSiblingFamilyName(sf) }} ({{ sf.childIds.length }})
            </div>
            <div class="spouse-family-header" :class="{ show: spouseFamiliesWithChildrenRef.length > 1 }">
              {{ getSpouseFamilyName(sf) }} ({{ sf.childIds.length }})
            </div>
            <ul class="list-unstyled person-list sibling-list">
              <li v-for="(id, index) in sf.childIds" :key="id">
                <div v-if="dataLoadedRef && hasDescendants(id) && !isPrimaryPerson(id)" class="more-indicator" :class="{ related: isRelatedChild(id) }">
                  <ChevronLeftIcon></ChevronLeftIcon>
                </div>
                <PersonCard ref="siblingCardsRef" :personId="id"
                  :layout="rightCardLayoutRef"
                  :placeholder="isPrimaryPerson(id)"
                  :data-id="id"
                  :data-index="index">
                </PersonCard>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="details-area">
      <ul class="nav nav-pills person-tabs">
        <li class="nav-item">
          <button type="button" id="highlightsTab" class="nav-link" data-bs-toggle="tab" data-bs-target="#highlightsPane" role="tab" aria-controls="highlightsPane" aria-selected="false">
            Fun facts
          </button>
        </li>
        <li class="nav-item">
          <button type="button" id="detailsTab" class="nav-link active" data-bs-toggle="tab" data-bs-target="#detailsPane" role="tab" aria-controls="detailsPane" aria-selected="true">
            Details
          </button>
        </li>
        <!-- <li class="nav-item">
          <button type="button" id="placesTab" class="nav-link" data-bs-toggle="tab" data-bs-target="#placesPane" role="tab" aria-controls="placesPane" aria-selected="false">Places</button>
        </li>
        <li class="nav-item">
          <button type="button" id="imagesTab" class="nav-link" data-bs-toggle="tab" data-bs-target="#imagesPane" role="tab" aria-controls="imagesPane" aria-selected="false">Pictures</button>
        </li>
        <li class="nav-item">
          <button type="button" id="sourcesTab" class="nav-link" data-bs-toggle="tab" data-bs-target="#sourcesPane" role="tab" aria-controls="sourcesPane" aria-selected="false">Sources</button>
        </li> -->
      </ul>
      <div id="personContent" class="tab-content person-details">
        <div id="highlightsPane" class="tab-pane" role="tabpanel" aria-labelled-by="highlightsTab" tab-index="0">
          <PersonHighlights :person-id="primaryPersonRef?.id"></PersonHighlights>
        </div>
        <div id="detailsPane" class="tab-pane show active" role="tabpanel" aria-labelled-by="detailsTab" tab-index="0">
          <AssertionList :person-id="primaryPersonRef?.id" include-related card-style></AssertionList>
        </div>
      </div>
    </div>
    <div class="earth-area">
      <Earth :focus-latitude="focusPlaceRef?.latitude" :focus-longitude="focusPlaceRef?.longitude"></Earth>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$expand-transition-duration: 0.3s;
$expand-transition-timing: ease-out;

.person-page {
  position: relative;
  padding-bottom: 90px;
  display: grid;
  grid-template-columns: max-content 1fr max-content;

  @media (min-width: 768px) {
    grid-template-columns: 25% 50% 25%;
  }

  h4 {
    position: relative;
    color: #888;
    font-size: 0.875rem;
    margin: 0.5rem 0 0.5rem;
    transition: font-size $expand-transition-duration;

    .list-label {
      width: 0;
      opacity: 0;
      transition-property: opacity, width, margin-right;
      transition-duration: $expand-transition-duration;
    }

    .list-count, .list-count-mini {
      padding-top: 0.1em;
      font-size: 0.875em; // relative
      color: #aaa;
      transition-property: opacity, width;
      transition-duration: $expand-transition-duration;
    }

    .list-count {
      opacity: 0;
      width: 0;
    }

    .list-count-mini {
      position: absolute;
      top: 0;
      width: 100%;
      opacity: 1;
      text-align: center;
    }
  }

  .expanded {
    h4 {
      margin-bottom: 0.5rem;
      font-size: 1rem;

      @media (min-width: 768px) {
        font-size: 1.5rem; // default h4 size
      }

      .list-label {
        width: auto;
        margin-right: 0.25em; // relative
        opacity: 1;
      }

      .list-count {
        opacity: 1;
        width: auto;
      }

      .list-count-mini {
        opacity: 0;
      }
    }
  }
}

.timeline-area {
  grid-column: 1 / span 3;
  margin-top: 0.5rem;
  padding: 0 1rem;
}

.relation-path-area {
  grid-column: 1 / span 3;
  margin-top: 0.25rem;
  min-height: 20px;
  display: flex;
  justify-content: center;
}

.tools-area {
  margin-left: auto;
}

.filter-mode-tool {

  input[type="checkbox"] {
    appearance: none;

    &:hover + img {
      opacity: 0.5;
    }

    &:checked + img {
      opacity: 1;
    }
  }

  img {
    width: 1.4rem;
    height: 1.4rem;
    opacity: 0.4;
  }
}

$collapsed-width: 20px;
$expanded-width: 180px;

.empty-list {
  display: none;

  @media (min-width: 768px) {
    display: block;
    font-size: 0.875rem;
    color: #aaa;
  }
}

.children-area {
  position: relative;
  grid-column: 1;
  min-height: 100px;
  width: $collapsed-width;
  padding: 0;
  transition: width $expand-transition-duration $expand-transition-timing;

  &.expanded {
    width: $expanded-width;
  }

  @media (min-width: 768px) {
    width: inherit !important;
    grid-row: span 99;
    padding: 0 1rem;
  }
}

.primary-person-area {
  grid-column: 2;
  min-width: 0;
  min-height: 100px;
  padding: 0 0.5rem 1rem 0.5rem;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    padding: 0 1rem;
  }
}

.profiles-list {
  width: 100%;
  min-width: 0;
  margin-bottom: 1em;

  li {
    margin: -0.5rem; // for drop shadow
    padding: 0.5rem; // for drop shadow

    .person-card {
      max-width: inherit;
      width: 100%;
    }
  }
}

.spouses-area {
  ul li {
    display: grid;
    grid-template-columns: max-content auto max-content;
    justify-content: start;
    align-items: center;
  }

  .person-card {
    min-width: 0;
    max-width: 600px;
  }

  .marriage-year {
    grid-column: 2;
    grid-row: 2;
    font-size: 0.875rem;
  }
}

.marriage-year {
  display: flex;
  align-items: center;
  height: 0; // must still be present to maintain grid layout
  color: #888;

  @media (min-width: 768px) {
    margin-left: 1rem;
    height: 1.5rem;
  }
}

.select-checkbox {
  align-self: center;
  margin-left: 0.5rem;
  width: 14px;
  height: 14px;
  appearance: none;
  border: 1px solid #ddd;
  border-radius: 0;
  position: relative;

  &:hover {
    border-color: #bbb;
    background-color: #fffabb !important;
  }

  &.active {
    &:checked {
      background-color: #fefcd5;
    }
    &:not(:checked) {
      background-color: white;
    }
  }

  &:checked::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 12px;
    height: 12px;
    background-image: url('@/assets/icons/check_gray_12.png');
    background-size: contain;
  }
}

.nav.person-tabs {

  .nav-item .nav-link {
    font-size: 0.75rem;
  }
}

.details-area {
  grid-column: 1/span 3;

  @media (min-width: 768px) {
    grid-column: 2;
    padding: 0 1rem;
  }

  .person-details {
    min-height: 400px;
  }
}
.parent-fam-area {
  grid-column: 3;
  width: $collapsed-width;
  min-height: 100px;
  padding: 0;
  padding-left: 6px;
  transition: width $expand-transition-duration $expand-transition-timing;
  transition-property: width, margin-top;

  &.expanded {
    width: $expanded-width;
  }

  @media (min-width: 768px) {
    grid-row: span 99;
    margin-top: 0;
    width: inherit !important;
    padding: 0 1rem;
  }

  .parent-area {
    h4 {
      margin: 0;    
      transition-property: font-size, margin;
    }

    @media (min-width: 768px) {
      margin-bottom: 0.5rem;
    }
  }

  &.expanded .parent-area h4 {
    margin: 0.5rem 0;
  }

  .marriage-year {
    margin-left: 1rem;
    font-size: 0.75rem;
  }
}

.person-placeholder {
  width: 100%;
  height: 2.2rem;
  border: 1px dashed lightgray;
  padding-left: 38px; // 8 + 30
  font-size: 0.857rem;
}

ul.person-list {
  margin: 0;

  li {
    position: relative;
    margin-bottom: 6px;

    @media (min-width: 768px) {
      margin-bottom: 0.5rem;
    }
  }
}

.more-indicator {
  position: absolute;
  top: -3px;

  @media (min-width: 768px) {
    top: 10px;
  }

  svg {
    display: block;
    color: #aaa;
    width: 14px;
    height: 14px;
  }

  &.related {
    svg{
      color: #000;
    }
  }
}

ul.spouse-family-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-left: 4px;

  @media (min-width: 768px) {
    padding-left: 0;
  }

  .person-list li {
    width: auto;
  }

  .more-indicator {
    left: -14px;

    @media (min-width: 768px) {
      left: -1.2rem;
    }
  }
}

.spouse-family-header {
  font-size: 0.75rem;
  color: #888;
  height: 0;
  opacity: 0;
  transition: height, opacity 0.3s;

  &.show {
    margin-bottom: 0.25rem;
    height: inherit;
    opacity: 1;
  }
}

ul.parent-list {
  margin: 0;
  min-height: 30px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (min-width: 768px) {
    min-height: 5.9rem;
    gap: 1.5rem;
  }

  & > li {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .other-parent-list {
      margin-left: -1rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      li {
        display: grid;
        grid-template-columns: max-content auto;
        align-items: center;
        position: relative;

        .person-card {
          min-width: 0;
        }

        .step-label {
          grid-column: 2;
          grid-row: 2;
          margin-top: 0.25rem;
          //margin-left: 0.5rem;
          font-size: 0.75rem;
          color: #888;
        }
      }
    }

    .parent-family {
      margin-left: -6px;
      display: grid;
      align-items: center;
      grid-template-columns: max-content auto;
      grid-template-rows: max-content max-content max-content;

      @media (min-width: 768px) {
        margin-left: -1rem;
      }

      .family-connector {
        grid-row: 1 / span 3;
      }

      .parent-fam-spouse {
        min-width: 0;
        display: flex;
        align-items: center;
        position: relative;

        .person-card {
          flex: 1;
          min-width: 0;
        }
      }
    }

    .more-indicator {
      right: -1.3em;
    }
  }
}

.sibling-area {
  display: flex;
  flex-direction: column;

  #showSiblingFamiliesBtn {
    margin-bottom: 0.5rem;
    //align-self: flex-end;
    font-size: 0.75rem;
    color: #888;

    &:hover {
      color: #333;
    }
  }

  .sibling-family-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  ul.sibling-list li {
    width: auto;

    .more-indicator {
      left: -0.25rem;
    }
  }
}

.earth-area {
  position: fixed;
  bottom: 0;
  width: 5rem;
  height: 5rem;
  padding: 0;

  @media (min-width: 768px) {
    width: 10rem;
    height: 10rem;
  }
}
</style>

<script setup lang="ts">
import { ref, Ref, computed, watch, nextTick, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { usePageTitle } from '@/util/AppUtil'
import { ViewPerson, ViewFamily, AssertionType, FamilyRelationshipRole } from '@/rd/ResearchDataModel'
import { useViewPersonStore } from '@/rd/ViewPersonStore'
import { useViewFamilyStore, FamilyAndChildren } from '@/rd/ViewFamilyStore'
import { usePersonStore } from '@/rd/PersonStore'
import { useAssertionStore } from '@/rd/AssertionStore'
import { usePlaceStore } from '@/rd/PlaceStore'
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { useExploreStore } from './ExploreStore'
import { SortDate } from '@/rd/SortDate'
import { CompositeId } from '@/rd/CompositeId'
import { ProfileUtils } from '@/manage/profiles/ProfileUtils'
import { LoadMode } from '@/util/AsyncData'
import { isDefined } from '@/util/TypeScriptUtil'
import { animate } from 'motion'
import _, { filter } from 'lodash'
import Timeline from './Timeline.vue'
import RelationPath from './RelationPath.vue'
import OverlayButton from '@/util/OverlayButton.vue'
import PersonCard from "@/manage/PersonCard.vue"
import SpouseConnector from '@/manage/SpouseConnector.vue'
import FamilyConnector from '@/manage/FamilyConnector.vue'
import PersonHighlights from "@/explore/highlights/PersonHighlights.vue"
import AssertionList from "@/explore/AssertionList.vue"
import Earth from './Earth.vue'
import { ChevronLeftIcon, ChevronRightIcon } from '@zhuowenli/vue-feather-icons'
import { DOMUtil } from '@/util/DOMUtil'
import { useWindowSize } from '@vueuse/core'

const props = defineProps({
  personId: String
})

const router = useRouter()
const { width: windowWidth } = useWindowSize()
const viewPersonStore = useViewPersonStore()
const viewFamilyStore = useViewFamilyStore()
const personStore = usePersonStore()
const assertionStore = useAssertionStore()
const placeStore = usePlaceStore()
const dataGroupStore = useDataGroupStore()
const exploreStore = useExploreStore()

const statusRef = ref('idle')
async function setStatus(value: string) {
  statusRef.value = value
  await nextTick()
}

const primaryPersonRef = ref<ViewPerson | undefined>(undefined)
usePageTitle("Person", () => primaryPersonRef.value?.displayName, true)

const isLargeScreenRef = computed(() => windowWidth.value > 768)

// ensure profile data groups are loaded so we can determine which profiles are not placeholders
const showProfilesRef = ref(false)
const profileIdsRef = computed(() => {
  const profileIds = primaryPersonRef.value?.matchIds ?? []
  const dataGroups = dataGroupStore.getAsyncGroupList(profileIds.map(CompositeId.getGroupId))
    .map(g => g.data).filter(isDefined)
  return ProfileUtils.getNonPlaceholderIds(profileIds, dataGroups)
})

// NOTE: profile persons and their display properties are all loaded in a single request with the assertions, so
// no need to load them separately for the profile cards

// track loading of person assertions to determine an alternative focus place in case we can't use birth place 
// available on view person display properties
const birthPlaceIdRef = computed(() => primaryPersonRef.value?.displayProperties.birthPlaceId)
const birthPlaceRef = computed(() => placeStore.trackAsyncPlace(birthPlaceIdRef.value)?.data)
const altFocusPlaceRef = computed(() => {
  const assertionIds = _.flatten(personStore.getAsyncAssertionKeyLists(primaryPersonRef.value?.matchIds ?? [])
    .map(a => a.data?.keys).filter(isDefined))
  const altBirthAssertions = assertionStore.getAsyncAssertionList(assertionIds).map(a => a.data).filter(isDefined)
    .filter(a => a.assertionType === AssertionType.Christen)
  const altBirthPlaceId = altBirthAssertions.at(0)?.placeId
  return placeStore.trackAsyncPlace(altBirthPlaceId)?.data
})
const focusPlaceRef = computed(() => birthPlaceRef.value ?? altFocusPlaceRef.value)

const spouseFamiliesRef = ref([]) as Ref<FamilyAndChildren[]>
const spouseFamiliesWithChildrenRef = computed(() => spouseFamiliesRef.value.filter(sf => sf.childIds.length > 0))
const spousesRef = ref([]) as Ref<ViewPerson[]>
const allChildrenCountRef = ref(0)
const uniqueParentFamiliesRef = ref([]) as Ref<FamilyAndChildren[]>
const otherParentFamiliesRef = ref([]) as Ref<FamilyAndChildren[]>
const allSiblingsRef = ref([]) as Ref<ViewPerson[]>
const showSiblingFamiliesRef = ref(false)
const showSiblingFamiliesOptionRef = computed(() => uniqueParentFamiliesRef.value.length > 1 || otherParentFamiliesRef.value.length > 0)
const siblingFamiliesRef = computed(() => {
  const allNonEmptyFamilies = [...uniqueParentFamiliesRef.value, ...otherParentFamiliesRef.value]
      .filter(sf => sf.childIds.length > 0)

  if (allNonEmptyFamilies.length > 1 && !showSiblingFamiliesRef.value) {
    const combinedFamily = {
      family: allNonEmptyFamilies[0].family, // re-use first family as placeholder for display
      childIds: allSiblingsRef.value.map(s => s.id) 
    } as FamilyAndChildren
    return [combinedFamily]
  }
  return allNonEmptyFamilies
})

// TODO: Use localization to pluralize
const parentFamiliesTextRef = computed(() => uniqueParentFamiliesRef.value.length > 1 ? "families" : "family")

function showChildMoreIndicator(childId: string, familyId: string) { 
  return hasDescendants(childId) && isPrimaryChildInstance(childId, familyId)
}

function isPrimaryChildInstance(childId: string, familyId: string) {
  // treat first occurrence of child as "primary"
  return spouseFamiliesRef.value.find(sf => sf.childIds.includes(childId))?.family.id == familyId
}

function getSpouseFamilyName(sf: FamilyAndChildren) {
  return spousesRef.value.find(sp => sp.id == sf.family.spouseOf(props.personId!))?.displayName
}

function getOtherSpousesOf(pf: FamilyAndChildren, role: FamilyRelationshipRole) {
  const parentId = pf.family.getSpouseId(role)
  if (!parentId)
    return []

  const exceptSpouseId = pf.family.spouseOf(parentId)
  return otherParentFamiliesRef.value.filter(pf => pf.family.hasSpouse(parentId))
    .map(pf => pf.family.spouseOf(parentId))
    .filter(id => id != exceptSpouseId)
}

function getSiblingFamilyName(sf: FamilyAndChildren) {
  const fatherName = viewPersonStore.getAsyncPerson(sf.family.fatherId)?.data?.displayName
  const motherName = viewPersonStore.getAsyncPerson(sf.family.motherId)?.data?.displayName
  if (uniqueParentFamiliesRef.value.includes(sf)) {
    return `${fatherName} and ${motherName}`
  }
  else {
    return sf.family.fatherId && uniqueParentFamiliesRef.value.find(pf => pf.family.hasSpouse(sf.family.fatherId!))
      ? motherName : fatherName
  }
}

type SelectableItemType = 'ViewPerson' | 'ViewFamily' | 'Profile'

const filterModeRef = ref(false)
const selectedItemTypeRef = ref<SelectableItemType | undefined>(undefined)
const selectedItemIdRef = ref<string | undefined>(undefined)

function isSelected(itemId: string, itemType: SelectableItemType) {
  return selectedItemTypeRef.value == itemType && selectedItemIdRef.value == itemId
}

function selectItem(itemId: string | undefined, itemType: SelectableItemType) {
  filterModeRef.value = !!itemId
  selectedItemTypeRef.value = itemType
  selectedItemIdRef.value = itemId
}

function isFamilySelected(f: FamilyAndChildren) {
  return isSelected(f.family.id!, 'ViewFamily')
}

function toggleSelectSpouseFamily(sf: FamilyAndChildren) {
  const select = enableSelectSpouseRef.value && !isSelected(sf.family.id!, 'ViewFamily') // toggle if enabled, otherwise clear selection
  selectItem(select ? sf.family.id : undefined, 'ViewFamily')
}

function toggleSelectParentFamily(pf: FamilyAndChildren) {
  const select = enableSelectParentsRef.value && !isSelected(pf.family.id!, 'ViewFamily') // toggle if enabled, otherwise clear selection
  selectItem(select ? pf.family.id : undefined, 'ViewFamily')
}

function clearSelection() {
  selectItem(undefined, 'ViewFamily')
}

function isPrimaryPerson(personId: string) {
  return personId == primaryPersonRef.value?.id
}

function showMarriageYear(family: ViewFamily) {
  return isLargeScreenRef.value && !!getMarriageYear(family)
}

function getMarriageYear(family: ViewFamily) {
  return family.displayProperties.marriageDate.date1?.year
}

const enableSelectSpouseRef = computed(() => spouseFamiliesRef.value.length > 1)
const enableSelectParentsRef = computed(() => uniqueParentFamiliesRef.value.length > 1)

function onFamilySelectedChange(f: FamilyAndChildren, e: Event) {
  const checkbox = e.target as HTMLInputElement
  selectItem(checkbox.checked ? f.family.id : undefined, 'ViewFamily')
}

function isFamilyDimmed(f: FamilyAndChildren) {
  return filterModeRef.value && !isSelected(f.family.id!, 'ViewFamily')
}

function isChildInFilter(childId: string) {
  return spouseFamiliesRef.value.find(sf => isFamilySelected(sf))?.childIds.includes(childId)
}

function isSiblingInFilter(siblingId: string) {
  return uniqueParentFamiliesRef.value.find(pf => isFamilySelected(pf))?.childIds.includes(siblingId)
}

function hasDescendants(personId: string | undefined) {
  return personId && viewPersonStore.hasLoadedDescendants(personId)
}

function hasAncestors(personId: string | undefined) {
  return personId && viewPersonStore.hasLoadedAncestors(personId)
}

function isRelatedChild(childId: string | undefined) {
  return childId && childId != exploreStore.startPersonId && exploreStore.relationPath?.startPersonPath.some(pi => pi.ancestorId == childId)
}

function isRelatedParent(parentId: string | undefined) {
  return parentId && parentId != exploreStore.startPersonId && (
    exploreStore.relationPath?.toPersonPath.some(pi => pi.ancestorId == parentId) ||
    (exploreStore.relationPath?.commonAncestorId == parentId && exploreStore.relationPath?.startPersonPath.length == 0))
}

const expandedAreaRef = ref<'left' | 'right'>()
const isLeftAreaExpandedRef = computed(() => isLargeScreenRef.value || expandedAreaRef.value == 'left')
const isRightAreaExpandedRef = computed(() => isLargeScreenRef.value || expandedAreaRef.value == 'right')

const leftCardLayoutRef = computed(() => isLargeScreenRef.value ? 'small' : isLeftAreaExpandedRef.value ? 'tiny' : 'dot')
const primaryCardLayoutRef = computed(() => !isLargeScreenRef.value && expandedAreaRef.value ? 'small' : 'full')
const spouseCardLayoutRef = computed(() => expandedAreaRef.value ? 'tiny' : 'small')
const rightCardLayoutRef = computed(() => isLargeScreenRef.value ? 'small' : isRightAreaExpandedRef.value ? 'tiny' : 'dot')

function expandLeftArea(expand: boolean) {
  expandedAreaRef.value = expand ? 'left' : undefined
}

function expandRightArea(expand: boolean) {
  expandedAreaRef.value = expand ? 'right' : undefined
}

function onPrimaryClick() {
  expandedAreaRef.value = undefined
}

watch(isLargeScreenRef, () => {
  if (isLargeScreenRef.value) {
    expandedAreaRef.value = undefined
  }
})

const prevPersonPositions = new Map<string, DOMRect>()

const primaryCardRef = ref<InstanceType<typeof PersonCard>>()
const spouseCardsRef = ref<InstanceType<typeof PersonCard>[]>()
const childCardsRef = ref<InstanceType<typeof PersonCard>[]>()
const parentCardsRef = ref<InstanceType<typeof PersonCard>[]>()
const otherParentCardsRef = ref<InstanceType<typeof PersonCard>[]>()
const siblingCardsRef = ref<InstanceType<typeof PersonCard>[]>()

function savePrevPosition(card: InstanceType<typeof PersonCard>) {
  const el = card?.$el as HTMLElement
  if (el && !el.classList.contains('card-placeholder')) {
    const oldPosition = el.getBoundingClientRect()
    const personId = el.dataset.id!
    prevPersonPositions.set(personId, oldPosition)
  }
}

const dataLoadedRef = ref(true)

watch(props, async () => {
  await setStatus("Saving card positions")
  // capture positions of all persons currently displayed
  prevPersonPositions.clear()
  if (primaryPersonRef.value?.id) {
    savePrevPosition(primaryCardRef.value!)
  }
  const personCards = [
    ...(childCardsRef.value ?? []),
    ...(spouseCardsRef.value ?? []),
    ...(parentCardsRef.value ?? []),
    ...(otherParentCardsRef.value ?? []),
    ...(siblingCardsRef.value ?? []),
  ]
  for (const c of personCards) {
    savePrevPosition(c)
  }

  console.log("PersonPage: all previous person positions saved")

  // reset interactive state
  showProfilesRef.value = false
  filterModeRef.value = false
  selectedItemTypeRef.value = undefined
  selectedItemIdRef.value = undefined

  dataLoadedRef.value = false

  if (!props.personId)
    return

  await setStatus("Start loading details")

  // ensure primary person details and user location are loaded (don't wait)
  viewPersonStore.ensureDetailsLoadedAsync(props.personId)
  exploreStore.ensureUserLocationLoaded()

  await setStatus("Loading generations")
  // pre-load extra generations of ancestors and descendants (don't wait if not needed yet)
  const loadRelated = Promise.allSettled([
    viewPersonStore.ensureAncestorsLoadedAsync(props.personId, 3, 2),
    viewPersonStore.ensureDescendantsLoadedAsync(props.personId, 2),
  ])

  // wait to display page if related persons are not loaded
  const ancestorsLoaded = viewPersonStore.areAncestorsLoaded(props.personId, 1, 1)
  const descendantsLoaded = viewPersonStore.areDescendantsLoaded(props.personId, 1)
  console.log(`PersonPage: ancestors loaded: ${ancestorsLoaded}, descendants loaded: ${descendantsLoaded}`)
  if (!ancestorsLoaded || !descendantsLoaded) {
    await loadRelated
    //await AsyncUtil.delay(1000)
    console.log(`PersonPage: ancestors and descendants loaded`)
    dataLoadedRef.value = true
  }
  else {
    loadRelated.then(() => {
      if (props.personId &&
          viewPersonStore.areAncestorsLoaded(props.personId, 2, 2) && 
          viewPersonStore.areDescendantsLoaded(props.personId, 2)) {
        // we now have enough information to show more indicators
        dataLoadedRef.value = true
      }
    })
  }

  const spouseFamilyIds = viewPersonStore.asyncSpouseFamilyKeys.get(props.personId!)?.data?.keys ?? []
  const spouseFamilies = spouseFamilyIds.map(id => viewFamilyStore.asyncViewFamilies.get(id)!.data!)
  console.log(`PersonPage: spouse families found: ${spouseFamilies.length}`)

  await setStatus("Start loading family details")
  // ensure spouse family assertions loaded (don't wait)
  spouseFamilyIds.map(id => viewFamilyStore.ensureAssertionsLoadedAsync(id))

  await setStatus("Start loading spouse generations")
  // pre-load spouse ancestors and descendants (don't wait)
  for (const sf of spouseFamilies) {
    viewPersonStore.ensureAncestorsLoadedAsync(sf.spouseOf(props.personId), 1, 1)
    viewPersonStore.ensureDescendantsLoadedAsync(sf.spouseOf(props.personId), 1)
  }

  // got everything we need, so start updating reactive state
  console.log(`PersonPage: updating reactive state`)

  // should be loaded prior to navigation in most cases (except e.g. initial load from a deep link)
  const primaryPerson = viewPersonStore.getAsyncPerson(props.personId)?.data
  if (!primaryPerson) {
    console.log(`PersonPage: primary person not found`)
    return
  }

  if (primaryPerson.id != props.personId) {
    // redirect to canonical URL for this view person
    router.replace(primaryPerson.pageUrl!)
    return
  }

  const familyChildrenMap = new Map(
    spouseFamilyIds.map(id => [id, viewFamilyStore.asyncChildKeys.get(id)?.data?.keys ?? []]))
  // TODO: how to sort multiple families?
  const allChildIds = _.uniq(_.flatten(Array.from(familyChildrenMap.values()))) // preserve child order within families
  console.log(`PersonPage: children found: ${allChildIds.length}`)

  const parentFamilyIds = viewPersonStore.asyncParentFamilyKeys.get(props.personId!)?.data?.keys ?? []
  const parentFamilies = parentFamilyIds.map(id => viewFamilyStore.asyncViewFamilies.get(id)?.data).filter(isDefined)
  console.log(`PersonPage: parent families found: ${parentFamilies.length}`)
  // TODO: how to sort parent families?

  // only show parent families that have NO spouses in common with a previous parent family
  const uniqueParentFamilies = [] as ViewFamily[]
  const parentIds = [] as string[]
  for (const pf of parentFamilies) {
    if (!pf.spouseIds.some(id => parentIds.includes(id))) {
      uniqueParentFamilies.push(pf)
      parentIds.push(...pf.spouseIds)
    }
  }
  const uniqueParentFamilyIds = uniqueParentFamilies.map(f => f.id!)

  const otherParentFamilyIds = parentIds.flatMap(id => viewPersonStore.asyncSpouseFamilyKeys.get(id)?.data?.keys ?? []) // should be loaded
    .filter(famId => !uniqueParentFamilyIds.includes(famId))
  const otherParentFamilies = otherParentFamilyIds.map(id => viewFamilyStore.asyncViewFamilies.get(id)?.data)
    .filter(isDefined)

  const allParentFamilyIds = [...uniqueParentFamilyIds, ...otherParentFamilyIds]
  const familySiblingMap = new Map(allParentFamilyIds.map(id => [id, viewFamilyStore.asyncChildKeys.get(id)?.data?.keys ?? []]))
  const allSiblingIds = _.uniq(_.flatten(Array.from(familySiblingMap.values()))) // preserve child order within families
  const allSiblings = viewPersonStore.getAsyncPersonList(allSiblingIds)
    .map(a => a.data).filter(isDefined)
    .sort((a, b) => SortDate.compare(a.displayProperties.birthSortDate, b.displayProperties.birthSortDate))
  console.log(`PersonPage: siblings found: ${allSiblings.length}`)

  const spouseFamiliesAndChildren = spouseFamilies
    .map(sf => ({ family: sf, childIds: familyChildrenMap.get(sf.id!) ?? [] }))
    .sort((a, b) => SortDate.compare(a.family.displayProperties.marriageSortDate, b.family.displayProperties.marriageSortDate))
  const spouses = viewPersonStore.getAsyncPersonList(spouseFamilies.map(sf => sf.spouseOf(props.personId!)))
    .map(a => a.data).filter(isDefined)
  const uniqueParentFamiliesAndChildren = uniqueParentFamilies
    .map(pf => ({ family: pf, childIds: familySiblingMap.get(pf.id!) ?? [] }))
  const otherParentFamiliesAndChildren = otherParentFamilies
    .map(pf => ({ family: pf, childIds: familySiblingMap.get(pf.id!) ?? [] }))

  // ensure all data groups (and owners) get loaded, but don't wait for them
  const matchIds = primaryPerson?.matchIds ?? []
  const allPersonAndFamilyIds = [matchIds, spouseFamilyIds, allChildIds, parentFamilyIds, allSiblingIds].flat()
  const allGroupIds = [...new Set(allPersonAndFamilyIds.map(CompositeId.getGroupId))]
  const asyncGroups = dataGroupStore.getAsyncGroupList(allGroupIds, LoadMode.EnsureLoaded)
  console.log(`PersonPage: data groups found: ${allGroupIds.length}, loaded: ${asyncGroups.filter(a => a.loadComplete).length}`)

  const newPersonIds = new Set([
    props.personId, 
    ...spouseFamilies.map(sf => sf.spouseOf(props.personId!)),
    ...allChildIds,
    ...uniqueParentFamilies.flatMap(pf => pf.spouseIds),
    ...allSiblingIds].filter(isDefined))

  await setStatus("Animating out")
  await animatePersonsOutAsync(newPersonIds)

  // update reactive state
  primaryPersonRef.value = primaryPerson
  spouseFamiliesRef.value = spouseFamiliesAndChildren
  spousesRef.value = spouses
  allChildrenCountRef.value = allChildIds.length
  uniqueParentFamiliesRef.value = uniqueParentFamiliesAndChildren
  otherParentFamiliesRef.value = otherParentFamiliesAndChildren
  allSiblingsRef.value = allSiblings

  // update layout
  expandedAreaRef.value = undefined

  // allow Vue to update DOM before we try to animate new/moved elements
  //await nextTick()
  await setStatus("Animating in")

  animatePersonsIn()

}, { immediate: true })

const animationDuration = 0.5

async function animatePersonsOutAsync(newPersonIds: Set<string>) {
  const finished: (Promise<void> | undefined)[] = []
  
  const oldPrimaryPersonId = primaryPersonRef.value?.id
  if (oldPrimaryPersonId && !newPersonIds.has(oldPrimaryPersonId)) {
    // zoom + fade out
    const ac = animate(primaryCardRef.value!.$el, 
      { opacity: [1, 0] },
      { duration: animationDuration, easing: "ease-in" }
    )
    finished.push(ac.finished)
  }

  // TODO: animate placeholders correctly (should fade out in-place)
  
  let childrenChanged = false
  for (const c of childCardsRef.value ?? []) {
    const personId = c.$el.dataset.id!
    if (personId && !newPersonIds.has(personId)) {
      const ac = animatePersonOut(c.$el, "left")
      finished.push(ac.finished)
      childrenChanged = true
    }
  }

  if (childrenChanged) {
    //finished.push(fadeOut(".children-area .spouse-family-header")?.finished)
    finished.push(fadeOut(".children-area .more-indicator")?.finished)
  }

  for (const c of spouseCardsRef.value ?? []) {
    const personId = c.$el.dataset.id!
    if (personId && !newPersonIds.has(personId)) {
      const ac = animatePersonOut(c.$el)
      finished.push(ac.finished)
    }
  }

  finished.push(fadeOut(".spouses-area .spouse-connector")?.finished)
  finished.push(fadeOut(".spouses-area .marriage-year")?.finished)

  const rightCards = [
    ...(parentCardsRef.value ?? []),
    ...(otherParentCardsRef.value ?? []),
    ...(siblingCardsRef.value ?? [])
  ]
  let rightCardsChanged = false
  for (const c of rightCards) {
    const personId = c.$el.dataset.id!
    if (personId && !newPersonIds.has(personId)) {
      const ac = animatePersonOut(c.$el, "right")
      finished.push(ac.finished)
      rightCardsChanged = true
    }
  }

  if (rightCardsChanged) {
    finished.push(fadeOut(".parent-list .spouse-connector")?.finished)
    finished.push(fadeOut(".parent-list .parent-fam-connector")?.finished)
    finished.push(fadeOut(".parent-list .marriage-year")?.finished)
    //finished.push(fadeOut(".sibling-area .spouse-family-header")?.finished)
    finished.push(fadeOut(".parent-fam-area .more-indicator")?.finished)
  }

  return Promise.all(finished)
}

function animatePersonOut(el: HTMLElement, direction?: 'left' | 'right') {
  const personId = el.dataset.id!
  console.log(`PersonPage: personOut: ${viewPersonStore.getAsyncPerson(personId)?.data?.displayName}`)
  const index = parseInt(el.dataset.index!)

  if (!direction) {
    return animate(el,
      { width: [null, "50%"], height: [null, "50%"], opacity: [1, 0] },
      { duration: animationDuration / 2, easing: "ease-in" }
    )
  }

  const dx = direction == "left" ? -100 : 100
  return animate(el,
    { x: dx, opacity: [1, 0] },
    { duration: animationDuration / 2, easing: 'linear' }
  )
}

function fadeOut(selector: string) {
  // HACK: animate complains if selector doesn't match anything, so check first
  const elements = document.querySelectorAll(selector)
  if (elements.length == 0) {
    return undefined
  }
  
  return animate(selector,
    { opacity: [1, 0, 0] },
    { duration: animationDuration / 2, easing: "linear" })
}

function animatePersonsIn() {
  animatePersonIn(primaryCardRef.value!.$el)

  // TODO: animate placeholders correctly (should fade in in-place)

  let childrenChanged = false
  for (const c of childCardsRef.value ?? []) {
    if (animatePersonIn(c.$el, "left")) {
      childrenChanged = true
    }
  }
  if (childrenChanged) {
    //fadeIn(".children-area .spouse-family-header")
    fadeIn(".children-area .more-indicator")
  }

  for (const c of spouseCardsRef.value ?? []) {
    animatePersonIn(c.$el, "left")
  }

  let parentsChanged = false
  const allParentCards = [
    ...(parentCardsRef.value ?? []), 
    ...(otherParentCardsRef.value ?? [])
  ]
  for (const c of allParentCards) {
    if (animatePersonIn(c.$el, "right")) {
      parentsChanged = true
    }
  }

  for (const c of siblingCardsRef.value ?? []) {
    animatePersonIn(c.$el, "right")
  }

  fadeIn(".spouses-area .spouse-connector")
  fadeIn(".spouses-area .marriage-year")
  if (parentsChanged) {
    fadeIn(".parent-list .parent-fam-connector")
    fadeIn(".parent-list .marriage-year")
    //fadeIn(".sibling-area .spouse-family-header")
    fadeIn(".parent-fam-area .more-indicator")
  }
}

function animatePersonIn(el: HTMLElement, direction?: "left" | "right") {
  const personId = el.dataset.id!
  const oldPosition = prevPersonPositions.get(personId)
  if (oldPosition) {
    const newPosition = el.getBoundingClientRect()
    if (DOMUtil.rectEquals(newPosition, oldPosition)) {
      return undefined // no change
    }
    // slide from old to new position (scale if needed)
    const dx = oldPosition.left - newPosition.left
    const dy = oldPosition.top - newPosition.top
    const dw = oldPosition.width / newPosition.width
    const dh = oldPosition.height / newPosition.height
    const opacityKeyframes = (dw == 1 && dh == 1) ? [1, 1] : [0.5, 1]
    el.style.transformOrigin = "top left"
    return animate(el,
      { x: [dx, 0], y: [dy, 0], scaleX: [dw, 1], scaleY: [dh, 1], opacity: opacityKeyframes },
      { duration: animationDuration, easing: 'ease-out' })
  }
  else if (direction) {
    // slide + fade in from direction
    const dx = direction == "left" ? -100 : 100
    return animate(el,
      { x: [dx, 0], opacity: [0, 1] },
      { duration: animationDuration, easing: 'ease-in' }
    )
  }
  else {
    // zoom + fade in from center
    el.style.transformOrigin = "center"
    return animate(el, 
      { scale: [0.5, 1], opacity: [0, 1] },
      { duration: animationDuration, easing: "ease-out" })
  }
}

function fadeIn(selector: string) {
  // HACK: animate complains if selector doesn't match anything, so check first
  const elements = document.querySelectorAll(selector)
  if (elements.length == 0) {
    return undefined
  }

  return animate(selector,
    { opacity: [0, 1] },
    { delay: animationDuration, duration: 0.3, easing: "ease-out" })
}

onMounted(() => exploreStore.hasViewedTree = true )

</script>
