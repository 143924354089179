<template>
  <ExpandableItem class="expander">
    <template #item>
      <div class="expander-label">
        {{ titleRef }} <span class="count">({{ props.family.childIds.length }})</span>
      </div>
    </template>
    <template #content>
      <div class="children-area">
        <ul v-if="props.family.childIds.length > 0" class="list-unstyled children-list">
          <li v-for="childId in props.family.childIds" :key="childId">
            <PersonCard 
              v-if="childId == props.currentChildId"
              :person-id="childId"
              layout="small"
              profile
              placeholder>
            </PersonCard>
            <ProfileRelationshipCard v-else :person-id="childId"></ProfileRelationshipCard>
          </li>
        </ul>
        <div v-else class="empty-list">
          None
        </div>
        <div class="action-row">
          <button type="button" v-if="canAddRef" class="btn btn-inline btn-link action" @click="emit('addChild', props.family)">
            Add a child
          </button>
          <button type="button" 
            v-if="canChangeOrderRef" 
            class="btn btn-inline btn-link action" 
            @click="changeChildOrder">
            Change order
          </button>
        </div>
      </div>
    </template>
  </ExpandableItem>
</template>

<style lang="scss" scoped>
.expander {
  margin-left: 2rem;
  font-size: 0.875rem;
}

.expander-label {
  color: #888;
}

.count {
  font-size: 0.75rem;
  color: #aaa;
}

.empty-list {
  color: #aaa;
}

.children-area {
  margin-left: 1.25rem;
  padding: 0.5rem 0; // margin-top causes jumping when expanding (??)

  .list-header {
    margin-bottom: 0.5rem;
    font-size: 0.75rem;
    color: #888;
  }

  .children-list {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  .action-row {
    margin-top: 0.5rem;
    display: flex;
    gap: 1rem;

    .action {
      font-size: 0.875rem;
    }
  }
}
</style>

<script lang="ts" setup>
import { computed } from 'vue'
import { FamilyAndChildren } from '@/rd/ViewFamilyStore'
import { canCreateChildRelationship } from './ProfileManager'
import { notImplemented } from '@/util/AppUtil'
import PersonCard from '@/manage/PersonCard.vue'
import ProfileRelationshipCard from './ProfileRelationshipCard.vue'
import ExpandableItem from '@/util/ExpandableItem.vue'

const props = defineProps<{
  profilePersonId: string
  family: FamilyAndChildren
  currentChildId?: string  
}>()

const emit = defineEmits([
  "addChild",
  "remove",
])

const titleRef = computed(() => props.currentChildId ? "Siblings" : "Children")
const canAddRef = computed(() => canCreateChildRelationship(undefined, props.family.family))
const canChangeOrderRef = computed(() => props.family.childIds.length > 1)

function changeChildOrder() {
  notImplemented()
}
</script>
