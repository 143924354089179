<template>
  <div class="privacy-level-indicator">
      <GlobeIcon v-if="props.value == PrivacyLevel.Everyone"></GlobeIcon>
      <UsersIcon v-else-if="props.value == PrivacyLevel.Groups"></UsersIcon>
      <HeartIcon v-else-if="props.value == PrivacyLevel.Friends"></HeartIcon>
      <LockIcon v-else-if="props.value == PrivacyLevel.Me"></LockIcon>
  </div>
</template>

<style lang="scss" scoped>
.privacy-level-indicator {
  margin-top: 1.3rem;
  color: #888;
}
</style>

<script setup lang="ts">
import { computed } from 'vue'
import { PrivacyLevel } from '@/gp/GroupAdminModel'
import { GlobeIcon, UsersIcon, HeartIcon, LockIcon } from '@zhuowenli/vue-feather-icons'

const props = defineProps<{
  value: PrivacyLevel
}>()

const displayText = computed(() => props.value)
</script>
