<template>
  <Modal ref="modalRef" ok-style="danger" @ok="removeMember">
    <template #title>
      Stop sharing
    </template>
    <div>
      <p v-if="props.profile">
        Are you sure you want to stop sharing the profile with this family group?
      </p>
      <p v-else>
        Are you sure you want to stop sharing your tree with this family group?
      </p>
      <FamilyGroupItem :family-group-id="dataGroupMemberRef?.principalId"></FamilyGroupItem>
    </div>
    <template #okText>
      Stop sharing
    </template>
  </Modal>
</template>

<style lang="scss" scoped>
.family-group-item {
  margin-left: 1rem;
}
</style>

<script setup lang="ts">
import { ref } from 'vue'
import { DataGroupMember } from '@/gp/GroupAdminModel'
import { useDataGroupMemberStore } from '@/gp/DataGroupMemberStore'
import Modal from '@/util/Modal.vue'
import FamilyGroupItem from '@/manage/familygroups/FamilyGroupItem.vue'

const props = defineProps({
  profile: Boolean
})

const dataGroupMemberRef = ref<DataGroupMember>()
const modalRef = ref<InstanceType<typeof Modal>>()

const dataGroupMemberStore = useDataGroupMemberStore()

defineExpose({
  open,
})

function open(dm: DataGroupMember) {
  dataGroupMemberRef.value = dm
  modalRef.value?.open()
}

async function removeMember() {
  await dataGroupMemberStore.deleteAsync(dataGroupMemberRef.value!)
  modalRef.value?.close()
}
</script>