<template>
  <div class="profile-relationship-selector">
    <select class="form-select" 
      v-model="selectedValue" 
      v-bind="{ disabled: props.disabled }"
      @focus="emit('focus')">
      <option v-for="rel in newProfileRelationships" :value="rel" :key="rel">{{ getNewProfileRelationshipText(rel) }}</option>
    </select>
  </div>
</template>

<style lang="scss" scoped>
.profile-relationship-selector {
  display: inline-block;
}
</style>

<script setup lang="ts">
import { computed, defineEmits, defineProps } from 'vue'
import { newProfileRelationships, getNewProfileRelationshipText, NewProfileRelationship } from '@/manage/profiles/ProfileManager'

const props = defineProps<{
  modelValue: NewProfileRelationship,
  disabled?: boolean,
}>()

const emit = defineEmits([
  'update:modelValue',
  'focus'
])

const selectedValue = computed({
  get() { return props.modelValue },
  set(value) { emit('update:modelValue', value) }
})

</script>