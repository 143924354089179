<template>
  <div class="research-group-card shadow-sm" :class="cardClassesRef">
    <button type="button" v-if="props.selectMode" class="overlay" @click="emit('selected')"></button>
    <router-link v-if="dataGroupRef && !props.selectMode" class="overlay" :to="dataGroupRef.pageUrl"></router-link>
    <div class="card-content">
      <div class="card-image">
        <img :src="logoRef">
      </div>
      <div class="card-body">
        <div class="card-title-row">
          <div class="card-title">
            {{ dataGroupRef?.name }}
          </div>
          <div v-if="statusTextRef" class="card-status" :class="statusStyleRef">
            {{ statusTextRef }}
          </div>
        </div>
        <div class="card-subtitle">
          {{ workspaceRef?.workspaceTypeInfo?.description }} managed by <UserLink :userId="dataGroupRef?.ownerId" class="link-dark" dimmed></UserLink>
        </div>
        <div v-if="!props.small" class="card-details">
          {{ lastSyncDateTextRef }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.research-group-card {
  position: relative;
  max-width: 500px;
  border-top: 1px solid #f8f8f8;

  &.selected {
    background-color: #f8f8f8;
  }

  .card-content {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
  }

  .card-image {
    padding: 12px 6px;
    width: 62px;
    background-color: #f1f1f1;

    img {
      width: 48px;
      object-fit: contain;
    }
  }

  .card-body {
    padding: 0.25em;
    line-height: 1.3;
  }

  .card-title-row {
    display: grid;
    grid-template-columns: auto max-content;

    .card-title {
      margin-bottom: 0.1rem;
    }

    .card-status {
      margin-bottom: 3px;
      background-color: #888;
      padding: 2px 0.25rem 0;
      font-size: 0.75rem;
      text-transform: uppercase;
      color: #333;

      &.warning {
        background-color: var(--bs-warning);
      }

      &.success {
        background-color: var(--bs-success);
      }
    }
  }

  .card-subtitle {
    margin-bottom: 1rem;
    font-size: 0.75rem;
    color: #888;
  }

  .card-details {
    font-size: 0.75rem;
    color: #888;

    svg {
      margin-right: 0.25rem;
    }
  }
}

.card-small {
  max-width: 400px;

  .card-image {
    padding: 0.5em;
    width: 50px;

    img {
      width: 35px;
    }
  }

  .card-subtitle {
    margin-bottom: 0;
  }
}
</style>

<script setup lang="ts">
import { computed, ref, watchEffect } from 'vue'
import { useWorkspaceStore } from '@/rd/WorkspaceStore'
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { useDataGroupInviteStore } from '@/gp/DataGroupInviteStore'
import { useSyncStore } from '@/rd/SyncStore'
import { Workspace } from '@/rd/ResearchDataModel'
import { DataGroup, DataGroupInvite, dataGroupInviteStatusStyle, InvitationResult } from '@/gp/GroupAdminModel'
import { LoadMode } from '@/util/AsyncData'
import { DateTimeUtil } from '@/util/LuxonUtil'
import { researchGroupLogos } from '@/manage/researchgroups/ResearchGroupLogos'
import UserLink from '@/manage/users/UserLink.vue'
import { TokenManager } from '@/auth/TokenManager'

const props = defineProps({
  dataGroupId: String,
  inviteId: String,
  small: Boolean,
  selectMode: Boolean,
  selectedId: String,
})

const emit = defineEmits([
  "selected",
])

const dataGroupRef = ref<DataGroup>()
const workspaceRef = ref<Workspace>()
const inviteRef = ref<DataGroupInvite>()

const dataGroupStore = useDataGroupStore()
const dataGroupInviteStore = useDataGroupInviteStore()
const workspaceStore = useWorkspaceStore()
const syncStore = useSyncStore()

const memberStatusText = {
  owner: 'Owner',
  member: undefined,
}
const memberStatusStyle = {
  owner: 'warning',
  member: undefined,
}

watchEffect(() => {
  if (props.dataGroupId) {
    dataGroupRef.value = dataGroupStore.getAsyncGroup(props.dataGroupId, LoadMode.EnsureLoaded)?.data
    inviteRef.value = undefined
  }
  else if (props.inviteId) {
    inviteRef.value = dataGroupInviteStore.getAsyncInvite(props.inviteId, LoadMode.EnsureLoaded)?.data
    dataGroupRef.value = dataGroupStore.getAsyncGroup(inviteRef.value?.dataGroupId, LoadMode.EnsureLoaded)?.data
  }
  else {
    workspaceRef.value = undefined
    dataGroupRef.value = undefined
    inviteRef.value = undefined
  }
  workspaceRef.value = workspaceStore.getAsyncWorkspacesForGroups([dataGroupRef.value?.id], LoadMode.EnsureLoaded).at(0)?.data
})

const logoRef = computed(() => researchGroupLogos[workspaceRef.value?.dataFileBaseType ?? 'TestData'])
const lastSyncRef = computed(() => syncStore.getAsyncSync(workspaceRef.value?.lastSyncId)) // loaded with workspace
const lastSyncDateTextRef = computed(() => {
  const relativeDate = lastSyncRef.value?.data?.createdDate
  return relativeDate ? `Last updated ${DateTimeUtil.toRelativePastDate(relativeDate)}` : "Never synced"
})
const cardClassesRef = computed(() => ({ 
  'card-small': props.small, 
  selected: props.selectedId == dataGroupRef.value?.id
}))

const memberStatusRef = computed(() => dataGroupRef.value?.ownerId == TokenManager.userId ? 'owner' : 'member')

const statusTextRef = computed(() => inviteRef.value
  ? inviteRef.value.statusText
  : memberStatusText[memberStatusRef.value])
const statusStyleRef = computed(() => inviteRef.value 
  ? dataGroupInviteStatusStyle[inviteRef.value.status]
  : memberStatusStyle[memberStatusRef.value])
</script>
