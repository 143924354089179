import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

// public
import HomePage from '@/public/HomePage.vue'
import SyncAppPage from '@/public/SyncAppPage.vue'
import PrivacyPage from '@/public/PrivacyPage.vue'
import TermsPage from '@/public/TermsPage.vue'
import PlansPage from '@/public/PlansPage.vue'

import SignUpPage from '@/auth/SignUpPage.vue'
import SignUpAppPage from '@/auth/SignUpAppPage.vue'
import SignUpNotifyPage from '@/auth/SignUpNotifyPage.vue'
import SignUpLaterPage from '@/auth/SignUpLaterPage.vue'
import SignInPage from '@/auth/SignInPage.vue'
import SignInCodePage from '@/auth/SignInCodePage.vue'
import TestSignInPage from '@/auth/TestSignInPage.vue'
import AuthRedirectPage from '@/auth/AuthRedirectPage.vue'
import SignOutPage from '@/auth/SignOutPage.vue'

import SupportPage from '@/support/SupportPage.vue'
import FAQPage from '@/learn/FAQPage.vue'
import ContactPage from '@/support/ContactPage.vue'

import SyncOverviewPage from '@/learn/SyncOverviewPage.vue'
import FamilyGroupOverviewPage from '@/learn/FamilyGroupOverviewPage.vue'
import ResearchPage from '@/learn/ResearchPage.vue'
import SupervisedUsersPage from '@/learn/SupervisedUsersPage.vue'

// manage
import AccountPage from '@/account/AccountPage.vue'
import FamilyGroupCreatePage from '@/manage/familygroups/FamilyGroupCreatePage.vue'
import FamilyGroupPage from '@/manage/familygroups/FamilyGroupPage.vue'
import FamilyGroupInviteAcceptPage from '@/manage/familygroups/FamilyGroupInviteAcceptPage.vue'
import ProfileListPage from '@/manage/profiles/ProfileListPage.vue'
import ProfilePage from '@/manage/profiles/ProfilePage.vue'
import ProfileMatchesPage from '@/manage/profiles/ProfileMatchesPage.vue'
import ResearchGroupListPage from '@/manage/researchgroups/ResearchGroupListPage.vue'
import ResearchGroupPage from '@/manage/researchgroups/ResearchGroupPage.vue'
import UserProfilePage from '@/manage/users/UserProfilePage.vue'

// explore
import UserHomePage from '@/explore/UserHomePage.vue'
import PersonPage from '@/explore/PersonPage.vue'
import FamilyPage from '@/explore/FamilyPage.vue'

// admin
import ManageUsersPage from '@/admin/ManageUsersPage.vue'

const routes: Array<RouteRecordRaw> = [
  // public
  { path: '/', component: HomePage },
  { path: '/syncapp', name: 'syncapp', component: SyncAppPage },
  { path: '/privacy', component: PrivacyPage },
  { path: '/terms', component: TermsPage },
  { path: '/signup', component: SignUpPage },
  { path: '/signupapp', component: SignUpAppPage },
  { path: '/signupnotify', component: SignUpNotifyPage },
  { path: '/signupdone', component: SignUpLaterPage },
  { path: '/signin', component: SignInPage },
  { path: '/signincode', component: SignInCodePage },
  { path: '/testsignin', component: TestSignInPage },
  { path: '/authredirect', component: AuthRedirectPage },
  { path: '/signout', component: SignOutPage },
  { path: '/about', name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/public/AboutPage.vue')
  },
  { path: '/plans', component: PlansPage },
  { path: '/learn/sync', name: 'sync', component: SyncOverviewPage },
  { path: '/learn/family-groups', component: FamilyGroupOverviewPage },
  { path: '/learn/faq', name: 'faq', component: FAQPage },
  { path: '/learn/research', name: 'research', component: ResearchPage },
  { path: '/learn/supervised-users', name: 'supervisedusers', component: SupervisedUsersPage },
  { path: '/support', name: 'support', component: SupportPage },
  { path: '/contact', name: 'contact', component: ContactPage },

  // manage
  { path: '/account', name: 'account', component: AccountPage },
  { path: '/users/:userId/account', component: AccountPage, props: true },
  { path: '/account', name: 'account.supervisedTab', component: AccountPage },
  { path: '/account', name: 'account.familyGroupsTab', component: AccountPage },
  { path: '/groups/new/:page?', component: FamilyGroupCreatePage, props: true },
  { path: '/groups/:familyGroupId', component: FamilyGroupPage, props: true },
  { path: '/groupinvites/:id/accept', 
      component: FamilyGroupInviteAcceptPage, 
      props: route => ({ inviteId: route.params.id, inviteCode: route.query.code }),
      meta: { redact: ['code'] }},
  { path: '/profile', component: ProfilePage, props: true }, // set props to undefined
  { path: '/profiles', component: ProfileListPage },
  { path: '/profiles/:personId', component: ProfilePage, props: true },
  { path: '/profiles/:personId/matches', component: ProfileMatchesPage, props: true },
  { path: '/trees', component: ResearchGroupListPage },
  { path: '/trees/:dataGroupId', component: ResearchGroupPage, props: true },
  { path: '/users/:userId', component: UserProfilePage, props: true },
  { path: '/users/:userId/profiles', component: ProfileListPage, props: true },

  // explore
  { path: '/home', component: UserHomePage },
  { path: '/persons/:personId', component: PersonPage, props: true  },
  { path: '/families/:id', component: FamilyPage },

  // admin
  { path: '/admin/users', component: ManageUsersPage },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // If the route has a hash (anchor link), scroll to the element
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' };
    }
    
    // If the route has savedPosition, use it for smooth scrolling
    if (savedPosition) {
      return savedPosition;
    }

    // Default behavior: scroll to the top of the page
    return { top: 0, behavior: 'smooth' };
  }
})

router.beforeEach((to, from, next) => {
  if (typeof to.name == 'string' && to.name.includes('.')) {
    const parts = to.name.split('.', 2)
    to.meta.initialTab = parts[1]
  }
  next()
})

export default router
