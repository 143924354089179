<template>
  <AutoCompleteInput
    class="user-selector"
    placeholder="Type a user name"
    :search="searchUsers"
    :item-component="UserItem"
    :get-component-props="getUserItemProps"
    @result-selected="onResultSelected">
  </AutoCompleteInput>
</template>

<script lang="ts" setup>
import { User } from '@/gp/GroupAdminModel'
import { useUserStore } from '@/gp/UserStore'
import AutoCompleteInput from '@/util/AutoCompleteInput.vue'
import UserItem from './UserItem.vue'

const userStore = useUserStore()

const emit = defineEmits([
  'userSelected'
])

interface UserSearchResult {
  value: string
  user: User
}

async function searchUsers(query: string) {
  if (query.length < 3)
    return []

  const results = await userStore.searchUsersAsync(query, 20)
  return results.items.map(u => ({ value: u.id!, user: u }))
}

function getUserItemProps(r: { value: string }) {
  return { userId: r.value, selectMode: true }
}

function onResultSelected(r: UserSearchResult) {
  emit('userSelected', r.value)
}

</script>